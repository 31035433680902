import React from "react";
import { useTranslation } from "react-i18next";

const CardVimeo = ({ item }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div
        style={{
          width: "329px",
          height: "288px",
          marginTop: "20px",
        }}>
        <iframe
          title={t("views.cardVimeo.vimeoVideo")}
          width="329px"
          height="288px"
          style={{ border: "1px solid black", borderRadius: "10px" }}
          src={`https://player.vimeo.com/video/${item.id}?autoplay=0`}
          allowFullScreen></iframe>
      </div>
    </div>
  );
};

export default CardVimeo;
