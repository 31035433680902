import React, {useState, useRef, useEffect} from 'react'

import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Button,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    Stack,
    Divider
  } from "@mui/material";

import FileFolder from './FileFolder';

const DialogBoxAssets = ({isOpen, onClose, idx, changeZoneDetails}) => {
    const { t } = useTranslation()
    const socketRef = useRef();
    const dispatch = useDispatch();
    const { user, auth, loggedin, users } = useSelector((state) => state.User);

    const { content, isDeleting, isDeleted, isContentAddedInContent, isContentAdding, uploadFailedError, uploadFailedErrorMessage } = useSelector((state) => state.Content);
    const { screens } = useSelector((state) => state.Screen);
    const { currentFolder, allFolders, userFolders, contentFolders, files, isFolderCreated, isFolderDeleted } = useSelector(
      (state) => ({
        currentFolder: state.FileFolder.currentFolder,
        userFolders: state.FileFolder.userFolders.filter((f) => f.parent === state.FileFolder.currentFolder && f?.folderFor === "content"),
        allFolders: state.FileFolder.userFolders,
        contentFolders: state.FileFolder.userFolders.filter((f) => f?.folderFor === "content"),
        files: state.Content.content.filter((c) => c.folderId === state.FileFolder.currentFolder),
        isFolderCreated: state.FileFolder.isFolderCreated,
        isFolderDeleted: state.FileFolder.isFolderDeleted,
      }),
      shallowEqual
    );

  return (
    <Dialog
    open={isOpen}
    onClose={() => onClose(false)}
    fullWidth
    maxWidth="xl"
    >
        <DialogTitle sx={{marginBottom:"10px"}}>
            <Stack direction={"column"} sx={{alignItems:"center"}}>
                <Box fontSize="h4.fontSize">Set Assets</Box>
            </Stack>
        </DialogTitle>
        <Divider sx={{ width: '100%', marginTop:"20px" }} />

        <DialogContent>
      <FileFolder user={user} socketRef={socketRef} onClose={onClose} idx={idx} changeZoneDetails={changeZoneDetails}/>
        </DialogContent>
    </Dialog>
  )
}

export default DialogBoxAssets