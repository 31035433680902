import {
  BLOCKED_USER,
  GET_ALL_USERS,
  GET_SINGLE_USER,
  INVALID_PASSWORD_CHANGE_EMAIL,
  LOGIN_FAILED,
  LOGIN_BLOCKED,
  LOGIN_FLAG_OFF,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_WITH_FACEBOOK,
  LOGOUT,
  PASSWORD_CHANGED,
  PASSWORD_CHANGE_EMAIL,
  PASSWORD_CHANGE_REQUEST,
  PASSWORD_CHANGE_TOKEN_EXPIRED,
  REFRESH_LOGIN,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_SUCCESS_FLAG_OFF,
  UPDATE_USER_PROFILE,
  DELETE_USER_PROFILE,
  VERIFY_SIGNUP_REQUEST,
  VERIFY_SIGNUP_SUCCESS,
  USER_DESC_ORDER_BY_EMAIL,
  USER_ASC_ORDER_BY_EMAIL,
  USER_DESC_ORDER_BY_NAME,
  USER_ASC_ORDER_BY_NAME,
  USER_DESC_ORDER_BY_DATE,
  USER_ASC_ORDER_BY_DATE,
  USER_DESC_ORDER_BY_STATUS,
  USER_ASC_ORDER_BY_STATUS,
  SET_USERS_GROUP,
  PASSWORD_CHANGE_ERROR,
  CURRENT_PASSWORD_INVALID,
  REMOVE_USERS_GROUP,
  EDIT_GROUP,
} from "../constant";
import toast from "react-hot-toast";

const initial = {
  auth: false,
  users: [],
  user: {},
  singleUser: {},
  loging: false,
  loggedin: false,
  logout: false,
  logFail: false,
  logBlocked: false,
  // yetLogin:window.localStorage.getItem('isLogin'),
  signupSppiner: false,
  isRegistered: false,
  verifiedAccount: false,
  verifyingAccount: false,
  premiume: false,
  isUserUpdated: false,
  isEmailSent: false,
  isEmailNotSent: false,
  isPasswordChangeRequesting: false,
  isPasswordChanged: false,
  isPasswordChangeTokenExpired: false,
  isVerified: true,
  isCurrentPasswordInvalid: false,
};
export const UserReducer = (state = initial, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loging: true,
      };
    case LOGIN_SUCCESS:
      console.log(action.payload.user);
      return {
        ...state,
        user: {
          _id: action.payload.user._id,
          fullname: action.payload.user.fullname,
          firstName: action.payload.user.firstName,
          lastName: action.payload.user.lastName,
          company: action.payload.user?.company,
          auth: true,
          email: action.payload.user.email,
          avatar: action?.payload?.user?.avatar,
          role:
            action.payload.user?.previlliages !== undefined
              ? `user-${action.payload.user?.previlliages?.name}`
              : action.payload.user.role,
          isMember:
            action.payload?.user?.isMember == undefined
              ? false
              : action.payload.user?.isMember,
          user: action.payload.user?.subuser,
          plan: action.payload.user?.plan,
          createdAt: action.payload.user.createdAt,
          customerId: action.payload?.user?._id,
        },
        users: [action.payload.user],
        auth: action.payload.auth,
        // auth: action.payload.social ? action.payload.user.auth : action.payload.auth,
        loging: false,
        isVerified:
          action.payload?.isVerified == undefined
            ? true
            : action.payload.isVerified,
        // isVerified:action.payload.social ? true : action.payload.user.role=='admin' ? true : action.payload.isVerified,
        // yetLogin:window.localStorage.getItem('isLogin'),
        loggedin: true,
        premiume: action.payload.premiume,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        auth: action.payload.auth,
        loging: false,
        // loggedin: false,
        logFail: true,
      };
    case LOGIN_BLOCKED:
      return {
        ...state,
        auth: action.payload.auth,
        loging: false,
        // loggedin: false,
        logBlocked: true,
      };
    case LOGIN_WITH_FACEBOOK:
      console.log("from reducer=", action.payload);
      return {
        ...state,
        user: {
          _id: action.payload.user._id,
          fullname: action.payload.user.fullname,
          firstName: action.payload.user?.firstName,
          lastName: action.payload.user?.lastName,
          company: action.payload.user?.company,
          auth: true,
          email: action.payload.user?.email,
          avatar: action?.payload?.user?.avatar,
          role: action.payload.user?.role,
          plan: action.payload.user?.plan,
          createdAt: action.payload.user.createdAt,
        },
        auth: action.payload.auth,
        loging: false,
        isVerified: action.payload.social
          ? true
          : action.payload.user.role == "admin"
          ? true
          : action.payload.user.isVerified,
        premiume: action.payload?.premiume,
        loggedin: true,
      };
    case LOGOUT:
      return {
        ...state,
        auth: false,
        // loggedin:false,
        // logout:true
      };
    case LOGIN_FLAG_OFF:
      return {
        ...state,
        loging: false,
        loggedin: false,
        logFail: false,
        logBlocked: false,
        isUserUpdated: false,
        isEmailSent: false,
        isEmailNotSent: false,
        isPasswordChangeRequesting: false,
        isVerified: true,
        // isPasswordChanged:false,
        isPasswordChangeTokenExpired: false,
      };
    case SIGNUP_REQUEST:
      return {
        ...state,
        signupSppiner: true,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        isRegistered: true,
        signupSppiner: false,
        users: [...state.users, action.payload],
      };
    case SIGNUP_SUCCESS_FLAG_OFF:
      return {
        ...state,
        isRegistered: false,
        signupSppiner: false,
      };
    case VERIFY_SIGNUP_REQUEST:
      return {
        ...state,
        verifiedAccount: false,
        verifyingAccount: true,
      };
    case VERIFY_SIGNUP_SUCCESS:
      return {
        ...state,
        verifiedAccount: true,
        verifyingAccount: false,
      };
    case REFRESH_LOGIN:
      console.log(action.payload.user);
      return {
        ...state,
        auth: true,
        loggedin: true,
        // isLogging:false,
        user: {
          _id: action.payload.user._id,
          fullname: action.payload.user.fullname,
          firstName: action.payload.user.firstName,
          lastName: action.payload.user.lastName,
          company: action.payload.user?.company,
          auth: true,
          email: action.payload.user.email,
          avatar: action?.payload?.user?.avatar,
          role:
            action.payload.user?.previlliages !== undefined
              ? `user-${action.payload.user?.previlliages?.name}`
              : action.payload.user.role,
          isMember:
            action.payload?.user?.isMember == undefined
              ? false
              : action.payload.user?.isMember,
          user: action.payload.user?.subuser,
          users: [action.payload.user],
          plan: action.payload.user?.plan,
          createdAt: action.payload.user.createdAt,
        },
        // auth: action.payload.auth,
        loging: false,
        isVerified:
          action.payload?.isVerified == undefined
            ? true
            : action.payload.isVerified,
        // yetLogin:window.localStorage.getItem('isLogin'),
        loggedin: true,
        premiume: action.payload.premiume,
        // loginSuccess:true
      };
    case GET_ALL_USERS:
      // console.log('users from reducers=>',action.payload)
      return {
        ...state,
        users: [...action.payload],
      };
    case SET_USERS_GROUP:
      return {
        ...state,
        users: [...action.payload],
      };
    case UPDATE_USER_PROFILE:
      const updatedUser = action.payload;

      let newUsers;

      if (state.users.find((u) => u._id === updatedUser._id)) {
        newUsers = state.users.map((u) =>
          u._id === updatedUser._id
            ? {
                ...u,
                ...updatedUser,
              }
            : u
        );
      } else {
        newUsers = [...state.users, updatedUser];
      }

      return {
        ...state,
        user:
          updatedUser._id == state.user._id
            ? {
                ...state.user,
                ...updatedUser,
              }
            : state.user,
        users: newUsers,
        isUserUpdated: true,
      };
    case DELETE_USER_PROFILE:
      const deletedUser = action.payload;

      if (state.user._id === deletedUser._id)
        return {
          ...state,
          auth: false,
        };

      return {
        ...state,
        users: state.users.map((u) =>
          u._id === deletedUser._id ? deletedUser : u
        ),
      };
    case GET_SINGLE_USER:
      console.log(state.users);
      return {
        ...state,
        singleUser: { ...state.user },
      };
    case PASSWORD_CHANGE_EMAIL:
      return {
        ...state,
        isEmailSent: true,
      };
    case INVALID_PASSWORD_CHANGE_EMAIL:
      return {
        ...state,
        isEmailNotSent: true,
      };
    case PASSWORD_CHANGE_REQUEST:
      return {
        ...state,
        isPasswordChangeRequesting: true,
        isCurrentPasswordInvalid: false,
      };
    case PASSWORD_CHANGED:
      toast.success("Password changed succesfully");
      console.log("PASSWORD_CHANGED");
      return {
        ...state,
        isPasswordChanged: true,
        isPasswordChangeRequesting: false,
        isCurrentPasswordInvalid: false,
      };
    case PASSWORD_CHANGE_TOKEN_EXPIRED:
      return {
        ...state,
        isPasswordChangeTokenExpired: true,
        isPasswordChangeRequesting: false,
      };
    case BLOCKED_USER:
      const blockedUser = action.payload;

      console.log(blockedUser);

      return {
        ...state,
        users: state.users.map((u) =>
          u._id === blockedUser._id ? blockedUser : u
        ),
      };
    case USER_DESC_ORDER_BY_EMAIL:
      return {
        ...state,
        users: state.users.sort((a, b) => {
          let nameA = a.email.toUpperCase();
          let nameB = b.email.toUpperCase();
          if (nameB < nameA) {
            return -1;
          }
          if (nameB > nameA) {
            return 1;
          }
          return 0;
        }),
      };
    case USER_ASC_ORDER_BY_EMAIL:
      return {
        ...state,
        users: state.users.sort((a, b) => {
          let nameA = a.email.toUpperCase();
          let nameB = b.email.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }),
      };
    case USER_DESC_ORDER_BY_NAME:
      return {
        ...state,
        users: state.users.sort((a, b) => {
          let nameA = a.fullname.toUpperCase();
          let nameB = b.fullname.toUpperCase();
          if (nameB < nameA) {
            return -1;
          }
          if (nameB > nameA) {
            return 1;
          }
          return 0;
        }),
      };
    case USER_ASC_ORDER_BY_NAME:
      return {
        ...state,
        users: state.users.sort((a, b) => {
          let nameA = a.fullname.toUpperCase();
          let nameB = b.fullname.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }),
      };
    case USER_DESC_ORDER_BY_DATE:
      return {
        ...state,
        users: state.users.sort(
          (a, b) =>
            new Date(...a.createdAt.split("/").reverse()) -
            new Date(...b.createdAt.split("/").reverse())
        ),
      };
    case USER_ASC_ORDER_BY_DATE:
      return {
        ...state,
        users: state.users.sort(
          (a, b) =>
            new Date(...b.createdAt.split("/").reverse()) -
            new Date(...a.createdAt.split("/").reverse())
        ),
      };
    case USER_DESC_ORDER_BY_STATUS:
      return {
        ...state,
        users: state.users.sort(
          (a, b) => a.isActive - b.isActive || b.isDeleted - a.isDeleted
        ),
      };
    case USER_ASC_ORDER_BY_STATUS:
      return {
        ...state,
        users: state.users.sort(
          (b, a) => a.isActive - b.isActive || b.isDeleted - a.isDeleted
        ),
      };
    case PASSWORD_CHANGE_ERROR:
      toast.error(action.payload);
      return state;
    case CURRENT_PASSWORD_INVALID:
      console.log("CURRENT_PASSWORD_INVALID");
      return {
        ...state,
        isCurrentPasswordInvalid: true,
      };
    case EDIT_GROUP:
      const updatedGroup = action.payload;

      const usersWithNewGroup = state.users.map((u) => ({
        ...u,
        groups: u.groups.map((g) =>
          g._id === updatedGroup._id ? updatedGroup : g
        ),
      }));

      return {
        ...state,
        users: usersWithNewGroup,
      };
    case "SET_CUSTOMER_ID":
      return {
        ...state,
        user: {
          ...state.user,
          customerId: action.payload,
        },
      };
    default:
      return state;
  }
};
