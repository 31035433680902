import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowLeftIcon from "@untitled-ui/icons-react/build/esm/ArrowLeft";
import ChevronDownIcon from "@untitled-ui/icons-react/build/esm/ChevronDown";
import Edit02Icon from "@untitled-ui/icons-react/build/esm/Edit02";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Unstable_Grid2";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

import UserAvatar from "src/components/avatar/UserAvatar";

import { customersApi } from "src/api/customers";
import { RouterLink } from "src/components/router-link";
import { Seo } from "src/components/seo";
import { useMounted } from "src/hooks/use-mounted";
import { usePageView } from "src/hooks/use-page-view";
import { paths } from "src/paths";
import { CustomerBasicDetails } from "./customer-basic-details";
import { CustomerMap } from "./customer-map";
import { CustomerDataManagement } from "./customer-data-management";
import { CustomerPasswordEdit } from "./customer-password-edit";
import { CustomerScreens } from "./customer-screens";

import Layout from "src/components/layout/Layout";
import { useSettings } from "src/hooks/use-settings";
import RedesignUserEdit from "./RedesignUserEdit";
import RedesignUserLogs from "./RedesignUserLogs";
import RedesignUserBillingHistory from "./RedesignUserBillingHistory";
import RedesignUserNotes from "./RedesignUserNotes";

import { getUserProfile } from "src/redux/actions/User";
import UserSubscriptions from "../subscriptions/UserSubscriptions/UserSubscriptions";

const useLogs = () => {
  const isMounted = useMounted();
  const [logs, setLogs] = useState([]);

  const handleLogsGet = useCallback(async () => {
    try {
      const response = await customersApi.getLogs();

      if (isMounted()) {
        setLogs(response);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(
    () => {
      handleLogsGet();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return logs;
};

const RedesignUserDetails = ({ isEditActive }) => {
  const { t } = useTranslation();

  const adminTabs = [
    { label: t("views.userDetails.tabs.details"), value: "details" },
    { label: t("views.userDetails.tabs.logs"), value: "logs" },
    {
      label: t("views.userDetails.tabs.billingHistory"),
      value: "billing-history",
    },
    { label: t("views.userDetails.tabs.notes"), value: "notes" },
    {
      label: t("views.userSubscriptions.mySubcriptions"),
      value: "subscriptions",
    },
  ];

  const tabs = [
    { label: t("views.userDetails.tabs.details"), value: "details" },
    { label: t("views.userDetails.tabs.logs"), value: "logs" },
    {
      label: t("views.userDetails.tabs.billingHistory"),
      value: "billing-history",
    },
    {
      label: t("views.userSubscriptions.mySubcriptions"),
      value: "subscriptions",
    },
  ];

  const dispatch = useDispatch();

  const { id } = useParams();
  const navigate = useNavigate();

  const settings = useSettings();

  const [currentTab, setCurrentTab] = useState("details");

  const { user } = useSelector((state) => state.User);

  useEffect(() => {
    dispatch(getUserProfile(id));
  }, [id]);

  const currentUser = useSelector(
    (state) => state.User.users.find((u) => u._id === id) ?? {}
  );

  const logs = useLogs();

  usePageView();

  const handleTabsChange = useCallback((event, value) => {
    setCurrentTab(value);
  }, []);

  if (!currentUser) {
    navigate("/");
    return null;
  }

  return (
    <Layout>
      <Seo title={t("views.userDetails.seoTitle")} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={settings.stretch ? false : "xl"}>
          <Stack spacing={4}>
            <Stack spacing={4}>
              {user.role === "admin" && (
                <div>
                  <Link
                    color="text.primary"
                    component={RouterLink}
                    to={paths.users.index}
                    sx={{
                      alignItems: "center",
                      display: "inline-flex",
                    }}
                    underline="hover"
                  >
                    <SvgIcon sx={{ mr: 1 }}>
                      <ArrowLeftIcon />
                    </SvgIcon>
                    <Typography variant="subtitle2">
                      {t("views.userDetails.users")}
                    </Typography>
                  </Link>
                </div>
              )}
              <Stack
                alignItems="flex-start"
                direction={{
                  xs: "column",
                  md: "row",
                }}
                justifyContent="space-between"
                spacing={4}
              >
                <Stack alignItems="center" direction="row" spacing={2}>
                  <UserAvatar
                    user={currentUser}
                    sx={{
                      height: 64,
                      width: 64,
                    }}
                  />
                  <Stack spacing={1}>
                    <Typography variant="h4">{currentUser.email}</Typography>
                    <Stack alignItems="center" direction="row" spacing={1}>
                      <Typography variant="subtitle2">
                        {t("views.userDetails.userId")}:
                      </Typography>
                      <Chip label={currentUser._id} size="small" />
                    </Stack>
                  </Stack>
                </Stack>
                <Stack alignItems="center" direction="row" spacing={2}>
                  {!currentUser.isDeleted &&
                    (isEditActive ? (
                      <Button
                        color="inherit"
                        component={RouterLink}
                        href={paths.users.details.replace(
                          ":id",
                          currentUser._id
                        )}
                      >
                        {t("views.userDetails.details")}
                      </Button>
                    ) : (
                      <Button
                        color="inherit"
                        component={RouterLink}
                        href={paths.users.edit.replace(":id", currentUser._id)}
                        endIcon={
                          <SvgIcon>
                            <Edit02Icon />
                          </SvgIcon>
                        }
                      >
                        {t("views.userDetails.edit")}
                      </Button>
                    ))}

                  <Button
                    disabled={true}
                    endIcon={
                      <SvgIcon>
                        <ChevronDownIcon />
                      </SvgIcon>
                    }
                    variant="contained"
                  >
                    {t("views.userDetails.actions")}
                  </Button>
                </Stack>
              </Stack>
              {!isEditActive && (
                <div>
                  {user.role === "admin" ? (
                    <Tabs
                      indicatorColor="primary"
                      onChange={handleTabsChange}
                      scrollButtons="auto"
                      sx={{ mt: 3 }}
                      textColor="primary"
                      value={currentTab}
                      variant="scrollable"
                    >
                      {adminTabs.map((tab) => (
                        <Tab
                          key={tab.value}
                          label={tab.label}
                          value={tab.value}
                        />
                      ))}
                    </Tabs>
                  ) : (
                    <Tabs
                      indicatorColor="primary"
                      onChange={handleTabsChange}
                      scrollButtons="auto"
                      sx={{ mt: 3 }}
                      textColor="primary"
                      value={currentTab}
                      variant="scrollable"
                    >
                      {tabs.map((tab) => (
                        <Tab
                          key={tab.value}
                          label={tab.label}
                          value={tab.value}
                        />
                      ))}
                    </Tabs>
                  )}
                  <Divider />
                </div>
              )}
            </Stack>
            {isEditActive ? (
              <RedesignUserEdit user={currentUser} />
            ) : (
              <>
                {currentTab === "details" && (
                  <div>
                    <Grid container spacing={4}>
                      <Grid xs={12} lg={4}>
                        <Stack spacing={4}>
                          <CustomerBasicDetails
                            formattedAddress={currentUser.formattedAddress}
                            address1={currentUser.address1}
                            address2={currentUser.address2}
                            country={currentUser.country}
                            email={currentUser.email}
                            isVerified={!!currentUser.isVerified}
                            phone={currentUser.phone}
                            state={currentUser.state}
                          />
                        </Stack>
                        <Stack spacing={4} sx={{ mt: 2 }}>
                          <CustomerMap user={currentUser} />
                        </Stack>
                      </Grid>
                      <Grid xs={12} lg={8}>
                        <Stack spacing={4}>
                          {!currentUser?.isDeleted && (
                            <CustomerScreens user={currentUser} />
                          )}
                        </Stack>

                        <Stack spacing={4} sx={{ mt: 2 }}>
                          {!currentUser?.isDeleted && (
                            <CustomerPasswordEdit user={currentUser} />
                          )}
                        </Stack>

                        <Stack spacing={4} sx={{ mt: 2 }}>
                          {!currentUser?.isDeleted && (
                            <CustomerDataManagement user={currentUser} />
                          )}
                        </Stack>
                      </Grid>
                    </Grid>
                  </div>
                )}
                {currentTab === "subscriptions" && (
                  <UserSubscriptions user={currentUser} />
                )}
                {currentTab === "logs" && (
                  <RedesignUserLogs user={currentUser} />
                )}
                {currentTab === "billing-history" && (
                  <RedesignUserBillingHistory user={currentUser} />
                )}
                {currentTab === "notes" && user.role === "admin" && (
                  <RedesignUserNotes user={currentUser} />
                )}
              </>
            )}
          </Stack>
        </Container>
      </Box>
    </Layout>
  );
};

export default RedesignUserDetails;
