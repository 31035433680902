import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { paths } from "src/paths";

import { Box, MenuItem, SvgIcon } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import GroupIcon from "@mui/icons-material/Group";

import Folder from "src/components/folder/Folder";

const GroupFoldersView = ({ groups, onGroupEdit, onGroupDelete }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleFolderOpen = useCallback(
    (id) => {
      navigate(`${paths.users.index}/${id}`);
    },
    [navigate]
  );

  const handleFolderEdit = useCallback(
    (id) => {
      onGroupEdit?.(id);
    },
    [onGroupEdit]
  );

  const handleFolderDelete = useCallback(
    (id) => {
      onGroupDelete?.(id);
    },
    [onGroupDelete]
  );

  return (
    <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap", margin: "20px 0 10px 0" }}>
      {groups.map(({ _id, name, color }) => (
        <Folder
          key={`userGroupFolder_${_id}`}
          _id={_id}
          name={name}
          color={color}
          onFolderClick={handleFolderOpen}
          icon={
            <Box pt={2}>
              <GroupIcon />
            </Box>
          }
          menu={[
            <MenuItem onClick={() => handleFolderEdit(_id)} key={`folderMenuItem_${_id}_edit`}>
              <SvgIcon sx={{ marginRight: "8px" }} fontSize="small">
                <EditIcon />
              </SvgIcon>
              {t("views.groupFoldersView.edit")}
            </MenuItem>,
            <MenuItem onClick={() => handleFolderDelete(_id)} key={`folderMenuItem_${_id}_delete`}>
              <SvgIcon sx={{ marginRight: "8px" }} fontSize="small">
                <DeleteIcon />
              </SvgIcon>
              {t("views.groupFoldersView.delete")}
            </MenuItem>,
          ]}
        />
      ))}
    </Box>
  );
};

export default GroupFoldersView;
