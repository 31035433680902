import { makeStyles } from "@material-ui/core";
import { Button } from "@mui/material";
import React from "react";
const useStyles = makeStyles((theme) => {
  return {
    btn: {
      textTransform: "capitalize !important",
      borderRadius: "5px !important",
      [theme.breakpoints.down("xs")]: {
        width: "100px !important",
        height: "25px !important",
        margin: "10px !important",
        fontSize: "10px !important",
      },
    },
  };
});
function VCPButton(props) {
  const classes = useStyles();
  return (
    <Button
      sx={{
        height: props.height,
        width: props.width,
        padding: props.padding,
        margin: { ...props.margin },
        backgroundColor: props.backgroundColor,
        color: props.color,
        // borderRadius:'5px !important'
      }}
      variant={props.variant}
      className={classes.btn}
      size={props.size}
      onClick={props.onClick}
      fullWidth={props.fullWidth}
      disabled={props.disable}>
      {props.name} {props.icon}
    </Button>
  );
}

export default VCPButton;
