import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import SearchMdIcon from "@untitled-ui/icons-react/build/esm/SearchMd";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PlusIcon from "@untitled-ui/icons-react/build/esm/Plus";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { Box, TextField, MenuItem, FormControl, InputLabel, Select, InputAdornment, OutlinedInput, Button } from "@mui/material";
import { useMediaQuery } from "react-responsive";

const RedesignUserListSearch = ({ userListState, selectedUsers, onDeleteFromGroup, onAddToGroup, currentGroup }) => {
  const { t } = useTranslation();

  const tabs = [
    { label: t("views.redesignUserListSearch.tabs.all"), value: "all" },
    { label: t("views.redesignUserListSearch.tabs.active"), value: "isActive" },
    { label: t("views.redesignUserListSearch.tabs.blocked"), value: "isBlocked" },
    { label: t("views.redesignUserListSearch.tabs.admin"), value: "isAdmin" },
  ];

  const { state: listState, handleFiltersChange, handleSortChange } = userListState;
  const { filters, sortBy, sortDir } = listState;
  const dispatch = useDispatch();

  const sortByValue = useMemo(() => `${sortBy}_${sortDir}`, [sortDir, sortBy]);
  const currentTab = useMemo(() => {
    const tabsValues = tabs.map((t) => t.value);
    for (const value of tabsValues) if (filters[value]) return value;
    return "all";
  }, [filters]);

  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
  const isSearchContainer = useMediaQuery({ query: "(max-width: 1120px)" });

  const handleTabsChange = useCallback(
    (event, value) => {
      const updatedFilters = {
        ...filters,
        ...tabs.reduce((filters, tab) => {
          if (tab.value !== "all") filters[tab.value] = undefined;
          return filters;
        }, {}),
      };
      if (value !== "all") {
        updatedFilters[value] = true;
      }
      handleFiltersChange(updatedFilters);
    },
    [filters]
  );

  const handleSearchQueryChange = useCallback(
    (event) => {
      handleFiltersChange({
        ...filters,
        query: event.target.value ?? undefined,
      });
    },
    [filters]
  );

  const handleSortSelect = useCallback((event) => {
    const value = event.target.value;
    const [sortBy, sortDir] = value.split("_");
    handleSortChange({ sortBy, sortDir });
  }, []);

  const handleAddUsersToGroup = useCallback(() => {
    onAddToGroup?.();
  }, [onAddToGroup]);

  const handleDeleteUsersFromGroup = useCallback(() => {
    onDeleteFromGroup?.();
  }, [onDeleteFromGroup]);

  return (
    <>
      <Tabs indicatorColor="primary" onChange={handleTabsChange} scrollButtons="auto" sx={{ px: 3 }} textColor="primary" value={currentTab} variant="scrollable">
        {tabs.map((tab) => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <Divider />
      <Stack
        alignItems={isSearchContainer ? "flex-start" : "center"}
        direction={isSearchContainer ? "column" : "row"}
        flexWrap="wrap"
        justifyContent="space-between"
        spacing={3}
        sx={{ padding: 3 }}>
        <OutlinedInput
          defaultValue=""
          fullWidth
          sx={{ maxWidth: isMobile ? "100%" : "400px" }}
          onChange={handleSearchQueryChange}
          name="itemName"
          placeholder={t("views.redesignUserListSearch.searchPlaceholder")}
          startAdornment={
            <InputAdornment position="start">
              <SvgIcon>
                <SearchMdIcon />
              </SvgIcon>
            </InputAdornment>
          }
        />
        <Box sx={{ display: "flex", minWidth: "420px", flexDirection: isSearchContainer ? "column" : "row" }}>
          {selectedUsers.length !== 0 && (
            <Button
              onClick={handleAddUsersToGroup}
              startIcon={
                <SvgIcon>
                  <PlusIcon />
                </SvgIcon>
              }
              variant="contained"
              sx={{ width: "200px", height: "54px", marginRight: "20px", marginBottom: isSearchContainer ? "24px !important" : "0px !important" }}>
              {t("views.redesignUserListSearch.addToGroup")}
            </Button>
          )}
          {selectedUsers.length !== 0 && currentGroup && (
            <Button
              onClick={handleDeleteUsersFromGroup}
              startIcon={
                <SvgIcon>
                  <DeleteIcon />
                </SvgIcon>
              }
              variant="contained"
              sx={{ width: "200px", height: "54px", marginRight: "20px", marginBottom: isSearchContainer ? "24px !important" : "0px !important" }}>
              {t("views.redesignUserListSearch.removeFromGroup")}
            </Button>
          )}
          <FormControl sx={{ width: "200px", marginLeft: "auto" }} fullWidth>
            <InputLabel id="user-sort-by-label">{t("views.redesignUserListSearch.sortBy")}</InputLabel>
            <Select labelId="user-sort-by-label" id="user-sort-by" value={sortByValue} label={t("views.redesignUserListSearch.sortBy")} onChange={handleSortSelect}>
              <MenuItem value={"email_desc"}>{t("views.redesignUserListSearch.sortOptions.emailDesc")}</MenuItem>
              <MenuItem value={"email_asc"}>{t("views.redesignUserListSearch.sortOptions.emailAsc")}</MenuItem>
              <MenuItem value={"name_desc"}>{t("views.redesignUserListSearch.sortOptions.nameDesc")}</MenuItem>
              <MenuItem value={"name_asc"}>{t("views.redesignUserListSearch.sortOptions.nameAsc")}</MenuItem>
              <MenuItem value={"createdAt_desc"}>{t("views.redesignUserListSearch.sortOptions.dateDesc")}</MenuItem>
              <MenuItem value={"createdAt_asc"}>{t("views.redesignUserListSearch.sortOptions.dateAsc")}</MenuItem>
              <MenuItem value={"status_desc"}>{t("views.redesignUserListSearch.sortOptions.statusDesc")}</MenuItem>
              <MenuItem value={"status_asc"}>{t("views.redesignUserListSearch.sortOptions.statusAsc")}</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Stack>
    </>
  );
};

export default RedesignUserListSearch;
