import React, { useState, useRef } from "react";
import { FaRegFolderOpen, FaGoogleDrive, FaDropbox } from "react-icons/fa";
import { AiFillCloud } from "react-icons/ai";
import { Box, Dialog, DialogContent, DialogTitle, Typography, DialogActions, Button } from "@mui/material";
import DropboxChooser from "react-dropbox-chooser";
import { useMediaQuery } from "react-responsive";
import Camera from "../../views/files/components/Camera";
import useUserFeature from "../../hooks/use-user-feature";
import { Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";

function AddContentModel({ browse, onClose, fileUpload, fileUploadDragDrop, onGoogleDrive, onDropboxFiles, onOneDrive }) {
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);
  const [camera, setCamera] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

  const handleDropboxFiles = (files) => {
    onDropboxFiles(files);
  };

  const openCamera = () => {
    setCamera(true);
    onClose(false);
  };
  const openMenu = () => {
    setCamera(false);
  };

  const dropboxEnabled = useUserFeature("dropbox_access");
  const onedriveEnabled = useUserFeature("onedrive_access");
  const googleDriveEnabled = useUserFeature("google_drive_access");

  return (
    <>
      <Dialog open={browse} onClose={() => onClose(false)} onDragOver={(e) => e.preventDefault()} onDrop={fileUploadDragDrop}>
        <DialogTitle>{t("components.addContentModel.browseFiles")}</DialogTitle>
        <DialogContent sx={{ overflow: "hidden" }}>
          <Box
            sx={{
              width: isMobile ? "280px" : "500px",
              height: isMobile ? "400px" : "200px",
            }}>
            <Box
              sx={{
                height: isMobile ? "400px" : "230px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <form encType="multipart/form-data">
                <input type={"file"} hidden id="click-browser-computer" multiple onChange={fileUpload} />
              </form>

              <Typography
                variant={"caption"}
                sx={{
                  fontFamily: "open sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "12px",
                }}>
                {t("components.addContentModel.dropFilesHere")}{" "}
                <label
                  htmlFor="click-browser-computer"
                  style={{
                    cursor: "pointer",
                    fontWeight: "700",
                  }}>
                  <bold>{t("components.addContentModel.browseFiles")}</bold>
                </label>{" "}
                {t("components.addContentModel.orImportFrom")}:
              </Typography>

              <Box
                sx={{
                  height: isMobile ? "240px" : "80px",
                  width: "90%",
                  margin: "10px auto",
                  padding: "20px",
                  borderRadius: "10px",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "20px",
                  justifyContent: isMobile ? "space-around" : "center",
                  alignItems: isMobile ? "center" : "flex-start",
                }}>
                <label
                  htmlFor="click-browser-computer"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <Box
                    sx={{
                      background: "#2275D7",
                      height: "30px",
                      width: "30px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <FaRegFolderOpen style={{ color: "white" }} />
                  </Box>
                  <Typography variant={"caption"}>{t("components.addContentModel.myDevice")}</Typography>
                </label>
                {googleDriveEnabled ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={onGoogleDrive}>
                    <Box
                      sx={{
                        background: "#4385F4",
                        height: "30px",
                        width: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      <FaGoogleDrive style={{ color: "white" }} />
                    </Box>
                    <Typography variant={"caption"}>{t("components.addContentModel.googleDrive")}</Typography>
                  </Box>
                ) : (
                  <Tooltip title={t("components.addContentModel.googleDriveUnavailable")}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                        justifyContent: "center",
                        alignItems: "center",
                        filter: "grayscale(1)",
                      }}>
                      <Box
                        sx={{
                          background: "#4385F4",
                          height: "30px",
                          width: "30px",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}>
                        <FaGoogleDrive style={{ color: "white" }} />
                      </Box>
                      <Typography variant={"caption"}>{t("components.addContentModel.googleDrive")}</Typography>
                    </Box>
                  </Tooltip>
                )}

                {dropboxEnabled ? (
                  <DropboxChooser
                    appKey="5wni26okwj0omnq"
                    success={handleDropboxFiles}
                    disabled={!dropboxEnabled}
                    multiselect={true}
                    extensions={[".pdf", ".docx", ".xlsx", ".png", ".jpg"]}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      <Box
                        sx={{
                          background: "#163A81",
                          height: "30px",
                          width: "30px",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}>
                        <FaDropbox style={{ color: "white" }} />
                      </Box>
                      <Typography variant={"caption"}>{t("components.addContentModel.dropbox")}</Typography>
                    </Box>
                  </DropboxChooser>
                ) : (
                  <Tooltip title={t("components.addContentModel.dropboxUnavailable")}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                        justifyContent: "center",
                        alignItems: "center",
                        filter: "grayscale(1)",
                      }}>
                      <Box
                        sx={{
                          background: "#163A81",
                          height: "30px",
                          width: "30px",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}>
                        <FaDropbox style={{ color: "white" }} />
                      </Box>
                      <Typography variant={"caption"}>{t("components.addContentModel.dropbox")}</Typography>
                    </Box>
                  </Tooltip>
                )}

                {onedriveEnabled ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={onOneDrive}>
                    <Box
                      sx={{
                        background: "#0062C0",
                        height: "30px",
                        width: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      <AiFillCloud style={{ color: "white" }} />
                    </Box>
                    <Typography variant={"caption"}>{t("components.addContentModel.oneDrive")}</Typography>
                  </Box>
                ) : (
                  <Tooltip title={t("components.addContentModel.oneDriveUnavailable")}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                        justifyContent: "center",
                        alignItems: "center",
                        filter: "grayscale(1)",
                      }}>
                      <Box
                        sx={{
                          background: "#0062C0",
                          height: "30px",
                          width: "30px",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}>
                        <AiFillCloud style={{ color: "white" }} />
                      </Box>
                      <Typography variant={"caption"}>{t("components.addContentModel.oneDrive")}</Typography>
                    </Box>
                  </Tooltip>
                )}
              </Box>
              <DialogActions>
                <Button onClick={() => onClose(false)}>{t("components.addContentModel.close")}</Button>
              </DialogActions>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Camera cameraOpen={camera} onClose={openMenu} />
    </>
  );
}

export default AddContentModel;
