import { Box, Checkbox, Typography, List, ListItem } from "@mui/material";
import React from "react";
function PlaylistList({ data, onClick, onChange, user }) {
  return (
    <>
      <ListItem onClick={() => onClick(data._id)} disableGutters divider sx={{ justifyContent: "space-between", padding: 2 }}>
        <Typography variant="subtitle2">{data.playlistName}</Typography>

        {user.isMember ? (
          user.previlliages.playlist.canDelete ? (
            <Box>
              <Checkbox value={data._id} onChange={(e) => onChange(e)} />
            </Box>
          ) : (
            <></>
          )
        ) : (
          <Box>
            <Checkbox value={data._id} onChange={(e) => onChange(e)} />
          </Box>
        )}
      </ListItem>
    </>
  );
}

export default PlaylistList;
