import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { paths } from "src/paths";

import ConfirmDialog from "src/components/dialog/ConfirmDialog";
import { deleteUser } from "src/redux/actions/User";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";

export const CustomerDataManagement = ({ user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const handleUserAccountDelete = useCallback(async () => {
    try {
      await dispatch(deleteUser(user._id));
      toast.success(t("views.customerDataManagement.deleteSuccess"));
    } catch (error) {
      toast.error(t("views.customerDataManagement.deleteError"));
    } finally {
      navigate(paths.users.index);
      setConfirmOpen(false);
    }
  }, [user, dispatch, navigate, t]);

  return (
    <>
      <Card>
        <CardHeader title={t("views.customerDataManagement.accountManagement")} />
        <CardContent sx={{ pt: 0 }}>
          <Button color="error" variant="outlined" onClick={() => setConfirmOpen(true)}>
            {t("views.customerDataManagement.deleteAccount")}
          </Button>
          <Box sx={{ mt: 1 }}>
            <Typography color="text.secondary" variant="body2">
              {t("views.customerDataManagement.deleteWarning")}
            </Typography>
          </Box>
        </CardContent>
      </Card>
      <ConfirmDialog
        open={isConfirmOpen}
        title={t("views.customerDataManagement.confirmDelete")}
        onClose={() => setConfirmOpen(false)}
        onAccept={handleUserAccountDelete}
      />
    </>
  );
};
