import { api } from "../../api/endpoints";
import { PENDING_WIDGETS_GRID, EDIT_WIDGETS_GRID, DELETE_WIDGETS_GRID, INIT_WIDGETS_GRID, ADD_WIDGET_TO_GRID, EDIT_WIDGET_IN_GRID, DELETE_WIDGET_FROM_GRID, UPDATE_WIDGETS_IN_GRID } from "../constant";

export const getWidgetGrid = (gridName) => {
    return async (dispatch) => {
        dispatch({
            type: PENDING_WIDGETS_GRID, payload: { gridName }
        });

        const { status, data } = await api.get(`/widgets/${gridName}`);
        if (status == 200)
            dispatch({
                type: INIT_WIDGETS_GRID,
                payload: {
                    gridName,
                    widgets: data.widgets,
                    draft: data?.draft?.name ?? null
                }
            });
    };
};

export const editWidgetGrid = (gridName) => {
    return async (dispatch) => {
        dispatch({
            type: PENDING_WIDGETS_GRID, payload: { gridName }
        });

        const { status, data } = await api.post(`/widgets/${gridName}/edit`);
        if (status == 200) {
            dispatch({
                type: INIT_WIDGETS_GRID,
                payload: {
                    gridName: data.name,
                    widgets: data.widgets,
                    draft: null
                }
            });

            dispatch({
                type: EDIT_WIDGETS_GRID,
                payload: {
                    gridName,
                    draft: data.name
                }
            });
        }
    };
};

export const saveWidgetGrid = (gridName, draftName) => {
    return async (dispatch) => {
        dispatch({
            type: PENDING_WIDGETS_GRID, payload: { draftName }
        });
        dispatch({
            type: PENDING_WIDGETS_GRID, payload: { gridName }
        });

        const { status, data } = await api.patch(`/widgets/${gridName}/save`);
        if (status == 200) {
            dispatch({
                type: INIT_WIDGETS_GRID,
                payload: {
                    gridName,
                    widgets: data.widgets,
                    draft: null
                }
            });

            dispatch({
                type: DELETE_WIDGETS_GRID,
                payload: {
                    draftName
                }
            });
        }
    };
};

export const discardWidgetGridChanges = (gridName, draftName) => {
    return async (dispatch) => {
        dispatch({
            type: PENDING_WIDGETS_GRID, payload: { draftName }
        });
        dispatch({
            type: PENDING_WIDGETS_GRID, payload: { gridName }
        });

        const { status, data } = await api.delete(`/widgets/${gridName}/discard`);
        if (status == 200) {
            dispatch({
                type: INIT_WIDGETS_GRID,
                payload: {
                    gridName,
                    widgets: data.widgets,
                    draft: null
                }
            });

            dispatch({
                type: DELETE_WIDGETS_GRID,
                payload: {
                    draftName
                }
            });
        }
    };
}

export const addWidgetToGrid = (gridName, widgetData) => {
    return async (dispatch) => {
        dispatch({
            type: PENDING_WIDGETS_GRID, payload: { gridName }
        });

        const { status, data } = await api.post(`/widgets/${gridName}`, widgetData);
        if (status == 200)
            dispatch({
                type: ADD_WIDGET_TO_GRID,
                payload: {
                    gridName,
                    widget: data
                }
            });
    };
};

export const editWidgetInGrid = (gridName, widgetData) => {
    return async (dispatch) => {
        dispatch({
            type: PENDING_WIDGETS_GRID, payload: { gridName }
        });

        const { status, data } = await api.patch(`/widgets/${gridName}/${widgetData._id}`, widgetData);
        if (status == 200)
            dispatch({
                type: EDIT_WIDGET_IN_GRID,
                payload: {
                    gridName,
                    widget: data
                }
            });
    };
};

export const deleteWidgetFromGrid = (gridName, widgetData) => {
    return async (dispatch) => {
        dispatch({
            type: PENDING_WIDGETS_GRID, payload: { gridName }
        });

        const { status } = await api.delete(`/widgets/${gridName}/${widgetData._id}`);
        if (status == 200)
            dispatch({
                type: DELETE_WIDGET_FROM_GRID,
                payload: {
                    gridName,
                    widget: widgetData
                }
            });
    };
};

export const swapWidgetsInGrid = (gridName, widgetsData) => {
    return async (dispatch) => {
        dispatch({
            type: PENDING_WIDGETS_GRID, payload: { gridName }
        });

        const { status, data } = await api.patch(`/widgets/${gridName}/swap`, [...widgetsData]);
        if (status == 200)
            dispatch({
                type: UPDATE_WIDGETS_IN_GRID,
                payload: {
                    gridName,
                    widgets: data
                }
            });
    };
};