import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

export const OptionsNavColor = (props) => {
  const { t } = useTranslation();
  const options = [
    {
      label: t("components.optionsNavColor.blendIn"),
      value: "blend-in",
    },
    {
      label: t("components.optionsNavColor.discreet"),
      value: "discreet",
    },
    {
      label: t("components.optionsNavColor.evident"),
      value: "evident",
    },
  ];

  const { onChange, value } = props;

  return (
    <Stack spacing={1}>
      <Typography color="text.secondary" variant="overline">
        {t("components.optionsNavColor.navColor")}
      </Typography>
      <Stack alignItems="center" direction="row" flexWrap="wrap" gap={2}>
        {options.map((option) => (
          <Chip
            key={option.label}
            label={option.label}
            onClick={() => onChange?.(option.value)}
            sx={{
              borderColor: "transparent",
              borderRadius: 1.5,
              borderStyle: "solid",
              borderWidth: 2,
              ...(option.value === value && {
                borderColor: "primary.main",
              }),
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
};
