import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import ConfirmDialog from "src/components/dialog/ConfirmDialog";

import { groupDelete } from "src/redux/actions/Group";

const GroupDeleteDialog = ({ group, open, onClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const title = useMemo(() => t("views.groupDeleteDialog.confirmDelete", { groupName: group?.name }), [group, t]);

  const handleAccept = async () => {
    await dispatch(groupDelete(group._id));
    onClose?.();
  };

  return <ConfirmDialog open={open} title={title} onClose={onClose} onAccept={handleAccept} />;
};

export default GroupDeleteDialog;
