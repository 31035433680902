import * as Yup from "yup";
import { CardContent, CardHeader } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import { RedesignChart } from "../../chart/RedesignChart";

const useChartOptions = () => {
  const { t } = useTranslation();

  const theme = useTheme();

  return {
    chart: {
      background: "transparent",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    colors: [theme.palette.primary.main, theme.palette.warning.main],
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
      type: "solid",
    },
    grid: {
      borderColor: theme.palette.divider,
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      horizontalAlign: "right",
      labels: {
        colors: theme.palette.text.secondary,
      },
      position: "top",
      show: true,
    },
    markers: {
      hover: {
        size: undefined,
        sizeOffset: 2,
      },
      radius: 2,
      shape: "circle",
      size: 4,
      strokeWidth: 0,
    },
    stroke: {
      curve: "smooth",
      dashArray: [0, 3],
      lineCap: "butt",
      width: 3,
    },
    theme: {
      mode: theme.palette.mode,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: [
        t("components.exampleWidget.1Jan"),
        t("components.exampleWidget.2Jan"),
        t("components.exampleWidget.3Jan"),
        t("components.exampleWidget.4Jan"),
        t("components.exampleWidget.5Jan"),
        t("components.exampleWidget.6Jan"),
        t("components.exampleWidget.7Jan"),
        t("components.exampleWidget.8Jan"),
        t("components.exampleWidget.9Jan"),
        t("components.exampleWidget.10Jan"),
        t("components.exampleWidget.11Jan"),
        t("components.exampleWidget.12Jan"),
      ],
      labels: {
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
    yaxis: [
      {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: theme.palette.text.secondary,
          },
        },
      },
      {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: theme.palette.text.secondary,
          },
        },
        opposite: true,
      },
    ],
  };
};

//Widget content - required
export const WidgetContent = ({ widgetData, editMode }) => {
  const { t } = useTranslation();

  const chartOptions = useChartOptions();
  const chartSeries = [
    {
      name: t("components.exampleWidget.screenViews"),
      data: [3350, 1840, 2254, 5780, 9349, 5241, 2770, 2051, 3764, 2385, 5912, 8323],
    },
    {
      name: t("components.exampleWidget.sessionDuration"),
      data: [35, 41, 62, 42, 13, 18, 29, 37, 36, 51, 32, 35],
    },
  ];

  return (
    <CardContent>
      <RedesignChart height={300} options={chartOptions} series={chartSeries} type="line" />
    </CardContent>
  );
};

//Widget header - not required
export const WidgetHeader = ({ widgetData, actions, editMode }) => {
  const { t } = useTranslation();
  return <CardHeader title={t("components.exampleWidget.analytics")} action={actions} />;
};

//Widget settings
export const type = "example";
export const displayName = "Example Widget";
export const configOptions = {};
