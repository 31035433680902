import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import toast from "react-hot-toast";
import { getPlans } from "src/redux/actions/Plan";
import {
  createSubscription,
  updateSubscription,
  cancelSubscription,
} from "./api/subscriptionService";
import ModalsSection from "./ModalsSection";
import { useUserSubscription } from "./hooks/useUserSubscription";
import { planRank } from "./config/planRank";
import UserSubscriptionListTable from "../UserSubscriptionListTable/UserSubscriptionListTable";
import UserAddOnsTable from "../UserAddOnsTable/UserAddOnsTable";

const freePlanId = "660ea1fce37af958e2586a53";

const UserSubscriptions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { plans } = useSelector((state) => state.Plan);
  const user = useSelector((state) => state.User.user);

  const stripeCustomerId = user?.stripeCustomerId;
  const customerId = user?._id;

  const { userSubscription, isLoadingSubscription, fetchUserSubscription } =
    useUserSubscription(stripeCustomerId);

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [changeModalOpen, setChangeModalOpen] = useState(false);
  const [changeActionType, setChangeActionType] = useState(null);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [purchaseModalOpen, setPurchaseModalOpen] = useState(false);
  const [selectedInterval, setSelectedInterval] = useState("month");
  const [addOns, setAddOns] = useState({});
  const [showAddOns, setShowAddOns] = useState(false);

  const currentPlanName = userSubscription?.planName || "Free";
  const isFree = currentPlanName === "Free";
  let currentPlanId = null;
  if (currentPlanName && plans?.length > 0) {
    const foundPlan = plans.find((p) => p.name === currentPlanName);
    if (foundPlan) {
      currentPlanId = foundPlan._id;
    }
  }

  React.useEffect(() => {
    dispatch(getPlans());
  }, [dispatch]);

  const handleChoosePlan = useCallback(
    (planId) => {
      const plan = plans.find((p) => p._id === planId);
      setSelectedPlan(plan);
      if (isFree) {
        setPurchaseModalOpen(true);
      }
    },
    [plans, isFree]
  );
  React.useEffect(() => {}, [selectedPlan]);
  const handleShowCancelModal = () => setCancelModalOpen(true);

  const handleCancelModalClose = () => setCancelModalOpen(false);

  const handleConfirmCancel = useCallback(async () => {
    if (!userSubscription?.id) return;
    const data = await cancelSubscription(userSubscription.id);
    if (data) {
      await fetchUserSubscription();
      setCancelModalOpen(false);
      toast.success(t("views.userSubscriptions.cancelSub"));
    } else {
      toast.error(t("views.userSubscriptions.errorSub"));
    }
  }, [userSubscription, fetchUserSubscription]);

  const handleConfirmPurchase = useCallback(async () => {
    if (!selectedPlan || !customerId) {
      toast.error(t("views.userSubscriptions.invalidOperation"));
      return;
    }

    const existingItems = (userSubscription?.items || []).map((item) => ({
      name: item.name,
      unitAmount: item.unitAmount || item.price || 0,
      quantity: item.quantity,
    }));

    const updatedItems = existingItems.filter(
      (item) => item.name !== userSubscription?.planName
    );

    const planPrice =
      selectedInterval === "year"
        ? selectedPlan.price * 12
        : selectedPlan.price;

    const newPlanItem = {
      name: selectedPlan.name,
      unitAmount: planPrice,
      quantity: 1,
    };

    updatedItems.push(newPlanItem);

    const payload = {
      items: updatedItems,
      planName: selectedPlan.name,
      customerId: customerId,
      currency: "usd",
      interval: selectedInterval,
    };

    try {
      if (userSubscription?.id) {
        const data = await updateSubscription(payload);
        if (data) {
          await fetchUserSubscription();
          toast.success(t("views.userSubscriptions.planUpdated"));
        } else {
          toast.error(t("views.userSubscriptions.errorUpdating"));
        }
      } else {
        const data = await createSubscription(payload);
        if (data?.url) {
          toast.success(t("views.userSubscriptions.redirectionToPay"));
          window.location.href = data.url;
        } else {
          toast.error(t("views.userSubscriptions.errorCreatingSub"));
        }
      }
    } catch (error) {
      console.error(t("views.userSubscriptions.errorOccurred"), error);
      toast.error(t("views.userSubscriptions.errorOccurred"));
    }
  }, [
    selectedPlan,
    customerId,
    selectedInterval,
    userSubscription,
    fetchUserSubscription,
  ]);
  const handleChangeSubscription = useCallback(
    (planId, type) => {
      const plan = plans.find((p) => p._id === planId);
      setSelectedPlan(plan);
      setChangeActionType(type);
      setChangeModalOpen(true);
    },
    [plans]
  );

  const handleConfirmSubscriptionChange = useCallback(async () => {
    if (!selectedPlan || !userSubscription?.id) {
      toast.error(t("views.userSubscriptions.invalidOperation"));
      return;
    }

    const existingItems = (userSubscription?.items || []).map((item) => ({
      name: item.name,
      unitAmount: item.unitAmount || item.price || 0,
      quantity: item.quantity,
    }));

    const updatedItems = existingItems.filter(
      (item) => item.name !== userSubscription.planName
    );

    const newPlanItem = {
      name: selectedPlan.name,
      unitAmount: selectedPlan.price,
      quantity: 1,
    };

    updatedItems.push(newPlanItem);

    const payload = {
      subscriptionId: userSubscription.id,
      items: updatedItems,
      planName: selectedPlan.name,
      currency: "usd",
      interval: "month",
    };

    try {
      const data = await updateSubscription(payload);
      if (data) {
        await fetchUserSubscription();
        setChangeModalOpen(false);
        setSelectedPlan(null);
        setChangeActionType(null);
        toast.success(t("views.userSubscriptions.planUpdated"));
      } else {
        toast.error(t("views.userSubscriptions.errorUpdating"));
      }
    } catch (error) {
      console.error(t("views.userSubscriptions.errorOccurred"), error);
      toast.error(t("views.userSubscriptions.errorOccurred"));
    }
  }, [selectedPlan, userSubscription, fetchUserSubscription]);

  const handleConfirmAddOnsPurchase = useCallback(async () => {
    const allAddOns = Object.entries(addOns).map(
      ([featureId, { quantity, unitPrice, featureName }]) => ({
        name: featureName,
        unitAmount: unitPrice,
        quantity: quantity,
      })
    );

    const validAddOns = allAddOns.filter((item) => item.quantity > 0);

    const existingItems = (userSubscription?.items || []).map((item) => ({
      name: item.name,
      unitAmount: item.unitAmount || item.price || 0,
      quantity: item.quantity,
    }));

    const updatedItems = existingItems
      .map((item) => {
        const foundAddOn = allAddOns.find((addOn) => addOn.name === item.name);
        if (foundAddOn) {
          return { ...item, quantity: foundAddOn.quantity };
        }
        return item;
      })
      .filter((item) => item.quantity > 0);

    const finalItems = [...updatedItems, ...validAddOns].filter(
      (item, index, self) =>
        item.quantity > 0 &&
        self.findIndex((i) => i.name === item.name) === index
    );

    if (finalItems.length === 0) {
      toast.error(t("views.userSubscriptions.noNewAdd"));
      return;
    }

    const payload = {
      subscriptionId: userSubscription?.id || null,
      items: finalItems,
      planName: userSubscription?.planName || "Free",
      currency: "usd",
      interval: "month",
    };

    try {
      if (userSubscription?.id) {
        const data = await updateSubscription(payload);
        if (data) {
          await fetchUserSubscription();
          toast.success(t("views.userSubscriptions.planUpdated"));
        } else {
          toast.error(t("views.userSubscriptions.errorUpdatingSub"));
        }
      } else {
        const newPayload = {
          ...payload,
          items: [
            {
              name: "Free",
              unitAmount: 0,
              quantity: 1,
            },
            ...finalItems,
          ],
          planName: "Free",
          customerId: customerId,
        };

        const data = await createSubscription(newPayload);
        if (data?.url) {
          toast.success(t("views.userSubscriptions.redirectionToPay"));
          window.location.href = data.url;
        } else {
          toast.error(t("views.userSubscriptions.errorCreatingSub"));
        }
      }
    } catch (error) {
      console.error(t("views.userSubscriptions.errorOccurred"), error);
      toast.error(t("views.userSubscriptions.errorOccurred"));
    }
  }, [userSubscription, addOns, fetchUserSubscription]);

  return (
    <Box component="main" sx={{ flexGrow: 1, py: 4 }}>
      <Container maxWidth="false">
        <Typography variant="h4" sx={{ mb: 4 }}>
          {t("views.userSubscriptions.title")}
        </Typography>

        {isLoadingSubscription ? (
          <div>{t("views.userSubscriptions.loadingSub")}</div>
        ) : (
          <>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {t("views.userSubscriptions.currentPlan")} {currentPlanName}
            </Typography>

            <UserSubscriptionListTable
              plans={plans}
              currentPlanId={currentPlanId || freePlanId}
              planRank={planRank}
              freePlanId={freePlanId}
              userSubscription={userSubscription}
              handleCancelSubscription={handleShowCancelModal}
              handleChangeSubscription={handleChangeSubscription}
              handleChoosePlan={handleChoosePlan}
            />

            <Box mt={4}>
              <Button
                variant="outlined"
                onClick={() => setShowAddOns((prev) => !prev)}
              >
                {showAddOns
                  ? t("views.userSubscriptions.hideAdditional")
                  : t("views.userSubscriptions.showAdditional")}
              </Button>
            </Box>

            {showAddOns && (
              <Box mt={4}>
                <UserAddOnsTable
                  plans={plans}
                  addOns={addOns}
                  setAddOns={setAddOns}
                  userSubscription={userSubscription}
                />

                <Box mt={2} display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleConfirmAddOnsPurchase}
                  >
                    {userSubscription?.id
                      ? t("views.userSubscriptions.updateSub")
                      : t("views.userSubscriptions.buyNow")}
                  </Button>
                </Box>
              </Box>
            )}
          </>
        )}
      </Container>

      <ModalsSection
        changeModalOpen={changeModalOpen}
        handleCancelChange={() => {
          setChangeModalOpen(false);
          setSelectedPlan(null);
          setChangeActionType(null);
        }}
        handleConfirmSubscriptionChange={handleConfirmSubscriptionChange}
        changeActionType={changeActionType}
        cancelModalOpen={cancelModalOpen}
        handleCancelModalClose={handleCancelModalClose}
        handleConfirmCancel={handleConfirmCancel}
        handleConfirmPurchase={handleConfirmPurchase}
        purchaseModalOpen={purchaseModalOpen}
        handlePurchaseModalClose={() => {
          setPurchaseModalOpen(false);
          setSelectedPlan(null);
          setSelectedInterval("month");
        }}
        selectedInterval={selectedInterval}
        setSelectedInterval={setSelectedInterval}
      />
    </Box>
  );
};

export default UserSubscriptions;
