import { useState, useEffect, useCallback } from "react";
import { fetchSubscription } from "../api/subscriptionService";

export const useUserSubscription = (stripeCustomerId) => {
  const [userSubscription, setUserSubscription] = useState(null);
  const [isLoadingSubscription, setIsLoadingSubscription] = useState(true);

  const fetchData = useCallback(async () => {
    try {
      const subscription = await fetchSubscription(stripeCustomerId);
      setUserSubscription(subscription);
      setIsLoadingSubscription(false);
    } catch (error) {
      console.error(error);
      setIsLoadingSubscription(false);
    }
  }, [stripeCustomerId]);

  useEffect(() => {
    if (stripeCustomerId) {
      fetchData();
    } else {
      setIsLoadingSubscription(false);
    }
  }, [stripeCustomerId, fetchData]);

  return {
    userSubscription,
    isLoadingSubscription,
    fetchUserSubscription: fetchData,
  };
};
