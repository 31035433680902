export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_BLOCKED = "LOGIN_BLOCKED";
export const LOGIN_FLAG_OFF = "LOGIN_FLAG_OFF";
export const FACEBOOK_LOGIN_REQUEST = "LOGIN_FACEBOOK_REQUEST";
export const LOGIN_WITH_FACEBOOK = "LOGIN_WITH_FACEBOOK";
export const LOGOUT = "LOGOUT";
export const REFRESH_LOGIN = "REFRESH_LOGIN";
export const VERIFY_SIGNUP_REQUEST = "VERIFY_SIGNUP_REQUEST";
export const VERIFY_SIGNUP_SUCCESS = "VERIFY_SIGNUP_SUCCESS";
export const PASSWORD_CHANGE_EMAIL = "PASSWORD_CHANGE_EMAIL";
export const INVALID_PASSWORD_CHANGE_EMAIL = "INVALID_PASSWORD_CHANGE_EMAIL";
export const PASSWORD_CHANGE_REQUEST = "PASSWORD_CHANGE_REQUEST";
export const PASSWORD_CHANGE_TOKEN_EXPIRED = "PASSWORD_CHANGE_TOKEN_EXPIRED";
export const PASSWORD_CHANGED = "PASSWORD_CHANGED";
export const PASSWORD_CHANGE_ERROR = "PASSWORD_CHANGE_ERROR";
export const CURRENT_PASSWORD_INVALID = "CURRENT_PASSWORD_INVALID";

// signup
export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_SUCCESS_FLAG_OFF = "SIGNUP_SUCCESS_FLAG_OFF";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const DELETE_USER_PROFILE = "DELETE_USER_PROFILE";
export const GET_SINGLE_USER = "GET_SINGLE_USER";
export const BLOCKED_USER = "BLOCKED_USER";
export const USER_DESC_ORDER_BY_EMAIL = "USER_DESC_ORDER_BY_EMAIL";
export const USER_ASC_ORDER_BY_EMAIL = "USER_ASC_ORDER_BY_EMAIL";
export const USER_DESC_ORDER_BY_NAME = "USER_DESC_ORDER_BY_NAME";
export const USER_ASC_ORDER_BY_NAME = "USER_ASC_ORDER_BY_NAME";
export const USER_DESC_ORDER_BY_DATE = "USER_DESC_ORDER_BY_DATE";
export const USER_ASC_ORDER_BY_DATE = "USER_ASC_ORDER_BY_DATE";
export const USER_DESC_ORDER_BY_STATUS = "USER_DESC_ORDER_BY_STATUS";
export const USER_ASC_ORDER_BY_STATUS = "USER_ASC_ORDER_BY_STATUS";
export const SET_USERS_GROUP = "SET_USERS_GROUP";
export const REMOVE_USERS_GROUP = "REMOVE_USERS_GROUP";
// signup end
// playlist
export const ADD_PLAYLIST_SUCCESS = "ADD_PLAYLIST_SUCCESS";
export const ADD_PLAYLIST_FAILED = "ADD_PLAYLIST_FAILED";
export const GET_PLAYLIST_SUCCESS = "GET_PLAYLIST_SUCCESS";
export const GET_ONE_PLAYLIST = "GET_ONE_PLAYLIST";
export const GET_PLAYLIST_REQUEST = "GET_PLAYLIST_REQUEST";
export const GET_PLAYLIST_FAILED = "GET_PLAYLIST_SUCCESS";
export const ADD_CONTENT_IN_PLAYLIST = "ADD_CONTENT_IN_PLAYLIST";
export const ADD_SCREEN_TO_PLAYLIST = "ADD_SCREEN_TO_PLAYLIST";
export const PLAYLIST_SCREEN_CHECK = "PLAYLIST_SCREEN_CHECK";
export const DELETE_CONTENT_FROM_PLAYLIST = "DELETE_CONTENT_FROM_PLAYLIST";
export const DELETE_PLAYLIST = "DELETE_PLAYLIST";
export const UPDATE_DURATION = "UPDATE_DURATION";
export const PLAYLIST_FLAG_OFF = "PLAYLIST_FLAG_OFF";
export const EDIT_PLAYLIST_NAME = "EDIT_PLAYLIST_NAME";
export const EDIT_PLAYLIST_DEMO_ATTRIBUTE = "EDIT_PLAYLIST_DEMO_ATTRIBUTE";
export const UNCHECKED_TIME_LEBEL = "UNCHECKED_TIME_LEBEL";
export const PLAYLIST_CONETNT_SORTING = "PLAYLIST_CONTENT_SORTING";
// playlist end
// content
export const ADD_CONTENT_REQUEST = "ADD_CONTENT_REQUEST";
export const ADD_CONTENT = "ADD_CONTENT";
export const ADD_CONTENT_FAILED = "ADD_CONTENT_FAILED";
export const GET_CONTENT_SUCCESS = "GET_CONTENT_SUCCESS";
export const GET_CONTENT_FAILED = "GET_CONTENT_FAILED";
export const CONTENT_DELETE_REQUEST = "CONTENT_DELETE_REQUEST";
export const CONTENT_DELETE_SUCCESS = "CONTENT_DELETE_SUCCESS";
export const CONTENT_FLAG_OFF = "CONTENT_FLAG_OFF";
export const CONTENT_MOVE_TO_FOLDER = "CONTENT_MOVE_TO_FOLDER";
export const CONTENT_DESC_ORDER_BY_CREATEDAT =
  "CONTENT_DESC_ORDER_BY_CREATEDAT";
export const CONTENT_ASC_ORDER_BY_CREATEDAT = "CONTENT_ASC_ORDER_BY_CREATEDAT";
export const CONTENT_DESC_ORDER_BY_NAME = "CONTENT_DESC_ORDER_BY_NAME";
export const CONTENT_ASC_ORDER_BY_NAME = "CONTENT_ASC_ORDER_BY_NAME";
export const CHECKED_CONTENT = "CHECKED_CONTENT";
export const UNCHECKED_CONTENT = "UNCHECKED_CONTENT";
export const CHECKED_SINGLE_CONTENT = "CHECKED_SINGLE_CONTENT";
export const UNCHECKED_SINGLE_CONTENT = "UNCHECKED_SINGLE_CONTENT";
export const DELETE_BULK_CONTENT = "DELETE_BULK_CONTENT";
export const RESTORE_FILES_REQUEST = "RESTORE_FILES_REQUEST";
export const RESTORE_FILES_SUCCESS = "RESTORE_FILES_SUCCESS";
export const GET_CONTENT_FROM_GOOGLE_DRIVE = "GET_CONTENT_FROM_GOOGLE_DRIVE";
export const PICKING_DATA_FROM_GOOGLE = "PICKING_DATA_FROM_GOOGLE";
export const PICKED_DATA_FROM_GOOGLE = "PICKED_DATA_FROM_GOOGLE";
export const UPLOAD_FAILED = "UPLOAD_FAILED";
export const CONTENT_MOVE_REQUEST = "CONTENT_MOVE_REQUEST";
export const CONTENT_MOVE_SUCCESS = "CONTENT_MOVE_SUCCESS";
export const CONTENT_MOVE_FAILED = "CONTENT_MOVE_FAILED";

// content end
// screen
export const SCREEN_REGISTER = "SCREEN_REGISTER";
export const SCREEN_REGISTER_FLAG_OFF = "SCREEN_REGISTER_FLAG_OFF";
export const GET_ALL_SCREEN_SUCCESS = "GET_ALL_SCREEN_SUCCESS";
export const GET_ONE_SCREEN = "GET_ONE_SCREEN";
export const SCREEN_STATUS_ACTIVE = "SCREEN_STATUS_ACTIVE";
export const SCREEN_STATUS_DEACTIVE = "SCREEN_STATUS_DEACTIVE";
export const DELETE_SCREEN = "DELETE_SCREEN";
export const DELETE_SCREEN_REQUEST = "DELETE_SCREEN_REQUEST";
export const EDIT_SCREEN = "EDIT_SCREEN";
export const SCREEN_MOVE_TO_FOLDER = "SCREEN_MOVE_TO_FOLDER";
export const SCREEN_PAIRED = "SCREEN_PAIRED";
export const SCREEN_UNPAIRED = "SCREEN_UNPAIRED";
// screen end
// apk
export const FETCH_APK_REQUEST = "FETCH_APK_REQUEST";
export const FETCH_APK_SUCCESS = "FETCH_APK_SUCCESS";
export const ADD_APK = "ADD_APK";
export const ADD_APK_SUCCESS = "ADD_APK_SUCCESS";
export const APK_ALREADY_SAVED = "APK_ALREADY_SAVED";
export const APK_INDENTITY = "APK_INDENTITY";
export const APK_FLAG_OFF = "APK_FLAG_OFF";
export const APK_DELETE = "APK_DELETE";
// apk end
// file folder
export const CREATE_FOLDER = "CREATE_FOLDER";
export const GET_FOLDERS = "GET_FOLDERS";
export const SET_FOLDER_ID = "SET_FOLDER_ID";
export const CHANGE_FOLDER = "CHANGE_FOLDER";
export const FOLDER_FLAG_OFF = "FOLDER_FLAG_OFF";
export const FOLDER_DELETE = "FOLDER_DELETE";
export const EDIT_FOLDER = "EDIT_FOLDER";
// group
export const CREATE_GROUP = "CREATE_GROUP";
export const GET_GROUPS = "GET_GROUPS";
export const SET_GROUP_ID = "SET_GROUP_ID";
export const CHANGE_GROUP = "CHANGE_GROUP";
export const GROUP_FLAG_OFF = "GROUP_FLAG_OFF";
export const GROUP_DELETE = "GROUP_DELETE";
export const EDIT_GROUP = "EDIT_GROUP";
export const SET_GROUPS = "SET_GROUPS";
// email
export const EMAIL_TEMP_SAVE = "EMAIL_TEMP_SAVE";
export const EMAIL_TEMP_GET = "EMAIL_TEMP_GET";
export const EDIT_TEMP_EMAIL = "EDIT_TEMP_EMAIL";
export const EMAIL_OFF_TEMP = "EDIT_OFF_TEMP";
// email end

//plans
export const CHANGE_PLAN_FEATURE = "CHANGE_PLAN_FEATURE";
export const GET_PLANS_SUCCESS = "GET_PLANS_SUCCESS";
export const GET_PLAN_SUCCESS = "GET_PLAN_SUCCESS";
export const CHANGE_PLAN_FEATURE_DRAFT = "CHANGE_PLAN_FEATURE_DRAFT";
export const SAVE_PLANS_FEATURES = "SAVE_PLANS_FEATURES";
export const SEARCH_PLANS = "SEARCH_PLANS";
export const CLEAN_DRAFT = "CLEAN_DRAFT";
export const CHANGE_PLAN_PRICE_DRAFT = "CHANGE_PLAN_PRICE_DRAFT";
export const CHANGE_PLAN_FEATURE_PRICE_DRAFT =
  "CHANGE_PLAN_FEATURE_PRICE_DRAFT ";

//Widgets grid
export const PENDING_WIDGETS_GRID = "PENDING_WIDGETS_GRID";
export const INIT_WIDGETS_GRID = "INIT_WIDGETS_GRID";
export const EDIT_WIDGETS_GRID = "EDIT_WIDGETS_GRID";
export const DELETE_WIDGETS_GRID = "DELETE_WIDGETS_GRID";
export const ADD_WIDGET_TO_GRID = "ADD_WIDGET_TO_GRID";
export const EDIT_WIDGET_IN_GRID = "EDIT_WIDGET_IN_GRID";
export const DELETE_WIDGET_FROM_GRID = "DELETE_WIDGET_FROM_GRID";
export const UPDATE_WIDGETS_IN_GRID = "UPDATE_WIDGETS_IN_GRID";

//logs
export const GET_LOGS_SUCCESS = "GET_LOGS_SUCCESS";

//user notes
export const GET_NOTES_SUCCESS = "GET_NOTES_SUCCESS";
export const SAVE_NOTE = "SAVE_NOTE";
export const DELETE_NOTE = "DELETE_NOTE";
