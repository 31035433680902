import { useState } from "react";
import { useTranslation } from "react-i18next";
import SearchMdIcon from "@untitled-ui/icons-react/build/esm/SearchMd";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import { useMediaQuery } from "react-responsive";
import { InputAdornment, OutlinedInput } from "@mui/material";

const UserGroupListSearch = (props) => {
  const { onSearchBarChange } = props;
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

  return (
    <>
      <Stack
        alignItems={isMobile ? "flex-start" : "center"}
        direction={isMobile ? "column" : "row"}
        flexWrap="wrap"
        justifyContent="space-between"
        spacing={3}
        sx={{ p: 3 }}>
        <OutlinedInput
          defaultValue=""
          fullWidth
          sx={{ maxWidth: isMobile ? "100%" : "400px" }}
          onChange={onSearchBarChange}
          name="itemName"
          placeholder={t("views.userGroupListSearch.searchPlaceholder")}
          startAdornment={
            <InputAdornment position="start">
              <SvgIcon>
                <SearchMdIcon />
              </SvgIcon>
            </InputAdornment>
          }
        />
      </Stack>
    </>
  );
};

export default UserGroupListSearch;
