import { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Table, TableBody, TableCell, TableHead, TableRow, Checkbox } from "@mui/material";
import { Scrollbar } from "src/components/scrollbar";
import RedesignUserTableRow from "./RedesignUserTableRow";

const RedesignUserListTable = ({ users, selectedUsers, setSelectedUsers }) => {
  const { t } = useTranslation();
  const tableBodyRef = useRef(null);

  useEffect(() => {
    const tableBodyElement = tableBodyRef.current;

    const handleSelectUser = (id) => {
      setSelectedUsers((selectedUsers) => {
        if (selectedUsers.indexOf(id) < 0) return [...selectedUsers, id];
        else return selectedUsers.filter((i) => i !== id);
      });
    };

    const handleClick = (e) => {
      const element = e.target;
      const userId = element.closest("[data-user]")?.getAttribute("data-user") ?? null;
      if (!userId) return;

      if (element.closest("[data-user-checkbox]")) handleSelectUser(userId);
    };

    tableBodyElement.addEventListener("click", handleClick);

    return () => {
      tableBodyElement.removeEventListener("click", handleClick);
    };
  }, [setSelectedUsers]);

  const isSelectedAll = useMemo(() => users?.length && selectedUsers?.length === users?.length, [selectedUsers, users]);
  const handleSelectAll = useCallback(() => {
    if (isSelectedAll) setSelectedUsers([]);
    else setSelectedUsers(users?.map((u) => u._id));
  }, [isSelectedAll, setSelectedUsers, users]);

  return (
    <Scrollbar>
      <Table sx={{ minWidth: 700 }}>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox checked={isSelectedAll} onClick={handleSelectAll} />
            </TableCell>
            <TableCell>{t("views.redesignUserListTable.name")}</TableCell>
            <TableCell>{t("views.redesignUserListTable.registerMethod")}</TableCell>
            <TableCell>{t("views.redesignUserListTable.screens")}</TableCell>
            <TableCell>{t("views.redesignUserListTable.creationDate")}</TableCell>
            <TableCell>{t("views.redesignUserListTable.country")}</TableCell>
            <TableCell>{t("views.redesignUserListTable.groups")}</TableCell>
            <TableCell>{t("views.redesignUserListTable.role")}</TableCell>
            <TableCell>{t("views.redesignUserListTable.status")}</TableCell>
            <TableCell align="right">{t("views.redesignUserListTable.actions")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody ref={tableBodyRef}>
          {users?.map((user) => (
            <RedesignUserTableRow key={`userRow_${user._id}`} user={user} isSelected={selectedUsers.indexOf(user._id) > -1} />
          ))}
        </TableBody>
      </Table>
    </Scrollbar>
  );
};

export default RedesignUserListTable;
