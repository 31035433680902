import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "src/redux/actions/User";

function Logout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  dispatch(logout());
  navigate("/");

  return null;
}

export default Logout;
