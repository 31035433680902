import { useCallback } from "react";
import { Menu, MenuItem } from "@mui/material";

const FolderMenu = ({ _id, anchorEl, open, onClose, children }) => {

    const handleClose = useCallback(() => {
        onClose?.();
    }, [onClose]);

    return (
        <Menu id={`folderMenu_${_id}`} anchorEl={anchorEl} open={open} onClose={handleClose} onClick={handleClose}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
            {children}
        </Menu>
    );

};

export default FolderMenu;