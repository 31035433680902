import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import ArrowRightIcon from "@untitled-ui/icons-react/build/esm/ArrowRight";
import PlusIcon from "@untitled-ui/icons-react/build/esm/Plus";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";

import { useSettings } from "src/hooks/use-settings";
import Layout from "src/components/layout/Layout";

import { AnalyticsStats } from "src/sections/dashboard/analytics/analytics-stats";
import { AnalyticsMostVisited } from "src/sections/dashboard/analytics/analytics-most-visited";
import { AnalyticsSocialSources } from "src/sections/dashboard/analytics/analytics-social-sources";
import { AnalyticsTrafficSources } from "src/sections/dashboard/analytics/analytics-traffic-sources";
import { AnalyticsVisitsByCountry } from "src/sections/dashboard/analytics/analytics-visits-by-country";

const useStyles = makeStyles((theme) => {
  return {
    // ... (styles remain unchanged)
  };
});

const Report = ({ user }) => {
  const { t } = useTranslation();
  const settings = useSettings();
  const classes = useStyles();

  return (
    <>
      <Layout>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8,
          }}>
          <Container maxWidth={settings.stretch ? false : "xl"}>
            <Grid
              container
              spacing={{
                xs: 3,
                lg: 4,
              }}>
              <Grid xs={12}>
                <Stack direction="row" justifyContent="space-between" spacing={4}>
                  <Stack spacing={1}>
                    <Typography variant="h4">{t("views.report.title")}</Typography>
                  </Stack>
                  <Stack alignItems="center" direction="row" spacing={2}>
                    {/* Button removed as per original code */}
                  </Stack>
                </Stack>
              </Grid>
              <Grid xs={12} md={4}>
                <AnalyticsStats
                  action={
                    <Button
                      color="inherit"
                      endIcon={
                        <SvgIcon>
                          <ArrowRightIcon />
                        </SvgIcon>
                      }
                      size="small">
                      {t("views.report.seeSources")}
                    </Button>
                  }
                  chartSeries={[
                    {
                      data: [0, 170, 242, 98, 63, 56, 85, 171, 209, 163, 204, 21, 264, 0],
                    },
                  ]}
                  title={t("views.report.impressions")}
                  value="36,6K"
                />
              </Grid>
              <Grid xs={12} md={4}>
                <AnalyticsStats
                  action={
                    <Button
                      color="inherit"
                      endIcon={
                        <SvgIcon>
                          <ArrowRightIcon />
                        </SvgIcon>
                      }
                      size="small">
                      {t("views.report.seeTraffic")}
                    </Button>
                  }
                  chartSeries={[
                    {
                      data: [0, 245, 290, 187, 172, 106, 15, 210, 202, 19, 18, 3, 212, 0],
                    },
                  ]}
                  title={t("views.report.engagements")}
                  value="19K"
                />
              </Grid>
              <Grid xs={12} md={4}>
                <AnalyticsStats
                  action={
                    <Button
                      color="inherit"
                      endIcon={
                        <SvgIcon>
                          <ArrowRightIcon />
                        </SvgIcon>
                      }
                      size="small">
                      {t("views.report.seeCampaigns")}
                    </Button>
                  }
                  chartSeries={[
                    {
                      data: [0, 277, 191, 93, 92, 85, 166, 240, 63, 4, 296, 144, 166, 0],
                    },
                  ]}
                  title={t("views.report.spent")}
                  value="$41.2K"
                />
              </Grid>
              <Grid xs={12} lg={8}>
                <AnalyticsTrafficSources
                  chartSeries={[
                    {
                      name: t("views.report.organic"),
                      data: [45, 40, 37, 41, 42, 45, 42],
                    },
                    {
                      name: t("views.report.marketing"),
                      data: [19, 26, 22, 19, 22, 24, 28],
                    },
                  ]}
                />
              </Grid>
              <Grid xs={12} lg={4}>
                <AnalyticsVisitsByCountry
                  visits={[
                    {
                      id: "us",
                      name: t("views.report.countries.unitedStates"),
                      seoPercentage: 40,
                      value: 31200,
                    },
                    {
                      id: "uk",
                      name: t("views.report.countries.unitedKingdom"),
                      seoPercentage: 47,
                      value: 12700,
                    },
                    {
                      id: "ru",
                      name: t("views.report.countries.russia"),
                      seoPercentage: 65,
                      value: 10360,
                    },
                    {
                      id: "ca",
                      name: t("views.report.countries.canada"),
                      seoPercentage: 23,
                      value: 5749,
                    },
                    {
                      id: "de",
                      name: t("views.report.countries.germany"),
                      seoPercentage: 45,
                      value: 2932,
                    },
                    {
                      id: "es",
                      name: t("views.report.countries.spain"),
                      seoPercentage: 56,
                      value: 200,
                    },
                  ]}
                />
              </Grid>
              <Grid xs={12} lg={8}>
                <AnalyticsMostVisited
                  pages={[
                    {
                      bounceRate: 16,
                      uniqueVisits: 8584,
                      url: "/",
                      visitors: 95847,
                    },
                    {
                      bounceRate: 5,
                      uniqueVisits: 648,
                      url: "/auth/login",
                      visitors: 7500,
                    },
                    {
                      bounceRate: 2,
                      uniqueVisits: 568,
                      url: "/dashboard",
                      visitors: 85406,
                    },
                    {
                      bounceRate: 12,
                      uniqueVisits: 12322,
                      url: "/blog/top-5-react-frameworks",
                      visitors: 75050,
                    },
                    {
                      bounceRate: 10,
                      uniqueVisits: 11645,
                      url: "/blog/understand-programming-principles",
                      visitors: 68003,
                    },
                    {
                      bounceRate: 8,
                      uniqueVisits: 10259,
                      url: "/blog/design-patterns",
                      visitors: 49510,
                    },
                  ]}
                />
              </Grid>
              <Grid xs={12} lg={4}>
                <AnalyticsSocialSources
                  chartSeries={[10, 10, 20]}
                  labels={[t("views.report.socialMedia.linkedin"), t("views.report.socialMedia.facebook"), t("views.report.socialMedia.instagram")]}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Layout>
    </>
  );
};

export default Report;
