import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { resolveSrc } from "src/api/endpoints";
import ContentCard from "src/components/contentcard/ContentCard";
import ListViewFile from "./ListViewFile";

import { Divider, Box, TablePagination } from "@mui/material";

function Images({
  content,
  onAddPlaylist,
  onPushScreen,
  onDelete,
  onMove,
  onPreview,
  view,
  onCheckedSingleContent,
  onUncheckedSingleContent,
  onUncheckedContent,
  handleSelectAll,
  onClose,
  idx,
  changeZoneDetails
}) {
  const { t } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const contentImages = content.filter((content) => content.type === "image");
  const contentToView = contentImages.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  useEffect(() => {
    if (handleSelectAll) {
      contentToView.forEach((c) => onCheckedSingleContent(c.id));
    }
  }, [handleSelectAll]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onUncheckedContent();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    onUncheckedContent();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "16px",
          flexWrap: "wrap",
          margin: "10px 0",
        }}>
        {view
          ? contentToView.map((data, index) => {
            if (data?.isActive) {
              return (
                <ContentCard
                  key={++index}
                  url={resolveSrc(data.url)}
                  type={data.type}
                  name={data.name}
                  checked={data?.checked}
                  onCheckedSingleContent={onCheckedSingleContent}
                  onUncheckedSingleContent={onUncheckedSingleContent}
                  contentObject={data}
                  onAddPlaylist={onAddPlaylist}
                  onPushScreen={onPushScreen}
                  onDelete={onDelete}
                  onMove={onMove}
                  onPreview={onPreview}
                  createdAt={data.createdAt}
                />
              );
            }
          })
          : contentToView.map((data, index) => {
            if (data?.isActive) {
              return (
                <ListViewFile
                  key={++index}
                  url={resolveSrc(data.url)}
                  type={data.type}
                  name={data.name}
                  checked={data?.checked}
                  onCheckedSingleContent={onCheckedSingleContent}
                  onUncheckedSingleContent={onUncheckedSingleContent}
                  contentObject={data}
                  onAddPlaylist={onAddPlaylist}
                  onPushScreen={onPushScreen}
                  onDelete={onDelete}
                  onMove={onMove}
                  onPreview={onPreview}
                  createdAt={data.createdAt}
                  onClose={onClose}
                  idx={idx}
                  changeZoneDetails={changeZoneDetails}
                />
              );
            }
          })}
      </Box>
      <Divider />

      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={content.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t("views.images.rowsPerPage")}
      />
    </>
  );
}

export default Images;
