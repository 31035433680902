import React from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";

import { Scrollbar } from "src/components/scrollbar";
import { SettingsBackupRestore } from "@mui/icons-material";

const RestoreFileTable = (props) => {
  const { t } = useTranslation();
  const { items = [] } = props;

  return (
    <Box sx={{ position: "relative" }}>
      <Scrollbar>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox />
              </TableCell>
              <TableCell>{t("views.restoreFileTable.image")}</TableCell>
              <TableCell>{t("views.restoreFileTable.fileName")}</TableCell>
              <TableCell>{t("views.restoreFileTable.fileType")}</TableCell>
              <TableCell align="right">{t("views.restoreFileTable.action")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(
              (file, index) =>
                file.isActive === false && (
                  <TableRow hover key={index}>
                    <TableCell padding="checkbox">
                      <Checkbox />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>{file.name}</TableCell>
                    <TableCell>{file.type}</TableCell>
                    <TableCell align="right">
                      <IconButton>
                        <SvgIcon>
                          <SettingsBackupRestore color="primary" />
                        </SvgIcon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
            )}
          </TableBody>
        </Table>
      </Scrollbar>
    </Box>
  );
};

export default RestoreFileTable;
