import React from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  Select,
  MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#0E1320",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  minWidth: "300px",
};

const ModalsSection = ({
  changeModalOpen,
  handleCancelChange,
  handleConfirmSubscriptionChange,
  changeActionType,
  cancelModalOpen,
  handleCancelModalClose,
  handleConfirmCancel,
  purchaseModalOpen,
  handlePurchaseModalClose,
  handleConfirmPurchase,
  selectedInterval,
  setSelectedInterval,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {/* Subscription Change Modal */}
      <Modal open={changeModalOpen} onClose={handleCancelChange}>
        <Box sx={styleModal}>
          <Typography variant="h6">
            {changeActionType === "upgrade"
              ? t("views.modalSection.updateYourSub")
              : t("views.modalSection.downgradeYourSub")}
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <Button
              variant="contained"
              onClick={handleConfirmSubscriptionChange}
            >
              {changeActionType === "upgrade"
                ? t("views.modalSection.improve")
                : t("views.modalSection.lower")}
            </Button>
            <Button variant="outlined" onClick={handleCancelChange}>
              {t("views.modalSection.cancel")}
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Subscription Cancellation Modal */}
      <Modal open={cancelModalOpen} onClose={handleCancelModalClose}>
        <Box sx={styleModal}>
          <Typography variant="h6">
            {t("views.modalSection.cancelSub")}
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <Button
              variant="contained"
              color="error"
              onClick={handleConfirmCancel}
            >
              {t("views.modalSection.cancelSubcription")}
            </Button>
            <Button variant="outlined" onClick={handleCancelModalClose}>
              {t("views.modalSection.close")}
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Subscription Purchase Modal with Free Plan */}
      <Modal open={purchaseModalOpen} onClose={handlePurchaseModalClose}>
        <Box sx={styleModal}>
          <Typography variant="h6">
            {t("views.modalSection.selectSub")}
          </Typography>
          <Select
            value={selectedInterval}
            onChange={(e) => setSelectedInterval(e.target.value)}
            sx={{
              backgroundColor: "#0E1320",
              color: "#fff",
              borderRadius: "4px",
              width: "100%",
              marginBottom: "10px",
              "& .MuiSelect-icon": {
                color: "#fff",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#555",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#aaa",
              },
            }}
          >
            <MenuItem value="month">{t("views.modalSection.month")}</MenuItem>
            <MenuItem value="year">{t("views.modalSection.year")}</MenuItem>
          </Select>
          <Box display="flex" justifyContent="space-between">
            <Button variant="contained" onClick={handleConfirmPurchase}>
              {t("views.modalSection.buy")}
            </Button>
            <Button variant="outlined" onClick={handlePurchaseModalClose}>
              {t("views.modalSection.cancel")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ModalsSection;
