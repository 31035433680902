import React, { useEffect, useState } from "react";

import ToggleButtonGroup, { toggleButtonGroupClasses } from "@mui/material/ToggleButtonGroup";

import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Typography,
  Card,
  FormControl,
  InputLabel,
  Select,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { changeFolder, createFolder } from "../../../../redux/actions/FileFolder";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { contentAscByCreatedAt, contentAscByName, contentDescByCreatedAt, contentDescByName } from "../../../../redux/actions/Content";

// icons
import SvgIcon from "@mui/material/SvgIcon";
import ListIcon from "@untitled-ui/icons-react/build/esm/List";
import Grid01Icon from "@untitled-ui/icons-react/build/esm/Grid01";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import ToggleButton from "@mui/material/ToggleButton";
import SearchMdIcon from "@untitled-ui/icons-react/build/esm/SearchMd";

import useUserFeature from "../../../../hooks/use-user-feature";
import PlanFeatureButton from "src/components/planFeature/PlanFeatureButton";
import { useTranslation } from "react-i18next";

function FileFolderHeader({ user, view, onViewChange, isChecked, onCheckedContent, onUncheckedContent, onGetCheckedContent, onSearchBarChange }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentFolder, currentFolderData, userFolders, content } = useSelector(
    (state) => ({
      currentFolder: state.FileFolder.currentFolder,
      currentFolderData: state.FileFolder.userFolders.find((u) => u._id === state.FileFolder.currentFolder),
      userFolders: state.FileFolder.userFolders,
      content: state.Content.content,
    }),
    shallowEqual
  );
  const [folderName, setFolderName] = useState("");
  const [openCreateFolder, setOpenCreateFolder] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectBy, setSelectBy] = useState("desc");

  const open = Boolean(anchorEl);

  useEffect(() => {
    const openBtn = document.querySelector("#openBtn");
    const closeBtn = document.querySelector("#closeBtn");
    const pagg = document.querySelector("#pagination-box");
    const btnGrp = document.querySelector("#btn-group");
    openBtn?.addEventListener("click", function (e) {
      btnGrp.classList.remove("hidden-rm");
      pagg.classList.remove("show-rm");
      pagg?.classList.add("hidden-rm");
      btnGrp.classList.add("show-rm");
    });
    closeBtn?.addEventListener("click", function (e) {
      pagg.classList.remove("hidden-rm");
      btnGrp.classList.remove("show-rm");
      pagg?.classList.add("show-rm");
      btnGrp.classList.add("hidden-rm");
    });
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (type) => {
    if (type === "desc") {
      dispatch(contentDescByCreatedAt());
      setSelectBy("desc");
    }
    if (type === "asc") {
      dispatch(contentAscByCreatedAt());
      setSelectBy("asc");
    }
    if (type === "name_desc") {
      dispatch(contentDescByName());
      setSelectBy("name_desc");
    }
    if (type === "name_asc") {
      dispatch(contentAscByName());
      setSelectBy("name_asc");
    }
    setAnchorEl(null);
  };

  console.log("test: ", selectBy);

  const makeFolder = () => {
    setOpenCreateFolder(false);
    const isAvailable = userFolders.find((f) => f.name.toLocaleUpperCase() === folderName.toLocaleUpperCase());
    if (isAvailable) {
      toast.success(t("components.redesignBodyHeader.folderAlreadyAvailable"));
    } else {
      if (user?.socialMedia) {
        dispatch(
          createFolder({
            name: folderName,
            parent: currentFolder,
            folderFor: "content",
            role: "socialMedia",
            platForm: "socialMedia",
            platFormId: user?._id,
            path: currentFolder === "root" ? [] : [{ parent: currentFolder, name: "" }],
          })
        );
      } else {
        dispatch(
          createFolder({
            name: folderName,
            parent: currentFolder,
            folderFor: "content",
            role: user?.role,
            user: user?._id,
            subuser: user?._id,
            path: currentFolder === "root" ? [] : [{ parent: currentFolder, name: "" }],
          })
        );
      }
    }
  };
  const handleNavigate = (link, id) => {
    navigate(link);
    dispatch(changeFolder(id));
  };
  const onChangeScreenBox = (e) => {
    const { checked } = e.target;
    if (checked) {
      onCheckedContent();
    } else {
      onUncheckedContent();
    }
  };

  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

  const userCanCreateFolders = useUserFeature("create_folders");
  const userCanCreateFoldersValue = useUserFeature("create_folders", true);

  return (
    <>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "relative",
          marginBottom: "20px",
        }}>

        <div role="presentation" style={{ display: "flex", width: "100%", margin: "0 5px", alignItems: "center" }}>
          {currentFolder !== "root" ? (
            <>
              <Button onClick={() => handleNavigate("/files", "root")}>{t("components.redesignBodyHeader.home")}</Button>
              <Box
                sx={{
                  backgroundColor: "neutral.500",
                  borderRadius: "50%",
                  height: 4,
                  width: 4,
                }}
              />
              {currentFolderData.path.map((c, index) => (
                <>
                  <Button
                    onClick={() => handleNavigate(`/files/${userFolders.find((u) => u._id === c.parent)?._id}`, `${userFolders.find((u) => u._id === c.parent)?._id}`)}>
                    {userFolders.find((u) => u._id === c.parent)?.name}
                  </Button>
                  <Box
                    sx={{
                      backgroundColor: "neutral.500",
                      borderRadius: "50%",
                      height: 4,
                      width: 4,
                    }}
                  />
                </>
              ))}
              <Typography sx={{ padding: "10px 10px" }} variant="button" display="block">
                {currentFolderData?.name}
              </Typography>
            </>
          ) : (
            <Typography variant="button" display="block">
              {t("components.redesignBodyHeader.home")}
            </Typography>
          )}
        </div>

        <PlanFeatureButton feature="create_folders" variant="outlined" onClick={() => setOpenCreateFolder(true)} startIcon={<CreateNewFolderIcon />}>
          <Typography variant="body2" flex="none">
            {t("components.redesignBodyHeader.createFolder")}
          </Typography>
        </PlanFeatureButton>
      </Box>

      <Card sx={{ p: 2 }}>
        <Box
          sx={{
            height: "80%",
            width: "100%",
            display: "flex",
            flexDirection: {
              lg: "row",
              xs: "column",
            },
            gap: {
              lg: "0px",
              xs: "20px",
            },
          }}>
          {/* left side */}
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "5px",
            }}>
            <OutlinedInput
              defaultValue=""
              fullWidth
              onChange={onSearchBarChange}
              name="itemName"
              placeholder={t("components.redesignBodyHeader.search")}
              startAdornment={
                <InputAdornment position="start">
                  <SvgIcon>
                    <SearchMdIcon />
                  </SvgIcon>
                </InputAdornment>
              }
            />
          </Box>
          {/* right side */}
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: {
                lg: "flex-end",
                xs: "flex-start",
              },
              alignItems: "center",
              gap: "5px",
            }}>
            <ToggleButtonGroup
              exclusive
              onChange={onViewChange}
              value={view ? "grid" : "list"}
              sx={{
                borderWidth: 1,
                borderColor: "divider",
                borderStyle: "solid",
                [`& .${toggleButtonGroupClasses.grouped}`]: {
                  margin: 0.5,
                  border: 0,
                  "&:not(:first-of-type)": {
                    borderRadius: 1,
                  },
                  "&:first-of-type": {
                    borderRadius: 1,
                  },
                },
              }}>
              <ToggleButton value="grid">
                <SvgIcon fontSize="small">
                  <Grid01Icon />
                </SvgIcon>
              </ToggleButton>
              <ToggleButton value="list">
                <SvgIcon fontSize="small">
                  <ListIcon />
                </SvgIcon>
              </ToggleButton>
            </ToggleButtonGroup>

            <FormControl sx={{ maxWidth: "200px" }} fullWidth>
              <InputLabel id="demo-simple-select-label">{t("components.redesignBodyHeader.sortBy")}</InputLabel>
              <Select labelId="demo-simple-select-label" id="demo-simple-select" value={selectBy} label={t("components.redesignBodyHeader.sortBy")} onClose={handleClose}>
                <MenuItem onClick={() => handleClose("desc")} value={"desc"}>
                  ↓ {t("components.redesignBodyHeader.createDate")}
                </MenuItem>
                <MenuItem onClick={() => handleClose("asc")} value={"asc"}>
                  ↑ {t("components.redesignBodyHeader.createDate")}
                </MenuItem>
                <MenuItem onClick={() => handleClose("name_desc")} value={"name_desc"}>
                  ↓ {t("components.redesignBodyHeader.name")}
                </MenuItem>
                <MenuItem onClick={() => handleClose("name_asc")} value={"name_asc"}>
                  ↑ {t("components.redesignBodyHeader.name")}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Card>

      <Dialog open={openCreateFolder} onClose={() => setOpenCreateFolder(false)}>
        <DialogTitle>{t("components.redesignBodyHeader.createFolder")}</DialogTitle>
        <DialogContent
          sx={{
            width: isMobile ? "300px" : "500px",
            height: "200px",
          }}>
          <Box style={{ width: "120px", height: "120px", margin: "20px auto" }}>
            <CreateNewFolderIcon color="primary" sx={{ fontSize: 120 }} />
          </Box>
          <TextField
            autoFocus
            required
            margin="dense"
            id="folderName"
            name="folderName"
            type="text"
            fullWidth
            variant="standard"
            label={t("components.redesignBodyHeader.typeFolderName")}
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCreateFolder(false)}>{t("components.redesignBodyHeader.close")}</Button>
          <Button onClick={makeFolder}>{t("components.redesignBodyHeader.save")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default FileFolderHeader;
