import React from "react";
import TvIcon from "@mui/icons-material/Tv";
import { grey } from "@mui/material/colors";

const Card = ({ item }) => {
  return (
    <div>
      <div
        style={{
          width: "329px",
          minHeight: "288px",
          border: "1px solid black",
          borderRadius: "10px",
          textAlign: "center",
        }}>
        <div style={{ width: "329px", height: "169px" }}>
          <TvIcon
            sx={{
              color: grey[400],
              fontSize: 100,

              paddingTop: 3,
            }}
          />
        </div>
        <div>
          <h3 style={{ marginLeft: "6px" }}>{item?.title}</h3>
          <p style={{ marginLeft: "6px" }}>{`${item?.inputValue?.substr(0, 20)}...`}</p>
        </div>
      </div>
    </div>
  );
};

export default Card;
