import { api } from "../../api/endpoints";
import {
    GET_NOTES_SUCCESS,
    SAVE_NOTE,
    DELETE_NOTE,
} from "../constant";


export const getUserNotes = (id) => {
    return async (dispatch) => {
        const response = await api.get(`/user/${id}/note`);
        if (response.status === 200) {
            dispatch({
                type: GET_NOTES_SUCCESS,
                payload: response.data,
            });
        }
    };
};
export const addUserNote = (id, obj) => {
    return async (dispatch) => {
        const response = await api.post(`/user/${id}/note`, obj);
        if (response.status === 200) {
            dispatch({
                type: SAVE_NOTE,
                payload: response.data,
            });
        }
    };
};

export const deleteUserNote = (userId, noteId) => {
    return async (dispatch) => {
        const response = await api.delete(`/user/${userId}/note/${noteId}`);
        if (response.status === 200) {
            dispatch({
                type: DELETE_NOTE,
                payload: response.data,
            });
        }
    };
};

