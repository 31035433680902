import { Box, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import VideocamIcon from "@mui/icons-material/Videocam";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { useDispatch } from "react-redux";
import { addContent } from "../../../redux/actions/Content";

function Camera({ cameraOpen, onClose }) {
  const { t } = useTranslation();
  const [img, setImg] = useState([]);
  const [vid, setVid] = useState([]);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recordedVideoURL, setRecordedVideoURL] = useState("");
  const dispatch = useDispatch();
  const camRef = useRef(null);

  useEffect(() => {
    cameraOpen && cameraInit();
  }, [cameraOpen]);

  const cameraInit = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: true,
        audio: true,
      })
      .then((stream) => {
        videoRef.current.srcObject = stream;
        camRef.current = stream;
        videoRef.current.play();
      });
  };

  const closeRunningCam = () => {
    if (navigator.mediaDevices.getUserMedia) {
      camRef.current.getTracks().forEach((track) => track.stop());
    }
  };

  const onUpload = () => {
    const dataForm = new FormData();
    for (const v in vid) {
      dataForm.append("content", vid[v].upload);
    }
    dispatch(addContent(dataForm));
    closeRunningCam();
    onClose();
  };

  const handleCapture_2 = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    const imageData = canvas.toDataURL("image/png");
    const base64String = imageData.split(",")[1];
    const mimeType = imageData.split(";")[0].slice(5);
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: mimeType });
    const file = new File([blob], "capturedImage.png", { type: "image/png" });
    setImg((prev) => [...prev, { img: imageData }]);
    setVid((prev) => [...prev, { vrl: URL.createObjectURL(blob), upload: file, type: "image" }]);
  };

  const handleStartRecording = () => {
    const mediaStream = videoRef.current.srcObject;
    mediaRecorderRef.current = new MediaRecorder(mediaStream);

    const chunks = [];
    mediaRecorderRef.current.addEventListener("dataavailable", (event) => {
      if (event.data.size > 0) {
        chunks.push(event.data);
      }
    });

    mediaRecorderRef.current.addEventListener("stop", () => {
      const blob = new Blob(chunks, { type: "video/webm" });
      const file = new File([blob], "capturevideo.mp4", { type: "video/webm" });
      const url = URL.createObjectURL(blob);
      setRecordedVideoURL(url);
      setVid((prev) => [...prev, { vrl: URL.createObjectURL(blob), upload: file, type: "video" }]);
      chunks.length = 0;
    });

    mediaRecorderRef.current.start();
    setIsRecording(true);
  };

  const handleStopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== "inactive") {
      mediaRecorderRef.current.stop();
    }

    setIsRecording(false);
  };

  return (
    <>
      <Dialog open={cameraOpen} onClose={onClose} maxWidth={"xl"}>
        <DialogTitle
          sx={{
            height: "5vh",
            background: "linear-gradient(30.84deg, #016AFE 61.34%, rgba(1, 163, 254, 0.53) 100%)",
            transform: "matrix(1, 0, 0, -1, 0, 0)",
          }}></DialogTitle>
        <DialogContent>
          <Box
            sx={{
              minHeight: "50vh",
              width: "50vw",
              display: "flex",
              flexDirection: "column",
            }}>
            {recordedVideoURL === "" ? <video ref={videoRef} autoPlay muted /> : <video ref={videoRef} autoPlay muted hidden />}
            {recordedVideoURL && <video src={recordedVideoURL} style={{ width: "100%" }} controls />}

            <Box sx={{ border: "1px solid black", height: "10vh", width: "50vw", maxWidth: "100%", display: "flex", gap: "10px", overflow: "auto" }}>
              <canvas ref={canvasRef} hidden />
              {vid.map(({ vrl, type }) => {
                if (type === "video") return <video src={vrl} controls autoPlay styl={{ height: "30px", width: "30px" }} />;
                else if (type === "image") {
                  return <img src={vrl} styl={{ height: "30px", width: "30px" }} />;
                }
              })}
            </Box>
            <Box
              sx={{
                height: "10vh",
                width: "50vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}>
              <Button
                ariant={"contained"}
                sx={{
                  height: "70px",
                  width: "70px",
                  borderRadius: "50%",
                }}
                onClick={closeRunningCam}>
                {t("views.camera.off")}
              </Button>
              <Button
                ariant={"contained"}
                sx={{
                  height: "70px",
                  width: "70px",
                  borderRadius: "50%",
                }}
                onClick={onUpload}>
                {t("views.camera.upload")}
              </Button>
              <Button
                variant={"contained"}
                sx={{
                  height: "70px",
                  width: "70px",
                  borderRadius: "50%",
                }}
                onClick={handleCapture_2}>
                <CameraAltIcon />
              </Button>
              {!isRecording ? (
                <Button
                  variant={"contained"}
                  sx={{
                    height: "70px",
                    width: "70px",
                    borderRadius: "50%",
                  }}
                  onClick={handleStartRecording}>
                  <VideocamIcon />
                </Button>
              ) : (
                <Button
                  variant={"contained"}
                  color={"error"}
                  sx={{
                    height: "70px",
                    width: "70px",
                    borderRadius: "50%",
                  }}
                  onClick={handleStopRecording}>
                  <StopCircleIcon />
                </Button>
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Camera;
