import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useSettings } from "src/hooks/use-settings";
import { usePageView } from "src/hooks/use-page-view";
import { useTranslation } from "react-i18next";
import { Box, Card, Container, Stack } from "@mui/material";

import Layout from "src/components/layout/Layout";

import RedesignUserListSearch from "./components/RedesignUserListSearch";
import RedesignUserListTable from "./components/RedesignUserListTable";

import RedesignUserListHeader from "./components/RedesignUserListHeader";
import GroupFoldersView from "./components/GroupFoldersView";

import GroupEditDialog from "./components/GroupEditDialog";
import GroupDeleteDialog from "./components/GroupDeleteDialog";
import GroupAssignUsersDialog from "./components/GroupAssignUsersDialog";
import GroupUnassignUsersDialog from "./components/GroupUnassignUsersDialog";
import UsersChangeGroupDialog from "./components/UsersChangeGroupDialog";

const useUsersList = () => {
  const [state, setState] = useState({
    filters: {
      query: undefined,
      isAdmin: undefined,
      isActive: undefined,
      isBlocked: undefined,
    },
    page: 0,
    rowsPerPage: 5,
    sortBy: "createdAt",
    sortDir: "desc",
  });

  const handleFiltersChange = useCallback((filters) => {
    setState((prevState) => ({
      ...prevState,
      filters,
    }));
  }, []);

  const handleSortChange = useCallback((sort) => {
    setState((prevState) => ({
      ...prevState,
      sortBy: sort.sortBy,
      sortDir: sort.sortDir,
    }));
  }, []);

  const handlePageChange = useCallback((event, page) => {
    setState((prevState) => ({
      ...prevState,
      page,
    }));
  }, []);

  const handleRowsPerPageChange = useCallback((event) => {
    setState((prevState) => ({
      ...prevState,
      rowsPerPage: parseInt(event.target.value, 10),
    }));
  }, []);

  return {
    handleFiltersChange,
    handleSortChange,
    handlePageChange,
    handleRowsPerPageChange,
    state,
  };
};

const Page = () => {
  const { t } = useTranslation();
  const settings = useSettings();

  usePageView();

  // Groups
  const { currentGroupId } = useParams();
  const usersGroups = useSelector((state) => state.Group.userGroups);
  const currentGroup = useMemo(() => (!currentGroupId ? null : usersGroups.find((g) => g._id === currentGroupId) ?? null), [currentGroupId, usersGroups]);

  const groups = useMemo(() => usersGroups.filter((g) => (currentGroup ? g.parent && g.parent == currentGroup._id : !g.parent)), [usersGroups, currentGroup]);

  // Users
  const userListState = useUsersList();
  const allUsers = useSelector((state) => state.User.users);

  const usersList = useMemo(() => {
    const { filters, sortBy, sortDir } = userListState.state;

    let list = [...allUsers];

    //Filter by group
    if (currentGroup) list = list.filter((u) => !!u.groups.find((g) => g._id === currentGroup._id));

    //Apply filters
    if (filters.isActive) list = list.filter((u) => u.isActive === true);
    else if (filters.isBlocked) list = list.filter((u) => u.isActive === false);
    else if (filters.isAdmin) list = list.filter((u) => u.role === "admin");

    if (filters.query && filters.query.trim()) list = list.filter((u) => u.fullname.toLowerCase().includes(filters.query.trim().toLowerCase()));

    //Apply sort
    list.sort((a, b) => {
      let left, right;

      if (sortBy === "createdAt") {
        left = new Date(a.createdAt);
        right = new Date(b.createdAt);
      } else if (sortBy === "status") {
        left = a.isActive - b.isActive;
        right = a.isDeleted - b.isDeleted;
      } else if (sortBy === "name") {
        left = a.fullname.toLowerCase();
        right = b.fullname.toLowerCase();
      } else if (sortBy === "email") {
        left = a.email.toLowerCase();
        right = b.email.toLowerCase();
      }

      if (sortDir === "asc") {
        left = [right, (right = left)][0];
      }

      //Sort strings
      if (typeof left === "string") {
        if (left < right) {
          return -1;
        }
        if (left > right) {
          return 1;
        }
        return 0;
      }

      return left - right;
    });

    return list;
  }, [allUsers, currentGroup, userListState.state]);

  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    setSelectedUsers([]);
  }, [usersList, currentGroup, allUsers]);

  // Dialogs
  const [activeDialog, setActiveDialog] = useState(null);
  const handleDialogClose = useCallback(() => setActiveDialog(null), []);

  const [activeDialogGroup, setActiveDialogGroup] = useState(null);

  // Group actions handlers
  const handleGroupEdit = useCallback(
    (id) => {
      setActiveDialogGroup(groups.find((g) => g._id === id));
      setActiveDialog("GROUP_EDIT");
    },
    [groups]
  );

  const handleGroupDelete = useCallback(
    (id) => {
      setActiveDialogGroup(groups.find((g) => g._id === id));
      setActiveDialog("GROUP_DELETE");
    },
    [groups]
  );

  const handleAddGroup = useCallback(() => {
    setActiveDialogGroup(null);
    setActiveDialog("GROUP_EDIT");
  }, []);

  const handleUsersToCurrentGroup = useCallback(() => {
    setActiveDialogGroup(null);
    setActiveDialog("GROUP_ASSIGN_USERS");
  }, []);

  const handleAddUsersToGroup = useCallback(() => {
    setActiveDialogGroup(null);
    setActiveDialog("USERS_ASSING_GROUP");
  }, [currentGroup]);

  const handleDeleteSelectedUsersFromGroup = useCallback(() => {
    setActiveDialogGroup(currentGroup);
    setActiveDialog("USERS_DELETE_GROUP");
  }, [currentGroup]);

  return (
    <>
      <Layout>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8,
          }}>
          <Container maxWidth={settings.stretch ? false : "xl"}>
            <Stack spacing={4}>
              <RedesignUserListHeader groups={usersGroups} currentGroup={currentGroup} onAddGroup={handleAddGroup} onAddUsers={handleUsersToCurrentGroup} />
              <GroupFoldersView groups={groups} onGroupEdit={handleGroupEdit} onGroupDelete={handleGroupDelete} />
              <Card>
                <RedesignUserListSearch
                  selectedUsers={selectedUsers}
                  userListState={userListState}
                  currentGroup={currentGroup}
                  onDeleteFromGroup={handleDeleteSelectedUsersFromGroup}
                  onAddToGroup={handleAddUsersToGroup}
                />
                <Box sx={{ position: "relative" }}>
                  <RedesignUserListTable users={usersList} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} />
                </Box>
              </Card>
            </Stack>
          </Container>
        </Box>
      </Layout>

      <GroupEditDialog onClose={handleDialogClose} open={activeDialog == "GROUP_EDIT"} group={activeDialogGroup} parentGroup={currentGroup} />
      <GroupDeleteDialog onClose={handleDialogClose} open={activeDialog == "GROUP_DELETE" && activeDialogGroup} group={activeDialogGroup} />
      <GroupAssignUsersDialog onClose={handleDialogClose} open={activeDialog == "GROUP_ASSIGN_USERS" && currentGroup} group={currentGroup} users={allUsers} />
      <GroupUnassignUsersDialog
        onClose={handleDialogClose}
        open={activeDialog == "USERS_DELETE_GROUP" && currentGroup}
        group={currentGroup}
        selectedUsers={selectedUsers}
      />
      <UsersChangeGroupDialog onClose={handleDialogClose} open={activeDialog == "USERS_ASSING_GROUP"} groups={usersGroups} selectedUsers={selectedUsers} action={"ADD"} />
    </>
  );
};

export default Page;
