import React, { useState } from 'react';
import { RotationTypes } from "../EditScreen/types";

const OrientationDialog = ({ androidSettings, setAndroidSettings, open, onClose, title }) => {

  if (!open) return null;

  const handleOptionClick = (option) => {
    setAndroidSettings({
        ...androidSettings,
        orientation: RotationTypes[option],
    })
  };

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: "25%",
        width: '50%',
        height: '100%',
        background: 'transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2000000000000,
      }}
    >
      <div
        style={{
          background: '#1a2432',
          borderRadius: '8px',
          width: '90%',
          maxWidth: '600px',
          padding: '20px',
          position: 'relative',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h2 style={{margin:"auto"}}>{title}</h2>
        </div>
        <div
          style={{
            marginTop: '20px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {Object.keys(RotationTypes).map((option) => (
              <button
                key={option}
                onClick={() => handleOptionClick(option)}
                style={{
                  background: androidSettings.orientation === RotationTypes[option] ? '#286af0' : '#f5f5f5',
                  color:  androidSettings.orientation === RotationTypes[option] ? 'white' : 'black',
                  border: 'none',
                  borderRadius: '4px',
                  padding: '10px',
                  margin: '5px 0',
                  cursor: 'pointer',
                  fontSize: '16px',
                }}
              >
                {RotationTypes[option]}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrientationDialog;
