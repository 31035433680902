import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Done from "../../../icons/untitled-ui/duocolor/done";
import None from "../../../icons/untitled-ui/duocolor/none";
import Button from "@mui/material/Button";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    overflowX: "auto",
    maxWidth: "100%",
  },
  staticColumn: {
    position: "sticky",
    left: 0,
    zIndex: 1,
    border: 0,
    width: 250,
    flexShrink: 0,
    fontWeight: "bold",
    fontSize: "18px",
  },
}));

const UserSubscriptionListTable = ({
  plans,
  currentPlanId,
  planRank,
  freePlanId,
  handleCancelSubscription,
  handleChangeSubscription,
  handleChoosePlan,
  userSubscription,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  if (!plans || plans.length === 0) {
    return <div>{t("views.userSubscriptions.noPlans")}</div>;
  }
  const planFeatures = plans[0].planFeatures || [];
  const currentRank = currentPlanId ? planRank[currentPlanId] : -1;

  return (
    <div className={classes.tableContainer}>
      <Table sx={{ minWidth: 700 }}>
        <TableHead>
          <TableRow>
            <TableCell
              className={classes.staticColumn}
              sx={{ backgroundColor: theme.palette.background.default }}
            >
              {t("views.userSubscriptions.featureName")}
            </TableCell>
            {plans.map((plan) => (
              <TableCell key={plan._id} align="center">
                <strong>{plan.name}</strong>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={classes.staticColumn}>
              {t("views.userSubscriptions.price")}
            </TableCell>
            {plans.map((plan) => (
              <TableCell key={plan._id} align="center">
                {plan.price} USD
              </TableCell>
            ))}
          </TableRow>

          {planFeatures.map((feature, featureIndex) => (
            <TableRow hover key={feature._id}>
              <TableCell className={classes.staticColumn}>
                {feature.name}
              </TableCell>
              {plans.map((plan) => {
                const planFeature = plan.planFeatures[featureIndex];
                return (
                  <TableCell key={plan._id} align="center">
                    {planFeature.inputType === "text" ? (
                      planFeature.value
                    ) : planFeature.enabled ? (
                      <Done />
                    ) : (
                      <None />
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}

          <TableRow>
            <TableCell className={classes.staticColumn}>
              {t("views.userSubscriptions.choosePlan")}
            </TableCell>
            {plans.map((plan) => {
              const targetRank = planRank[plan._id];
              if (!userSubscription || !userSubscription.id) {
                if (plan.name === "Free") {
                  return (
                    <TableCell key={plan._id} align="center">
                      <span>{t("views.userSubscriptions.chosen")}</span>
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell key={plan._id} align="center">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleChoosePlan(plan._id)}
                      >
                        {t("views.userSubscriptions.choose")}
                      </Button>
                    </TableCell>
                  );
                }
              } else if (
                userSubscription.planName === "Free" &&
                plan.name === "Free"
              ) {
                return (
                  <TableCell key={plan._id} align="center">
                    <span>{t("views.userSubscriptions.chosen")}</span>
                  </TableCell>
                );
              } else {
                const targetRank = planRank[plan._id];
                const currentRank = planRank[userSubscription.planName];

                if (targetRank === currentRank) {
                  return (
                    <TableCell key={plan._id} align="center">
                      <Button
                        variant="contained"
                        style={{ backgroundColor: "red", color: "#fff" }}
                        onClick={handleCancelSubscription}
                      >
                        {t("views.modalSection.cancelSubcription")}
                      </Button>
                    </TableCell>
                  );
                } else if (targetRank > currentRank) {
                  return (
                    <TableCell key={plan._id} align="center">
                      <Button
                        variant="contained"
                        style={{ backgroundColor: "green", color: "#fff" }}
                        onClick={() =>
                          handleChangeSubscription(plan._id, "upgrade")
                        }
                      >
                        {t("views.modalSection.updateSub")}
                      </Button>
                    </TableCell>
                  );
                } else if (
                  targetRank < currentRank &&
                  targetRank > planRank[freePlanId]
                ) {
                  return (
                    <TableCell key={plan._id} align="center">
                      <Button
                        variant="contained"
                        style={{ backgroundColor: "yellow", color: "#000" }}
                        onClick={() =>
                          handleChangeSubscription(plan._id, "downgrade")
                        }
                      >
                        {t("views.modalSection.downgradeSub")}
                      </Button>
                    </TableCell>
                  );
                }
              }

              if (targetRank === currentRank) {
                return (
                  <TableCell key={plan._id} align="center">
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "red", color: "#fff" }}
                      onClick={handleCancelSubscription}
                    >
                      {t("views.modalSection.cancelSubcription")}
                    </Button>
                  </TableCell>
                );
              } else if (targetRank > currentRank) {
                return (
                  <TableCell key={plan._id} align="center">
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "green", color: "#fff" }}
                      onClick={() =>
                        handleChangeSubscription(plan._id, "upgrade")
                      }
                    >
                      {t("views.modalSection.updateSub")}
                    </Button>
                  </TableCell>
                );
              } else if (
                targetRank < currentRank &&
                targetRank > planRank[freePlanId]
              ) {
                return (
                  <TableCell key={plan._id} align="center">
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "yellow", color: "#000" }}
                      onClick={() =>
                        handleChangeSubscription(plan._id, "downgrade")
                      }
                    >
                      {t("views.modalSection.downgradeSub")}
                    </Button>
                  </TableCell>
                );
              }

              return <TableCell key={plan._id} align="center"></TableCell>;
            })}
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default UserSubscriptionListTable;
