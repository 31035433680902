import {
  ADD_APK,
  ADD_APK_SUCCESS,
  APK_ALREADY_SAVED,
  APK_DELETE,
  APK_FLAG_OFF,
  APK_INDENTITY,
  FETCH_APK_REQUEST,
  FETCH_APK_SUCCESS,
} from "../constant";

const initial = {
  apkLoading: false,
  apkLoaded: false,
  isAvailable: false,
  isAdded: false,
  identity: false,
  isAdding: false,
  apk: [],
};
export const ApkReducer = (state = initial, action) => {
  switch (action.type) {
    case APK_FLAG_OFF:
      return {
        ...state,
        isAvailable: false,
        apkLoaded: false,
        apkLoaded: false,
        isAdded: false,
        identity: false,
        isAdding: false
      };
    case ADD_APK:
      return {
        ...state,
        apkLoading: true,
        isAdding: true
      };
    case ADD_APK_SUCCESS:
      return {
        ...state,
        apkLoading: false,
        apkLoaded: true,
        isAdded: true,
        isAdding: false,
        apk: [...state.apk, action.payload].reverse(),
      };
    case APK_ALREADY_SAVED:
      return {
        ...state,
        isAdding: false,
        isAvailable: true,
      };
    case APK_INDENTITY:
      return {
        ...state,
        isAdding: false,
        identity: true
      }
    case FETCH_APK_REQUEST:
      return {
        ...state,
        apkLoading: true,
      };
    case FETCH_APK_SUCCESS:
      return {
        ...state,
        apkLoading: false,
        apkLoaded: true,
        apk: [...action.payload].reverse(),
      };
    case APK_DELETE:
      return {
        ...state,
        apk: state.apk.filter((a) => a._id !== action.payload),
      };

    default:
      return state;
  }
};
