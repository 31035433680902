import { useCallback, useState, useEffect } from "react";
import ConfirmDialog from "src/components/dialog/ConfirmDialog";
import { api } from "src/api/endpoints";
import { useTranslation } from "react-i18next";

import {
  Card,
  CardContent,
  CardHeader,
  Box,
  Grid,
  Stack,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
} from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { loginFlagOff, passwordChange, changePassword } from "../../redux/actions/User";
import toast from "react-hot-toast";
import Circularbar from "../../components/circularbar/Circularbar";

export const CustomerPasswordEdit = ({ user }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isCurrentPasswordInvalid = useSelector((state) => state.User.isCurrentPasswordInvalid);
  const params = useParams();

  const [isConfirmOpen, setConfirmOpen] = useState(false);

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);

  const [showConfirmPasswordError, setShowConfirmPasswordError] = useState(false);
  const [showConfirmPasswordErrorText, setShowConfirmPasswordErrorText] = useState("");

  const [showPasswordError, setShowPasswordError] = useState(false);
  const [showPasswordErrorText, setShowPasswordErrorText] = useState("");

  const [showCurrentPasswordError, setShowCurrentPasswordError] = useState(false);
  const [showCurrentPasswordErrorText, setShowCurrentPasswordErrorText] = useState("");

  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    if (isCurrentPasswordInvalid) {
      setShowCurrentPasswordError(true);
      setShowCurrentPasswordErrorText(t("views.customerPasswordEdit.currentPasswordInvalid"));
    } else {
      setShowCurrentPasswordError(false);
      setShowCurrentPasswordErrorText("");
    }
  }, [isCurrentPasswordInvalid, t]);

  const passwordSaveChanges = useCallback(() => {
    let error = false;
    if (password !== confirmPassword) {
      setShowConfirmPasswordErrorText(t("views.customerPasswordEdit.passwordsDoNotMatch"));
      setShowConfirmPasswordError(true);
      error = true;
    }
    if (currentPassword === "") {
      setShowCurrentPasswordErrorText(t("views.customerPasswordEdit.currentPasswordEmpty"));
      setShowCurrentPasswordError(true);
      error = true;
    }
    if (password === "") {
      setShowPasswordErrorText(t("views.customerPasswordEdit.newPasswordEmpty"));
      setShowPasswordError(true);
      error = true;
    }
    if (confirmPassword === "") {
      setShowConfirmPasswordErrorText(t("views.customerPasswordEdit.confirmPasswordEmpty"));
      setShowConfirmPasswordError(true);
      error = true;
    }
    if (!error) {
      const passwords = {
        currentPassword: currentPassword,
        password: password,
        confirmPassword: confirmPassword,
      };

      dispatch(changePassword(user._id, passwords));
    }

    setConfirmOpen(false);
  }, [dispatch, currentPassword, password, confirmPassword, user._id, t]);

  const handleSetPassword = useCallback(
    (value) => {
      setShowPasswordErrorText("");
      setShowPasswordError(false);
      setPassword(value);
    },
    [dispatch]
  );

  const handleSetConfirmPassword = useCallback(
    (value) => {
      setShowConfirmPasswordError(false);
      setShowConfirmPasswordErrorText("");
      setConfirmPassword(value);
    },
    [dispatch]
  );

  const handleSetCurrentPassword = useCallback(
    (value) => {
      setShowCurrentPasswordError(false);
      setShowCurrentPasswordErrorText("");
      setCurrentPassword(value);
    },
    [dispatch]
  );

  return (
    <>
      <Card>
        <CardHeader title={t("views.customerPasswordEdit.changePassword")} />
        <CardContent>
          <Grid container>
            <Grid xs={12} md={6}>
              <Stack spacing={3}>
                <FormControl sx={{ width: "100%" }} variant={"standard"}>
                  <TextField
                    id="current-password"
                    required
                    placeholder={t("views.customerPasswordEdit.currentPassword")}
                    onChange={(e) => handleSetCurrentPassword(e.target.value)}
                    type={showCurrentPassword ? "text" : "password"}
                    variant="outlined"
                    autoComplete="off"
                    error={showCurrentPasswordError}
                    helperText={showCurrentPasswordErrorText}
                    InputProps={{
                      endAdornment: (
                        <IconButton aria-label="toggle password visibility" onClick={() => setShowCurrentPassword(!showCurrentPassword)}>
                          {showCurrentPassword ? <VisibilityOff sx={{ color: "#551ffa" }} /> : <Visibility sx={{ color: "#551ffa" }} />}
                        </IconButton>
                      ),
                    }}
                  />
                </FormControl>

                <FormControl sx={{ width: "100%" }} variant={"standard"}>
                  <TextField
                    id="new-password"
                    required
                    placeholder={t("views.customerPasswordEdit.newPassword")}
                    onChange={(e) => handleSetPassword(e.target.value)}
                    type={showNewPassword ? "text" : "password"}
                    variant="outlined"
                    autoComplete="new-password"
                    error={showPasswordError}
                    helperText={showPasswordErrorText}
                    InputProps={{
                      endAdornment: (
                        <IconButton aria-label="toggle password visibility" onClick={() => setShowNewPassword(!showNewPassword)}>
                          {showNewPassword ? <VisibilityOff sx={{ color: "#551ffa" }} /> : <Visibility sx={{ color: "#551ffa" }} />}
                        </IconButton>
                      ),
                    }}
                  />
                </FormControl>

                <FormControl sx={{ width: "100%" }} variant={"standard"}>
                  <TextField
                    id="confirm-password"
                    required
                    placeholder={t("views.customerPasswordEdit.confirmPassword")}
                    onChange={(e) => handleSetConfirmPassword(e.target.value)}
                    type={showConfirmPassword ? "text" : "password"}
                    variant="outlined"
                    autoComplete="new-password"
                    error={showConfirmPasswordError}
                    helperText={showConfirmPasswordErrorText}
                    InputProps={{
                      endAdornment: (
                        <IconButton aria-label="toggle password visibility" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                          {showConfirmPassword ? <VisibilityOff sx={{ color: "#551ffa" }} /> : <Visibility sx={{ color: "#551ffa" }} />}
                        </IconButton>
                      ),
                    }}
                  />
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
          <Button onClick={() => setConfirmOpen(true)} variant="outlined" sx={{ marginTop: "20px" }}>
            {t("views.customerPasswordEdit.changePassword")}
          </Button>
        </CardContent>
      </Card>
      <ConfirmDialog
        open={isConfirmOpen}
        title={t("views.customerPasswordEdit.confirmChangePassword")}
        onClose={() => setConfirmOpen(false)}
        onAccept={passwordSaveChanges}
      />
    </>
  );
};
