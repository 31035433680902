import {
  CHANGE_FOLDER,
  CREATE_FOLDER,
  GET_FOLDERS,
  SET_FOLDER_ID,
  FOLDER_FLAG_OFF,
  FOLDER_DELETE,
  EDIT_FOLDER
} from "../constant";

const initial = {
  isLoading: false,
  currentFolder: "root",
  userFolders: [],
  userFiles: [],
  adminFolders: [],
  adminFiles: [],
  isFolderCreated: false,
  isFolderDeleted: false,
  isFolderUpdated: false
};

export const FileFolderReducer = (state = initial, action) => {
  switch (action.type) {
    case FOLDER_FLAG_OFF:
      return {
        ...state,
        isFolderCreated: false,
        isFolderDeleted: false,
        isFolderUpdated: false
      };
    case CREATE_FOLDER:
      return {
        ...state,
        userFolders: [...state.userFolders, action.payload],
        isFolderCreated: true,
      };
    case GET_FOLDERS:
      return {
        ...state,
        userFolders: [...action.payload],
      };
    case SET_FOLDER_ID:
      return {
        ...state,
        currentFolder: action.payload,
      };
    case CHANGE_FOLDER:
      return {
        ...state,
        currentFolder: action.payload,
      };
    case FOLDER_DELETE:
      return {
        ...state,
        userFolders: state.userFolders.filter(f => f._id !== action.payload),
        isFolderDeleted: true
      }
    case EDIT_FOLDER:
      const _findEditFolderIndex = state.userFolders.findIndex(f => f._id === action.payload._id);
      console.log(_findEditFolderIndex, action.payload)
      state.userFolders[_findEditFolderIndex] = { ...action.payload };
      return {
        ...state,
        userFolders: state.userFolders,
        isFolderUpdated: true
      }
    default:
      return state;
  }
};
