import React from "react";
import { useTranslation } from "react-i18next";

const CardYt = ({ item }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div
        style={{
          width: "329px",
          height: "288px",
          border: "2px solid pink",
          borderRadius: "10px",
          marginTop: "20px",
        }}>
        <iframe
          title={t("views.cardYt.youtubeVideo")}
          width="329px"
          height="288px"
          style={{ border: "1px solid black", borderRadius: "10px" }}
          src={`${item.url}`}></iframe>
      </div>
    </div>
  );
};

export default CardYt;
