import React, { useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link, TextField, Typography, DialogContentText } from "@mui/material";
import { addWidget, addContent } from "../../../redux/actions/Content";

export default function DialogBoxCanva({ isOpen, onClose, onAddWidget, onAddContent, isIcon }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentFolder } = useSelector((state) => state.FileFolder);
  const { user } = useSelector((state) => state.User);

  //Form fields
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");

  useEffect(() => {
    handleDialogClose();
  }, [isOpen, onClose]);

  const handleDialogClose = useCallback(() => {
    setName("");
    setUrl("");
  }, []);

  const handleAdd = useCallback(() => {
    if (name === "" || !url) {
      toast.error(t("views.dialogBoxPostermywall.fillRequiredFields"));
      return;
    }

    const isValidUrl = /^(https?:\/\/)?(www\.)?postermywall\.com/.test(url);

    if (!isValidUrl) {
     toast.error(t("views.dialogBoxPostermywall.invalidUrl"));
     return;
    }

    let action = onAddWidget({ name, type: 'widget', widgetCat: 'postermywall', widgetIcon: isIcon, url: url, folderId: currentFolder, user: user._id });

    (async () => {
      await action;
      onClose();
      return;
    })();

  }, [name, url]);

  const handlePreviewOpen = () => {
    if (!url) {
      toast.error(t("views.dialogBoxPostermywall.fillRequiredFields"));
      return;
    }

    const isValidUrl = /^(https?:\/\/)?(www\.)?postermywall\.com/.test(url);

    if (!isValidUrl) {
      toast.error(t("views.dialogBoxPostermywall.invalidUrl"));
      return;
    }

    window.open(url, "_blank", "noopener,noreferrer"); 
  };

  return (
    <>
      <Dialog fullWidth open={isOpen} onClose={() => onClose(false)}>
        <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
          <img src={"./images/postermywallLogo.png"} alt="Logo" width={"150px"} />
        </Box>
        <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>{t("views.dialogBoxPostermywall.title")}</DialogTitle>    

        <DialogContent sx={{ minHeight: "30vh" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} mt={2}>
              <Button href="https://www.postermywall.com/index.php?rid=1031" target="_blank" variant="contained" color="primary" underline="none">
                {t("views.dialogBoxPostermywall.loginToPostermywall")}
              </Button>
            </Grid>
          </Grid>
            <div
              style={{
                display: "flex",
                marginTop: 20,
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ fontWeight: "bold" }}>
              {t("views.dialogBoxPostermywall.name")} 
              </DialogContentText>
              <TextField
                id="outlined-basic"
                label={t("views.dialogBoxPostermywall.designName")}
                variant="outlined"
                fullWidth
                sx={{ marginLeft: 3, width: "430px" }}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                marginTop: 20,
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ fontWeight: "bold" }}>
              {t("views.dialogBoxPostermywall.url")}
              </DialogContentText>
              <TextField
                id="outlined-basic"
                label={t("views.dialogBoxPostermywall.url")}
                variant="outlined"
                fullWidth
                sx={{ marginLeft: 3, width: "430px" }}
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </div>
            <Typography align="right" mt={2}>
                <Link href="https://youtu.be/9tQxqqvEw8Q" target="_blank">
                  {t("views.dialogBoxPostermywall.howToGetLink")}
                </Link>
              </Typography>
        </DialogContent>

        <DialogActions
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 2,
              marginTop: 1,
            }}
          >
            <div>
              <Button variant="contained" color="primary" onClick={handlePreviewOpen}
              >
                {t("views.dialogBoxPostermywall.preview")}
              </Button>
            </div>
            <div style={{ marginLeft: "auto" }}>
            <Button onClick={() => onClose(false)}>{t("views.dialogBoxPostermywall.cancel")}</Button>
            <Button variant="contained" color="primary" onClick={handleAdd}>
              {t("views.dialogBoxPostermywall.save")}
            </Button>
            </div>
          </DialogActions>
      </Dialog>
    </>
  );
}
