import { useMemo, useEffect } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

const AuthRouter = ({ auth }) => {

    const { user, auth: authUser } = useSelector(state => state.User);

    const params = useParams();

    const isAuthorized = useMemo(() => authUser && (auth ? auth(user, params) : true), [user, params, authUser, auth]);

    useEffect(() => {
        if (!isAuthorized)
            toast.error('Unauthorized', { id: "unauth" });
    }, [isAuthorized]);

    if (isAuthorized) {
        return <Outlet />;
    }

    return (
        <Navigate to="/" />
    );

};

export default AuthRouter;