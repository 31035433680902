import axios from 'axios';

let baseURL = process.env.REACT_APP_BACKEND_URL;

export const baseImg = baseURL.substring(0, baseURL.length - 1);
export const baseApi = `${baseURL}api`;

export const resolveSrc = (url) => `${!url?.startsWith('http') ? `${baseImg}/` : ''}${url}`

export const api = axios.create({
    baseURL: baseApi,
    withCredentials: true,
    headers: {
        // "Content-type":"application/json;multipart/form-data",
        // Accept:"application/json;multipart/form-data",
        "Access-Control-Allow-Credentials": true,
    }
});