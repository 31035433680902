import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Checkbox,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

const UserAddOnsTable = ({ plans, addOns, setAddOns, userSubscription }) => {
  const { t } = useTranslation();

  if (plans.length === 0) {
    return <Typography>{t("views.userAddOnsTable.noAddOns")}</Typography>;
  }

  const purchasedItemsMap = (userSubscription?.items || []).reduce(
    (acc, item) => {
      acc[item.name.toLowerCase()] = item;
      return acc;
    },
    {}
  );

  const currentPlan = userSubscription?.planName
    ? plans.find((plan) => plan.name === userSubscription.planName)
    : plans[0];

  const currentPlanFeatures = currentPlan?.planFeatures || [];

  const newFeatures = currentPlanFeatures.filter((feature) => {
    const fname = feature.name.toLowerCase();
    const isPartOfPlan = currentPlanFeatures.includes(fname);

    if (feature.inputType === "text") {
      return true;
    }

    if (feature.inputType === "checkbox") {
      if (feature.name === "Canva App") {
        return feature.enabled;
      }
      return !feature.enabled;
    }

    return !isPartOfPlan;
  });

  const handleQuantityChange = (featureId, price, name) => (e) => {
    const quantity = parseInt(e.target.value, 10) || 0;
    setAddOns((prev) => ({
      ...prev,
      [featureId]: {
        quantity,
        unitPrice: price,
        featureName: name,
      },
    }));
  };

  const handleCheckboxChange = (featureId, price, name) => (e) => {
    const checked = e.target.checked;
    const quantity = checked ? 1 : 0;
    setAddOns((prev) => ({
      ...prev,
      [featureId]: {
        quantity,
        unitPrice: price,
        featureName: name,
      },
    }));
  };

  return (
    <Box>
      <Typography variant="h6">
        {t("views.userAddOnsTable.additionalFeatures")}
      </Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <strong>{t("views.userAddOnsTable.function")}</strong>
            </TableCell>
            <TableCell align="center">
              <strong>{t("views.userAddOnsTable.unitPrice")}</strong>
            </TableCell>
            <TableCell align="center">
              <strong>{t("views.userAddOnsTable.quantity")}</strong>
            </TableCell>
            <TableCell align="center">
              <strong>{t("views.userAddOnsTable.totalPrice")}</strong>
            </TableCell>
          </TableRow>
          {newFeatures.map((feature) => {
            const {
              _id: featureId,
              price: unitPrice,
              name: featureName,
              inputType,
            } = feature;

            const purchasedData = purchasedItemsMap[featureName.toLowerCase()];
            const purchasedQuantity = purchasedData?.quantity || 0;
            const purchasedUnitPrice = purchasedData?.price || unitPrice;

            const data = addOns[featureId] || {
              quantity: purchasedQuantity,
              unitPrice: purchasedUnitPrice,
              featureName,
            };

            const total = data.unitPrice * data.quantity;

            if (inputType === "text") {
              return (
                <TableRow key={featureId}>
                  <TableCell>{featureName}</TableCell>
                  <TableCell align="center">{data.unitPrice} USD</TableCell>
                  <TableCell align="center">
                    <TextField
                      type="number"
                      variant="standard"
                      sx={{ width: 80 }}
                      value={data.quantity}
                      onChange={handleQuantityChange(
                        featureId,
                        data.unitPrice,
                        featureName
                      )}
                      inputProps={{
                        min: 0,
                        style: { textAlign: "right" },
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">{total} USD</TableCell>
                </TableRow>
              );
            } else {
              return (
                <TableRow key={featureId}>
                  <TableCell>{featureName}</TableCell>
                  <TableCell align="center">{data.unitPrice} USD</TableCell>
                  <TableCell align="center">
                    <Checkbox
                      checked={data.quantity > 0}
                      onChange={handleCheckboxChange(
                        featureId,
                        data.unitPrice,
                        featureName
                      )}
                    />
                  </TableCell>
                  <TableCell align="center">{total} USD</TableCell>
                </TableRow>
              );
            }
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

export default UserAddOnsTable;
