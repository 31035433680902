import React from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, IconButton, Stack, SvgIcon, TableCell, TableRow } from "@mui/material";

// icons
import GroupIcon from "@mui/icons-material/Group";
import Edit02Icon from "@untitled-ui/icons-react/build/esm/Edit02";
import DeleteIcon from "@mui/icons-material/Delete";

const UserGroupListTable = ({ group, onGroupDelete, onGroupEdit }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    onGroupDelete(group?._id);
    handleClose();
  };

  const handleEdit = () => {
    onGroupEdit(group?._id, group?.name, group?.color);
    handleClose();
  };

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ paddingLeft: "32px" }}>
          <Stack alignItems="center" direction="row" spacing={1}>
            <GroupIcon sx={{ color: group.color }} />
            <div>{group.name}</div>
          </Stack>
        </TableCell>
        <TableCell align="right">
          <IconButton onClick={() => handleEdit()} aria-label={t("views.userGroupListTable.editGroup")}>
            <SvgIcon>
              <Edit02Icon />
            </SvgIcon>
          </IconButton>
          <IconButton onClick={() => handleDelete()} aria-label={t("views.userGroupListTable.deleteGroup")}>
            <SvgIcon>
              <DeleteIcon />
            </SvgIcon>
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default UserGroupListTable;
