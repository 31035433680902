import { CgEthernet } from "react-icons/cg";
import { TbAntennaBars1, TbAntennaBars2, TbAntennaBars3, TbAntennaBars4, TbAntennaBars5 } from "react-icons/tb";

export const NetworkIcon = ({ ethernetStatus, wifiSignal, connectionType }) => {
    if (ethernetStatus === 1 && connectionType !== "WiFi") {
      return (
        <CgEthernet size={20} color="green"/>
      );
    }

    const wifiIcons = [
      <TbAntennaBars1 size={30} color="red"/>, 
      <TbAntennaBars2 size={30} color="orange" />, 
      <TbAntennaBars3 size={30} color="orange" />, 
      <TbAntennaBars4 size={30} color="green"/> ,
      <TbAntennaBars5 size={30} color="green"/>
    ];

    return wifiIcons[wifiSignal] || wifiIcons[0];
  };