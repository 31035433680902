import { useSelector } from "react-redux";

import { Backdrop, CircularProgress } from '@mui/material';

const WidgetsGridLoader = ({ gridName }) => {

    //Show loader when grid or draft is pending
    const isPending = useSelector(state => !!state.WidgetsGrids[gridName]?.pending ||
        (state.WidgetsGrids[gridName]?.draft && state.WidgetsGrids[state.WidgetsGrids[gridName]?.draft]?.pending));

    return (
        <Backdrop open={isPending} display="flex" alignItems="center" justifyContent="center" sx={{ position: "absolute", inset: 0, zIndex: "fab" }}>
            <CircularProgress size={64} />
        </Backdrop>
    );
};

export default WidgetsGridLoader;