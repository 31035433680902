import { useRef, useCallback } from "react";
import { TextFieldElement, useFormContext } from "react-hook-form-mui";
import { Autocomplete } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";

import { withErrorBoundary } from "react-error-boundary";

const parsePlaceObject = (placeObject) => {
  const country = placeObject.address_components.find((c) => c.types.includes("country"))?.long_name ?? "";

  const street = placeObject.address_components.find((c) => c.types.includes("route"))?.long_name ?? "";
  const street_number = placeObject.address_components.find((c) => c.types.includes("street_number"))?.long_name ?? "";
  const postal_code = placeObject.address_components.find((c) => c.types.includes("postal_code"))?.long_name ?? "";
  const city = placeObject.address_components.find((c) => c.types.includes("locality"))?.long_name ?? "";

  const state = placeObject.address_components.find((c) => c.types.includes("administrative_area_level_1"))?.long_name ?? "";

  const address1 = street_number && street ? `${street} ${street_number}` : "";
  const address2 = postal_code && city ? `${postal_code} ${city}` : "";

  const formattedAddress = placeObject.formatted_address;

  return {
    country,
    address1,
    address2,
    state,
    formattedAddress,
  };
};

const UserAddressInput = () => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();

  const selectedPlaceRef = useRef();
  const placeChanged = useCallback(() => {
    const placeObject = selectedPlaceRef.current.getPlace();

    if (placeObject) {
      Object.entries(parsePlaceObject(placeObject)).forEach(([field, value]) => setValue(field, value));
    }
    console.log(placeObject);
  }, [setValue]);

  return (
    <Autocomplete onLoad={(autocomplete) => (selectedPlaceRef.current = autocomplete)} onPlaceChanged={placeChanged}>
      <TextFieldElement fullWidth label={t("views.userAddressInput.changeAddress")} name="formattedAddress" />
    </Autocomplete>
  );
};

export default withErrorBoundary(UserAddressInput);
