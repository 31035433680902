import { useMemo } from "react";
import { TextField, MenuItem } from "@mui/material";

const WidgetEditInput = ({ name, type, label, placeholder, options, error, ...props }) => {
  const errorProps = useMemo(
    () => ({
      error: !!error,
      helperText: error ?? undefined,
    }),
    [error]
  );

  if (type == "text") return <TextField error={!!error} name={name} label={label} placeholder={placeholder} fullWidth {...errorProps} {...props} />;

  if (type == "textarea") return <TextField name={name} label={label} placeholder={placeholder} fullWidth multiline rows={6} {...errorProps} {...props} />;

  if (type == "select" && options)
    return (
      <TextField
        name={name}
        label={label}
        placeholder={placeholder}
        fullWidth
        defaultValue={options.find((o) => o.default)?.value ?? undefined}
        select
        {...errorProps}
        {...props}>
        {options.map((option, idx) => (
          <MenuItem key={`option_${name}_${option.value}`} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
    );

  return null;
};

export default WidgetEditInput;
