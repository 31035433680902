// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth, GoogleAuthProvider} from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAK6B12T52lZegR3iXI85kjubbYEIukF1s",
  authDomain: "vc-player-7f2e5.firebaseapp.com",
  projectId: "vc-player-7f2e5",
  storageBucket: "vc-player-7f2e5.appspot.com",
  messagingSenderId: "437604093652",
  appId: "1:437604093652:web:2342b6cba716cf6d62b541",
  measurementId: "G-S03TFCR7WK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const fbAuth=getAuth(app);
const provider=new GoogleAuthProvider();
export {app, fbAuth, provider}