import { useState, useCallback, useMemo } from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import { Box, Collapse, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import WidgetEditInput from "./WidgetEditInput";

const WidgetEditStylesInputs = ({ widget, action, formErrors }) => {
  const { t } = useTranslation();
  const { size, border, padding, proportions } = widget?.style ?? {};

  const [isCollapsed, setCollapsed] = useState(false);
  const handleCollapseToggle = useCallback(() => {
    setCollapsed((c) => !c);
  }, []);

  /* Size field */
  const [widgetSize, setWidgetSize] = useState(action == "EDIT" ? size : "12");
  const handleWidgetSizeChange = useCallback((size) => {
    setWidgetSize(size);
  }, []);

  const widgetSizeOptions = useMemo(() => Array.from({ length: 12 }, (x, i) => i + 1).map((i) => ({ name: i + "", value: i })), []);

  /* Border field */
  const [widgetBorder, setWidgetBorder] = useState(action == "EDIT" ? border ?? "none" : "none");
  const handleWidgetBorderChange = useCallback((border) => {
    setWidgetBorder(border);
  }, []);

  const widgetBorderOptions = useMemo(
    () => [
      { name: t("components.widgetEditStylesInputs.none"), value: "none" },
      { name: t("components.widgetEditStylesInputs.primary"), value: "primary" },
      { name: t("components.widgetEditStylesInputs.secondary"), value: "secondary" },
    ],
    []
  );

  /* Padding field */
  const [widgetPadding, setWidgetPadding] = useState(action == "EDIT" ? padding ?? 4 : 4);
  const handleWidgetPaddingChange = useCallback((padding) => {
    setWidgetPadding(padding);
  }, []);

  const widgetPaddingOptions = useMemo(
    () => [
      { name: t("components.widgetEditStylesInputs.none"), value: 0 },
      { name: t("components.widgetEditStylesInputs.small"), value: 2 },
      { name: t("components.widgetEditStylesInputs.medium"), value: 3 },
      { name: t("components.widgetEditStylesInputs.large"), value: 4 },
    ],
    []
  );

  /* Proportions field */
  const [widgetProportions, setWidgetProportions] = useState(action == "EDIT" ? proportions ?? "auto" : "auto");
  const handleWidgetProportionsChange = useCallback((proportions) => {
    setWidgetProportions(proportions);
  }, []);

  const widgetProportionsOptions = useMemo(
    () => [
      { name: t("components.widgetEditStylesInputs.auto"), value: "auto" },
      { name: "1:1", value: "1:1" },
      { name: "4:3", value: "4:3" },
      { name: "16:9", value: "16:9" },
    ],
    []
  );

  return (
    <>
      <Box px={1} display="flex" alignItems="center" sx={{ cursor: "pointer" }} onClick={handleCollapseToggle}>
        <Typography variant="caption">{t("components.widgetEditStylesInputs.widgetStyle")}</Typography>
        {isCollapsed ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </Box>
      <Collapse in={isCollapsed}>
        <Box my={2}>
          <WidgetEditInput
            label={t("components.widgetEditStylesInputs.widgetSize")}
            type="select"
            name="style-size"
            placeholder={t("components.widgetEditStylesInputs.selectWidgetSize")}
            options={widgetSizeOptions}
            value={widgetSize}
            error={formErrors["style-size"]}
            onChange={(e) => handleWidgetSizeChange(e.target.value)}
          />
        </Box>
        <Box my={2}>
          <WidgetEditInput
            label={t("components.widgetEditStylesInputs.widgetProportions")}
            type="select"
            name="style-proportions"
            placeholder={t("components.widgetEditStylesInputs.selectWidgetProportions")}
            options={widgetProportionsOptions}
            value={widgetProportions}
            error={formErrors["style-proportions"]}
            onChange={(e) => handleWidgetProportionsChange(e.target.value)}
          />
        </Box>
        <Box my={2}>
          <WidgetEditInput
            label={t("components.widgetEditStylesInputs.widgetBorderType")}
            type="select"
            name="style-border"
            placeholder={t("components.widgetEditStylesInputs.selectWidgetBorderType")}
            options={widgetBorderOptions}
            value={widgetBorder}
            error={formErrors["style-border"]}
            onChange={(e) => handleWidgetBorderChange(e.target.value)}
          />
        </Box>
        <Box my={2}>
          <WidgetEditInput
            label={t("components.widgetEditStylesInputs.widgetPaddingSize")}
            type="select"
            name="style-padding"
            placeholder={t("components.widgetEditStylesInputs.selectWidgetPaddingSize")}
            options={widgetPaddingOptions}
            value={widgetPadding}
            error={formErrors["style-padding"]}
            onChange={(e) => handleWidgetPaddingChange(e.target.value)}
          />
        </Box>
      </Collapse>
    </>
  );
};

export default WidgetEditStylesInputs;

// export const StylesInputsSchema = {
//   "style-size": Yup.string().required(t("components.widgetEditStylesInputs.widgetSizeRequired")),
//   "style-border": Yup.string().required(t("components.widgetEditStylesInputs.widgetBorderTypeRequired")),
//   "style-padding": Yup.string().required(t("components.widgetEditStylesInputs.widgetPaddingSizeRequired")),
//   "style-proportions": Yup.string().required(t("components.widgetEditStylesInputs.widgetProportionsRequired")),
// };
