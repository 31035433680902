import React, { createElement } from 'react';
import useUserFeature from "../../hooks/use-user-feature";
import { Button, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

const PlanFeatureComponent = ({ feature, component, ...props }) => {
  const { t } = useTranslation();
  const planFeatureEnabled = useUserFeature(feature);

  const Element = createElement(component,{
    ...props,
    ...(!planFeatureEnabled ? { disabled: true } : {})
  });

  return (
    <>
      {planFeatureEnabled ? (
        <>
          {Element}
        </>
      ) : (
        <Tooltip title={t("components.planFeatureButton.notAvailable")}>
          <span>
            {Element}
          </span>
        </Tooltip>
      )}
    </>
  );
};

export default PlanFeatureComponent;
