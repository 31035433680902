import { useState, useMemo, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Autocomplete, Chip, TextField, Box } from "@mui/material";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

import { assignGroup } from "src/redux/actions/User";

import GroupIcon from "@mui/icons-material/Group";

const GroupAssignUsersDialog = ({ open, onClose, users, group }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedUsers, setSelectedUsers] = useState([]);
  useEffect(() => setSelectedUsers([]), [open]);

  const usersOptions = useMemo(() => users.filter((u) => (group ? !u.groups.find((g) => g._id == group._id) : true)), [group, users]);

  const handleAssign = useCallback(async () => {
    if (!selectedUsers.length || !group) return onClose?.();

    try {
      await dispatch(assignGroup({ groupId: group._id, userIds: selectedUsers }));
      toast.success(t("views.groupAssignUsersDialog.successMessage"));
    } catch {
      toast.error(t("views.groupAssignUsersDialog.errorMessage"));
    } finally {
      onClose?.();
    }
  }, [selectedUsers, group, onClose, t]);

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
        <GroupIcon color="primary" sx={{ fontSize: 24, marginRight: "8px" }} />
        {t("views.groupAssignUsersDialog.title")}
      </DialogTitle>
      <DialogContent>
        <Box my={2}>
          <Autocomplete
            multiple
            options={usersOptions}
            getOptionLabel={(option) => `${option.fullname} (${option.email})`}
            value={selectedUsers}
            onChange={(_, newValue) => {
              setSelectedUsers(newValue);
            }}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={t("views.groupAssignUsersDialog.selectUsers")}
                placeholder={t("views.groupAssignUsersDialog.selectUserPlaceholder")}
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => <Chip variant="outlined" label={`${option.fullname} (${option.email})`} {...getTagProps({ index })} />)
            }
          />
        </Box>
        <DialogActions>
          <Button onClick={onClose}>{t("views.groupAssignUsersDialog.close")}</Button>
          <Button onClick={handleAssign} type="submit">
            {t("views.groupAssignUsersDialog.save")}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default GroupAssignUsersDialog;
