import {
  CHANGE_PLAN_FEATURE,
  GET_PLANS_SUCCESS,
  GET_PLAN_SUCCESS,
  CHANGE_PLAN_FEATURE_DRAFT,
  SAVE_PLANS_FEATURES,
  SEARCH_PLANS,
  CHANGE_PLAN_PRICE_DRAFT,
  CHANGE_PLAN_FEATURE_PRICE_DRAFT,
} from "../constant";

const initial = {
  plans: [],
  searchResults: [],
  plan: {},
};

export const PlanReducer = (state = initial, action) => {
  switch (action.type) {
    case GET_PLANS_SUCCESS:
      return {
        ...state,
        plans: [...action.payload],
      };
    case GET_PLAN_SUCCESS:
      return {
        ...state,
        plan: { ...action.payload },
      };
    case CHANGE_PLAN_FEATURE_DRAFT:
      const { planId, featureId, ...settings } = action.payload;

      return {
        ...state,
        plans: state.plans.map((p) =>
          p._id == planId
            ? {
                ...p,
                planFeatures: p.planFeatures.map((f) =>
                  f._id == featureId
                    ? {
                        ...f,
                        ...settings,
                      }
                    : f
                ),
              }
            : p
        ),
      };
    case CHANGE_PLAN_PRICE_DRAFT:
      const { planId: pricePlanId, price } = action.payload;
      return {
        ...state,
        plans: state.plans.map((p) =>
          p._id == pricePlanId
            ? {
                ...p,
                price: price,
              }
            : p
        ),
      };
    case CHANGE_PLAN_FEATURE_PRICE_DRAFT: {
      const { featureId, planId, price } = action.payload;

      return {
        ...state,
        plans: state.plans.map((plan) =>
          plan._id === planId
            ? {
                ...plan,
                planFeatures: plan.planFeatures.map((feature) =>
                  feature._id === featureId
                    ? { ...feature, price: parseInt(price, 10) || 0 }
                    : feature
                ),
              }
            : plan
        ),
      };
    }

    case SAVE_PLANS_FEATURES:
      return state;
    case SEARCH_PLANS:
      const query = action.payload;
      console.log(query);
      let searchResults = [];

      if (query && query !== "") {
        searchResults = state.plans.map((p) => ({
          ...p,
          planFeatures: p.planFeatures.filter((feature) =>
            feature.name.toLowerCase().includes(query)
          ),
        }));
      }

      return {
        ...state,
        searchResults: searchResults,
      };
    default:
      return state;
  }
};
