import { Button, TextField, Box, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from "@mui/material";
import React, { useCallback, useMemo, useEffect, Fragment, useRef, useState } from "react";
import { FaList } from "react-icons/fa";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import LanguageIcon from "@mui/icons-material/Language";
import ImageIcon from "@mui/icons-material/Image";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import DataObjectIcon from "@mui/icons-material/DataObject";
import { resolveSrc } from "../../../api/endpoints";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import SlowMotionVideoIcon from "@mui/icons-material/SlowMotionVideo";
import { useTranslation } from "react-i18next";

function SelectFilesModal({ open, onClose, setOpenSelectFiles, files, onSaveFiles, playlist }) {
  const { t } = useTranslation();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [filesToSkip, setFilesToSkip] = useState([]);

  useEffect(() => {
    let files = [];
    if (playlist && playlist.content) {
      playlist?.content.forEach((item) => {
        if (item.content) {
          files.push(item.content._id);
        }
      });
      setFilesToSkip(files);
    }
  }, [open]);

  const handleSelectFile = (id) => {
    setSelectedFiles((selectedFiles) => [...selectedFiles, id]);
  };

  const handleUnselectFile = (id) => {
    setSelectedFiles(selectedFiles.filter((item) => item != id));
  };

  const handleSaveFiles = (ids) => {
    onSaveFiles(selectedFiles);
    setOpenSelectFiles(false);
    setSelectedFiles([]);
  };

  return (
    <>
      <Dialog open={open} onClose={() => setOpenSelectFiles(false)} maxWidth={"xl"} fullWidth={true} sx={{ zIndex: "10000000000" }}>
        <DialogTitle sx={{ textAlign: "center" }}>{t("views.selectFilesModal.selectFiles")}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}>
            <Box sx={{ margin: "20px 0" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell>{t("views.selectFilesModal.preview")}</TableCell>
                    <TableCell>{t("views.selectFilesModal.type")}</TableCell>
                    <TableCell>{t("views.selectFilesModal.fileName")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files
                    .filter((file) => !filesToSkip.includes(file.id))
                    .map((file) => {
                      return (
                        <TableRow
                          hover
                          key={file.id}
                        // selected={isSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              // checked={isSelected}
                              onChange={(event) => {
                                if (event.target.checked) {
                                  handleSelectFile(file.id);
                                } else {
                                  handleUnselectFile(file.id);
                                }
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <Box sx={{ height: "100px", width: "180px" }}>
                              {file.type === "image" && (
                                <img src={resolveSrc(file?.url)} style={{ maxWidth: "100%", height: "auto", maxHeight: "100px", margin: "auto", display: "block" }} />
                              )}
                              {file.type === "widget" && (
                                <img
                                  src={`${file.widget?.widgetIcon}`}
                                  style={{ maxWidth: "100%", height: "auto", maxHeight: "100px", margin: "auto", display: "block" }}
                                />
                              )}
                              {file.type === "video" && <video src={resolveSrc(file?.url)} controls style={{ width: "100%", height: "auto", maxHeight: "100px" }} />}
                              {file.embed === "embed" && <embed src={`${file.url}`} style={{ maxWidth: "100%", height: "auto", maxHeight: "100px" }} />}
                            </Box>
                          </TableCell>
                          <TableCell>
                            {file.type === "image" && <ImageIcon />}
                            {file.type === "widget" && <AppRegistrationIcon />}
                            {file.type === "video" && <SlowMotionVideoIcon />}
                            {file.embed === "embed" && <DataObjectIcon />}
                          </TableCell>
                          <TableCell>
                            <Stack alignItems="center" direction="row" spacing={1}>
                              <Typography color="text.secondary" variant="body2">
                                {file.name}
                              </Typography>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant={"outlined"}
            sx={{
              height: "38px",
              width: "193px",
              textTransform: "capitalize",
              borderRadius: "5px",
            }}
            onClick={() => setOpenSelectFiles(false)}>
            {t("views.selectFilesModal.close")}
          </Button>
          <Button
            variant={"contained"}
            sx={{
              height: "38px",
              width: "193px",
              textTransform: "capitalize",
              borderRadius: "5px",
            }}
            onClick={handleSaveFiles}>
            {t("views.selectFilesModal.add")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SelectFilesModal;
