import { useReducer, useCallback, useEffect } from 'react';

import WidgetDeleteDialog from './dialogs/WidgetDeleteDialog';
import WidgetEditDialog from './dialogs/WidgetEditDialog';

const actionsInitState = {
    widget: null,
    action: null,
    gridName: ''
};

const actionsReducer = (state, { type, payload }) => {
    switch (type) {
        case "DELETE_WIDGET":
            return {
                ...state,
                widget: payload,
                action: "DELETE"
            };
        case "EDIT_WIDGET":
            return {
                ...state,
                widget: payload,
                action: "EDIT"
            };
        case "ADD_WIDGET":
            return {
                ...state,
                widget: null,
                action: "ADD"
            };
        case "END_ACTIONS":
            return {
                ...state,
                widget: null,
                action: null
            }
        case "CHANGE_GRID_NAME":
            return {
                ...state,
                gridName: payload
            }
    }
};

export const useWidgetActionsModal = (gridName) => {

    //Modal state
    const modalState = useReducer(actionsReducer, {
        ...actionsInitState,
        gridName
    });

    //Actions
    const [_, dispatch] = modalState;

    useEffect(() => {
        dispatch({
            type: "CHANGE_GRID_NAME",
            payload: gridName
        })
    }, [gridName]);

    const openDeleteWidgetModal = useCallback((widgetData) => {
        dispatch({
            type: "DELETE_WIDGET",
            payload: widgetData
        })
    }, []);

    const openEditWidgetModal = useCallback((widgetData) => {
        dispatch({
            type: "EDIT_WIDGET",
            payload: widgetData
        })
    }, []);

    const openAddWidgetModal = useCallback((widgetData) => {
        dispatch({
            type: "ADD_WIDGET"
        })
    }, []);

    //Open modal
    const openModal = useCallback((type, widgetData) => {
        if (type === 'DELETE')
            return openDeleteWidgetModal(widgetData);

        if (type === 'EDIT')
            return openEditWidgetModal(widgetData);

        if (type === 'ADD')
            return openAddWidgetModal(widgetData);
    }, []);

    return {
        modalState,
        openModal
    };
};

const WidgetsActionModal = ({ modal }) => {

    const [{ action }] = modal;

    if (action === 'DELETE') {
        return <WidgetDeleteDialog modal={modal} />;
    }

    if (action === 'EDIT' || action === 'ADD') {
        return <WidgetEditDialog modal={modal} />;
    }

    return null;
};

export default WidgetsActionModal;
