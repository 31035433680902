const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const fetchSubscription = async (stripeCustomerId) => {
  if (!stripeCustomerId) {
    console.warn("Brak stripeCustomerId, pomijam fetchSubscription");
    return null;
  }
  try {
    const response = await fetch(
      `${BACKEND_URL}api/subscription/${stripeCustomerId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.ok) {
      return await response.json();
    }
    throw new Error("Błąd pobierania subskrypcji");
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createSubscription = async (payload) => {
  try {
    const response = await fetch(
      `${BACKEND_URL}api/subscription/create-checkout-session`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    if (response.ok) {
      return await response.json();
    }
    throw new Error("Błąd tworzenia subskrypcji");
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateSubscription = async (payload) => {
  try {
    const response = await fetch(
      `${BACKEND_URL}api/subscription/update-subscription`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    if (response.ok) {
      return await response.json();
    }
    throw new Error("Błąd aktualizacji subskrypcji");
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const cancelSubscription = async (subscriptionId) => {
  try {
    const response = await fetch(
      `${BACKEND_URL}api/subscription/cancel-subscription`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ subscriptionId }),
      }
    );
    if (response.ok) {
      return await response.json();
    }
    throw new Error("Błąd anulowania subskrypcji");
  } catch (error) {
    console.error(error);
    return null;
  }
};
