import { api } from "../../api/endpoints";
import {
  //CHANGE_GROUP,
  CREATE_GROUP,
  GROUP_FLAG_OFF,
  GET_GROUPS,
  //SET_GROUP_ID,
  GROUP_DELETE,
  EDIT_GROUP,
} from "../constant";
import toast from "react-hot-toast";


export const groupFlagOff = () => {
  return async (dispatch) => {
    dispatch({
      type: GROUP_FLAG_OFF,
      payload: "",
    });
  };
};

export const createGroup = (obj) => async (dispatch) => {
  try {
    const { data } = await api.post("/group/creategroup", obj);

    dispatch({
      type: CREATE_GROUP,
      payload: data.group,
    });

  } catch (error) {
    if (!error.response)
      return;

    const { status, data } = error.response;

    if (status === 400)
      throw { formErrors: data };
  }
};

export const getGroups = () => {
  return async (dispatch) => {
    try {
      const { data, status } = await api.get(`/group/getgroups`);
      dispatch({
        type: GET_GROUPS,
        payload: ((status === 200) ? data.groups : []),
      });
    } catch (error) {
      // bład pobierania grup, czyszcze
      dispatch({
        type: GET_GROUPS,
        payload: [],
      });
    }
  };
};

export const groupDelete = (id) => async (dispatch) => {
  const { data } = await api.delete(`/group/${id}`);

  if (data.deleted) {
    dispatch({
      type: GROUP_DELETE,
      payload: id,
    });

    toast.success(`Group deleted successfully.`);
  } else {
    toast.error(`You can't delete a group with users and a group's`)
  }
};

export const editGroup = (id, obj) => async (dispatch) => {
  try {
    const { data } = await api.patch(`/group/${id}`, obj)

    dispatch({
      type: EDIT_GROUP,
      payload: data.group,
    });

  } catch (error) {
    if (!error.response)
      return;

    const { status, data } = error.response;

    if (status === 400)
      throw { formErrors: data };
  }
}