import { useEffect } from "react";
import { useFormContext } from 'react-hook-form-mui';

const FormValuesInit = (initValues) => {

    const { setValue, reset } = useFormContext();

    useEffect(() => {
        Object.entries(initValues).forEach(([name, value]) => setValue(name, value));

    }, [initValues, setValue, reset]);

};

export default FormValuesInit;