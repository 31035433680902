import React from "react";
// import Embed from "../images/Embed.png";

const Card = ({ item }) => {
  return (
    <div>
      <div
        style={{
          Width: "329px",
          Height: "288px",
          marginTop: "10px",
          border: "1px solid black",
          borderRadius: "10px",
        }}>
        <div style={{ width: "329px", height: "169px" }}>
          <img
            // src={Embed}
            alt=""
            style={{ width: "329px", height: "169px", objectFit: "contain" }}
          />
        </div>
        <div>
          <h3 style={{ marginLeft: "6px", textAlign: "center" }}>{item.title}</h3>
        </div>

        {/* Code To Show WebPage From TextField */}
        {/* <div
          style={{ height: "100%", width: "100%" }}
          dangerouslySetInnerHTML={{ __html: item.inputValue }}
        ></div> */}
      </div>
    </div>
  );
};

export default Card;
