import { useCallback, useState } from "react";
import PropTypes from "prop-types";
import SearchMdIcon from "@untitled-ui/icons-react/build/esm/SearchMd";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";

export const OrderListSearch = (props) => {
  const { t } = useTranslation();

  const sortOptions = [
    {
      label: t("views.orderListSearch.sortOptions.newest"),
      value: "date_desc",
    },
    {
      label: t("views.orderListSearch.sortOptions.oldest"),
      value: "date_asc",
    },
    {
      label: t("views.orderListSearch.sortOptions.nameAsc"),
      value: "name_asc",
    },
    {
      label: t("views.orderListSearch.sortOptions.nameDesc"),
      value: "name_desc",
    },
  ];

  const { onFiltersChange, onSortChange } = props;
  const [sortBy, setSortBy] = useState("date_desc");

  const handleSortChange = useCallback(
    (event) => {
      const sortDir = event.target.value;
      onSortChange?.(sortDir);
      setSortBy(sortDir);
    },
    [onSortChange]
  );

  return (
    <div>
      <Divider />
      <Stack alignItems="center" direction="row" flexWrap="wrap" gap={3} sx={{ p: 3 }}>
        <Box component="form" sx={{ flexGrow: 1 }}>
          <OutlinedInput
            defaultValue=""
            fullWidth
            onChange={(e) => onFiltersChange(e.target.value)}
            name="orderNumber"
            placeholder={t("views.orderListSearch.searchPlaceholder")}
            startAdornment={
              <InputAdornment position="start">
                <SvgIcon>
                  <SearchMdIcon />
                </SvgIcon>
              </InputAdornment>
            }
          />
        </Box>
        <TextField label={t("views.orderListSearch.sortBy")} name="sort" onChange={handleSortChange} select SelectProps={{ native: true }} value={sortBy}>
          {sortOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {t(option.label)}
            </option>
          ))}
        </TextField>
      </Stack>
    </div>
  );
};

OrderListSearch.propTypes = {
  onFiltersChange: PropTypes.func,
  onSortChange: PropTypes.func,
  sortBy: PropTypes.string,
  sortDir: PropTypes.oneOf(["asc", "desc"]),
};
