import { Box, Container, Stack, Card, Grid, SvgIcon, TextField, Button, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Layout from "../../components/layout/Layout";
import Edit02Icon from "@untitled-ui/icons-react/build/esm/Edit02";
import { emailTempSave, getEmailTemp, editEmailTemp, emailFlagOff } from "../../redux/actions/EmailAction";
import { useSettings } from "src/hooks/use-settings";

function RedesignEmail({ user }) {
  const { t } = useTranslation();
  const settings = useSettings();

  const dispatch = useDispatch();
  const { emailtemps, isEdited, isAdded } = useSelector((state) => state.EmailTemp);
  const [emailData, setEmailData] = useState({
    subject: "",
    text: "",
  });
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState("");

  useEffect(() => {
    if (isEdited) {
      setEdit(false);
      setId("");
      dispatch(emailFlagOff());
    }
    if (isAdded) {
      setEdit(false);
      dispatch(emailFlagOff());
    }
  }, [isEdited, isAdded]);

  useEffect(() => {
    dispatch(getEmailTemp());
  }, []);

  useEffect(() => {
    if (emailtemps.length > 0) {
      setEdit(false);
    }
  }, []);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setEmailData((prev) => ({ ...prev, [name]: value }));
  };

  const saveEmailTemp = () => {
    dispatch(emailTempSave(emailData));
    setEmailData({ subject: "", text: "" });
  };

  const editAbleHandle = () => {
    setEdit((prev) => !prev);
    setId(emailtemps[0]?._id);
    setEmailData({
      subject: emailtemps[0]?.subject,
      text: emailtemps[0]?.text,
    });
  };

  const editEmailTemps = () => {
    dispatch(editEmailTemp(id, emailData));
  };

  return (
    <>
      <Layout title={t("views.redesignEmail.title")} user={user}>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8,
          }}>
          <Container maxWidth={settings.stretch ? false : "xl"}>
            <Stack spacing={4}>
              <Stack direction="row" justifyContent="space-between" spacing={4}>
                <Typography variant="h4">{t("views.redesignEmail.emailTemplate")}</Typography>

                <Stack alignItems="center" direction="row" spacing={3}>
                  <Button
                    onClick={editAbleHandle}
                    startIcon={
                      <SvgIcon>
                        <Edit02Icon />
                      </SvgIcon>
                    }
                    variant="contained">
                    {t("views.redesignEmail.edit")}
                  </Button>
                </Stack>
              </Stack>
              <Stack>
                {edit ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}>
                    <TextField
                      type="text"
                      value={emailData.subject}
                      onChange={onChangeHandler}
                      name={"subject"}
                      label={t("views.redesignEmail.enterSubject")}
                      multiline
                      fullWidth
                    />
                    <TextField
                      type="text"
                      name={"text"}
                      value={emailData.text}
                      onChange={onChangeHandler}
                      label={t("views.redesignEmail.enterText")}
                      multiline
                      fullWidth
                    />
                    <Button width="200px" name={"Edit"} onClick={editEmailTemps}>
                      {t("views.redesignEmail.save")}
                    </Button>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}>
                      <Typography variant={"h6"}>{t("views.redesignEmail.subject")}:</Typography>
                      <Typography variant={"caption"}>{emailtemps[0]?.subject}</Typography>
                      <Typography variant={"h6"}>{t("views.redesignEmail.text")}:</Typography>
                      <Typography variant={"caption"}>{emailtemps[0]?.text}</Typography>
                    </Box>
                  </Box>
                )}
              </Stack>
            </Stack>
          </Container>
        </Box>
      </Layout>
    </>
  );
}

export default RedesignEmail;
