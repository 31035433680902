import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { useTheme } from "@mui/material/styles";

import { Chart } from "src/components/chart";
import { useTranslation } from "react-i18next";

export const AnalyticsTrafficSources = (props) => {
  const { t } = useTranslation();

  const useChartOptions = () => {
    const theme = useTheme();

    return {
      chart: {
        background: "transparent",
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      colors: [theme.palette.primary.main, theme.palette.mode === "dark" ? theme.palette.primary.darkest : theme.palette.primary.light],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      grid: {
        borderColor: theme.palette.divider,
        padding: {
          bottom: 0,
          left: 0,
          right: 0,
          top: 0,
        },
        strokeDashArray: 2,
      },
      plotOptions: {
        bar: {
          borderRadius: 8,
          columnWidth: "32px",
          horizontal: true,
        },
      },
      theme: {
        mode: theme.palette.mode,
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        categories: [
          t("sections.analyticsTrafficSources.categories.jun", "Jun"),
          t("sections.analyticsTrafficSources.categories.jul", "Jul"),
          t("sections.analyticsTrafficSources.categories.aug", "Aug"),
          t("sections.analyticsTrafficSources.categories.sep", "Sep"),
          t("sections.analyticsTrafficSources.categories.oct", "Oct"),
          t("sections.analyticsTrafficSources.categories.nov", "Nov"),
          t("sections.analyticsTrafficSources.categories.dec", "Dec"),
        ],
        labels: {
          style: {
            colors: theme.palette.text.secondary,
          },
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
    };
  };

  const { chartSeries } = props;
  const chartOptions = useChartOptions();

  return (
    <Card>
      <CardHeader sx={{ pb: 0 }} title={t("sections.analyticsTrafficSources.title")} />
      <CardContent sx={{ pt: 0 }}>
        <Chart height={400} options={chartOptions} series={chartSeries} type="bar" />
      </CardContent>
    </Card>
  );
};

AnalyticsTrafficSources.propTypes = {
  chartSeries: PropTypes.any.isRequired,
};
