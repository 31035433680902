// import { CallToActionOutlined } from "@mui/icons-material";
import {
  ADD_CONTENT_IN_PLAYLIST,
  ADD_PLAYLIST_SUCCESS,
  DELETE_CONTENT_FROM_PLAYLIST,
  DELETE_PLAYLIST,
  EDIT_PLAYLIST_NAME,
  GET_ONE_PLAYLIST,
  GET_PLAYLIST_SUCCESS,
  PLAYLIST_FLAG_OFF,
  UNCHECKED_TIME_LEBEL,
  UPDATE_DURATION,
  PLAYLIST_CONETNT_SORTING,
  ADD_PLAYLIST_FAILED,
  EDIT_PLAYLIST_DEMO_ATTRIBUTE,
} from "../constant";
import toast from "react-hot-toast";

const initial = {
  playlists: [],
  playlist: {},
  isPlaylistAdded: false,
  isPlaylistLoaded: false,
  isContentAdded: false,
  isOnePlaylist: false,
  isPlaylistContentSorted: false,
};
export const PlaylistReducer = (state = initial, action) => {
  switch (action.type) {
    case PLAYLIST_FLAG_OFF:
      return {
        ...state,
        isContentAdded: false,
        isOnePlaylist: false,
        isPlaylistLoaded: false,
        isPlaylistContentSorted: false,
        isPlaylistAdded: false,
      };
    case GET_PLAYLIST_SUCCESS:
      let getAllActiveList = [];
      action.payload.forEach((p, index) => {
        if (index === 0) {
          getAllActiveList.push({ ...p, isActive: true });
        } else {
          getAllActiveList.push({ ...p, isActive: false });
        }
      });
      return {
        ...state,
        playlists: [...getAllActiveList].reverse(),
        isPlaylistLoaded: true,
      };
    case ADD_PLAYLIST_SUCCESS:
      return {
        ...state,
        isPlaylistAdded: true,
        playlists: [action.payload, ...state.playlists.reverse()],
      };
    case GET_ONE_PLAYLIST:
      const newOneContent = [];
      const activeList = [];
      const oneP = state.playlists.find((p) => p._id === action.payload);
      if (!oneP) {
        return {
          ...state,
        };
      }
      const oneContents = oneP.content;
      oneContents.forEach((c) => {
        newOneContent.push({ ...c, timeChecked: false });
      });
      state.playlists.forEach((p) => {
        if (p._id === action.payload) {
          activeList.push({ ...p, isActive: true });
        } else {
          activeList.push({ ...p, isActive: false });
        }
      });
      return {
        ...state,
        isOnePlaylist: true,
        playlist: { ...oneP, content: newOneContent },
        playlists: activeList,
      };
    case DELETE_PLAYLIST:
      return {
        ...state,
        playlists: state.playlists.filter((p) => !action.payload.includes(p._id)),
      };
    case ADD_CONTENT_IN_PLAYLIST:
      const newContent = [];
      const playlistIndex = state.playlists.findIndex((playlist) => playlist._id === action.payload._id);
      const contents = action.payload.content;
      contents.forEach((c) => {
        newContent.push({ ...c, timeChecked: false });
      });
      state.playlists[playlistIndex] = {
        ...action.payload,
        content: newContent,
      };
      return {
        ...state,
        playlists: state.playlists,
        playlist: { ...action.payload, content: newContent },
        isContentAdded: true,
      };
    case DELETE_CONTENT_FROM_PLAYLIST:
      const __playlist_content_index = state.playlists.findIndex((p) => p._id === action.payload.updatedPlaylist._id);
      state.playlists[__playlist_content_index] = {
        ...action.payload.updatedPlaylist,
      };
      state.playlist = {
        ...action.payload.updatedPlaylist,
        content: action.payload.updatedPlaylist.content.filter((c) => c._id !== action.payload.contentId),
      };
      return {
        ...state,
        playlist: state.playlist,
        playlists: state.playlists,
      };
    case UNCHECKED_TIME_LEBEL:
      const __playlist_index = state.playlists.findIndex((p) => p._id === action.payload.playlistId);
      const __content_index = state.playlists[__playlist_index].content.findIndex((c) => c._id === action.payload.contentId);
      const __getPlaylist = state.playlists[__playlist_index];
      const __getContents = __getPlaylist.content;
      __getContents[__content_index] = {
        ...__getContents[__content_index],
        timeChecked: true,
      };
      state.playlists[__playlist_index] = {
        ...state.playlists[__playlist_index],
        content: __getContents,
      };
      return {
        ...state,
        playlists: state.playlists,
        playlist: state.playlists.find((p) => p._id === action.payload.playlistId),
      };
    case UPDATE_DURATION:
      const { contentId, duration } = action.payload;
      return {
        ...state,
        playlist: {
          ...state.playlist,
          content: state.playlist.content.map(item =>
            item._id === contentId
              ? { ...item, duration: duration * 1000, timeChecked: false }
              : item
          )
        }
      };
    case EDIT_PLAYLIST_NAME:
      const __playlistIndex = state.playlists.findIndex((p) => p._id === action.payload._id);
      state.playlists[__playlistIndex] = { ...action.payload };
      return {
        ...state,
        playlists: state.playlists,
        playlist: { ...action.payload },
      };
    case EDIT_PLAYLIST_DEMO_ATTRIBUTE:
      const ___playlistIndex = state.playlists.findIndex((p) => p._id === action.payload._id);
      state.playlists[___playlistIndex] = { ...action.payload };
      return {
        ...state,
        playlists: state.playlists,
        playlist: { ...action.payload },
      };
    case PLAYLIST_CONETNT_SORTING:
      const _forSortingPlaylistIndex = state.playlists.findIndex((p) => p._id === action.payload.playlistId);
      const _contentList = state.playlists[_forSortingPlaylistIndex].content;

      const result = Array.from(_contentList);
      const [removed] = result.splice(action.payload.from, 1);
      result.splice(action.payload.to, 0, removed);

      state.playlists[_forSortingPlaylistIndex] = {
        ...state.playlists[_forSortingPlaylistIndex],
        content: result,
      };

      return {
        ...state,
        playlist: { ...state.playlist, content: result },
        playlists: state.playlists,
        isPlaylistContentSorted: true,
      };
    case ADD_PLAYLIST_FAILED:
      toast.error(action.payload);
      return {
        ...state,
        isContentAdding: false,
      };
    default:
      return state;
  }
};
