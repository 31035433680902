import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import PlanFeatureButton from "src/components/planFeature/PlanFeatureButton";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";

import useUserFeature from "src/hooks/use-user-feature";

function RedesignCard({ cardTitle, icon, amount, moreButton, buttonIcon, buttonTo, buttonComponent, feature }) {
  const featureEnabled = useUserFeature(feature);
  const ButtonComponent = feature && !featureEnabled ? PlanFeatureButton : Button;

  return (
    <Card sx={!featureEnabled && feature && { opacity: 0.5 }}>
      <Stack
        alignItems="center"
        direction={{
          xs: "column",
          sm: "row",
        }}
        spacing={3}
        sx={{
          px: 4,
          py: 3,
        }}>
        <div>
          {buttonTo ? (
            <ButtonComponent component={buttonComponent} to={buttonTo} size="small">
              <>{icon}</>
            </ButtonComponent>
          ) : (
            <>{icon}</>
          )}
        </div>
        <Box sx={{ flexGrow: 1 }}>
          <Typography color="text.secondary" variant="body2">
            {cardTitle}
          </Typography>
          <Typography color="primary" variant="h4">
            {amount}
          </Typography>
        </Box>
      </Stack>
      <Divider />
      <CardActions>
        <ButtonComponent component={buttonComponent} to={buttonTo} color="primary" endIcon={<SvgIcon>{buttonIcon}</SvgIcon>} size="small">
          {moreButton}
        </ButtonComponent>
      </CardActions>
    </Card>
  );
}

export default RedesignCard;
