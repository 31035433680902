import React, { useCallback, useRef, useState } from "react";
import { Box, Menu, MenuItem, Tooltip, Card, Stack, Typography } from "@mui/material";
import { useMediaQuery } from "react-responsive";

//Icons
import SvgIcon from '@mui/material/SvgIcon';
import IconButton from '@mui/material/IconButton';
import DotsVerticalIcon from '@untitled-ui/icons-react/build/esm/DotsVertical';

import { ReactComponent as FolderIcon } from './icon-folder.svg';

import FolderMenu from './FolderMenu';

const Folder = ({ name, _id, color = "#FEB800", icon, menu, onFolderClick }) => {

    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

    //Menu
    const menuAnchorElRef = useRef(null);
    const [isMenuOpen, setMenuOpen] = useState(false);

    const handleMenuOpen = useCallback(() => {
        setMenuOpen(true);
    }, []);

    const handleMenuClose = useCallback(() => {
        setMenuOpen(false);
    }, []);

    //Folder
    const onFolderClicks = useCallback(() => {
        if (isMobile)
            return;

        onFolderClick?.(_id);
    }, [isMobile, onFolderClick, _id]);

    return (
        <Card
            onDoubleClick={onFolderClicks}
            sx={{
                backgroundColor: 'transparent',
                boxShadow: 0,
                width: "159px",
                transition: (theme) =>
                    theme.transitions.create(['background-color, box-shadow'], {
                        easing: theme.transitions.easing.easeInOut,
                        duration: 200,
                    }),
                '&:hover': {
                    backgroundColor: 'background.paper',
                    boxShadow: 16,
                },
            }}
            variant="outlined"
        >
            <Box sx={{ p: 2, position: "relative", }}>
                {menu && (
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="flex-end"
                        sx={{ pt: 2, px: 2, position: "absolute", right: 0, paddingTop: 0, }}
                    >
                        <IconButton onClick={handleMenuOpen} ref={menuAnchorElRef}>
                            <SvgIcon fontSize="small">
                                <DotsVerticalIcon />
                            </SvgIcon>
                        </IconButton>
                        <FolderMenu _id={_id} open={isMenuOpen} onClose={handleMenuClose} anchorEl={menuAnchorElRef.current} children={menu} />
                    </Stack>
                )}
                <Box sx={{ display: 'flex', mb: 1, }} onClick={onFolderClicks}>
                    <Box sx={{ display: 'inline-flex', cursor: 'pointer', position: 'relative' }}>
                        <FolderIcon fill={color} />
                        {icon && (
                            <Box sx={{ position: 'absolute', inset: '0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {icon}
                            </Box>
                        )}
                    </Box>
                </Box>
                <Typography
                    sx={{ cursor: 'pointer', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                    variant="subtitle2"
                    onClick={onFolderClicks}
                    title={name}
                >
                    {name}
                </Typography>
            </Box>
        </Card>
    );
};

export default Folder;