import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import UserGroupListSearch from "./components/UserGroupListSearch";
import UsersGroupListTable from "./components/UsersGroupListTable";
import Layout from "src/components/layout/Layout";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import { useSettings } from "src/hooks/use-settings";
import { RouterLink } from "src/components/router-link";
import { paths } from "src/paths";
import ArrowLeftIcon from "@untitled-ui/icons-react/build/esm/ArrowLeft";
import { Seo } from "src/components/seo";
import AddIcon from "@mui/icons-material/Add";
import GroupIcon from "@mui/icons-material/Group";
import { useMediaQuery } from "react-responsive";
import ConfirmationModel from "../../components/confirmmodel/ConfirmationModel";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Link,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Card,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
} from "@mui/material";

import { Scrollbar } from "src/components/scrollbar";
import { customersApi } from "src/api/customers";
import { useMounted } from "src/hooks/use-mounted";
import { useSelection } from "src/hooks/use-selection";
import { usePageView } from "src/hooks/use-page-view";

//state
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import toast from "react-hot-toast";
import { editGroup, groupDelete, groupFlagOff, getGroups, createGroup, changeGroup } from "../../redux/actions/Group";

// style

import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";

// customer search

const useCustomersSearch = () => {
  const [state, setState] = useState({
    page: 0,
    rowsPerPage: 5,
  });

  const handlePageChange = useCallback((event, page) => {
    setState((prevState) => ({
      ...prevState,
      page,
    }));
  }, []);

  const handleRowsPerPageChange = useCallback((event) => {
    setState((prevState) => ({
      ...prevState,
      rowsPerPage: parseInt(event.target.value, 10),
    }));
  }, []);

  return {
    handlePageChange,
    handleRowsPerPageChange,
    state,
  };
};

// customer store

const useCustomersStore = (searchState) => {
  const isMounted = useMounted();
  const [state, setState] = useState({
    customers: [],
    customersCount: 0,
  });

  const handleCustomersGet = useCallback(async () => {
    try {
      const response = await customersApi.getCustomers(searchState);

      if (isMounted()) {
        setState({
          customers: response.data,
          customersCount: response.count,
        });
      }
    } catch (err) {
      console.error(err);
    }
  }, [searchState, isMounted]);

  useEffect(
    () => {
      handleCustomersGet();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchState]
  );

  return {
    ...state,
  };
};

const useCustomersIds = (customers = []) => {
  return useMemo(() => {
    return customers.map((customer) => customer.id);
  }, [customers]);
};

// user groups

function UserGroups({ socketRef, user }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [ids, setIds] = useState("");
  const [openCreateModel, setOpenCreateModel] = useState(false);
  const [openGroupEditModel, setOpenGroupEditModel] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [groupColorPicker, setGroupColorPicker] = useState("#0369fe");
  const [groupColor, setGroupColor] = useColor(groupColorPicker);
  const [openCreateGroup, setOpenCreateGroup] = useState(false);

  const { content } = useSelector((state) => state.Content);

  const { userGroups, isGroupCreated, isGroupDeleted } = useSelector((state) => ({
    userGroups: state.Group.userGroups,
    isGroupCreated: state.Group.isGroupCreated,
    isGroupDeleted: state.Group.isGroupDeleted,
  }));

  // group state

  useEffect(() => {
    if (isGroupCreated) {
      toast.success(t("views.userGroups.groupCreated"));
      dispatch(groupFlagOff());
    }
    if (isGroupDeleted) {
      toast.success(t("views.userGroups.groupDeleted"));
      dispatch(groupFlagOff());
    }
  }, [isGroupCreated, isGroupDeleted, t, dispatch]);

  const onGroupDelete = (id) => {
    setIds(id);
    setOpenCreateModel(true);
  };

  const onConfirm = () => {
    setOpenCreateModel(false);
    dispatch(groupDelete(ids));
  };

  const onGroupEdit = (id, name, color) => {
    setIds(id);
    setGroupName(name);
    setGroupColorPicker(color);
    setOpenGroupEditModel(true);
  };

  const onConfirmGroupEdit = () => {
    const isEmpty = groupName === "";
    const isAvailable = userGroups.find((f) => f.name.toLocaleUpperCase() === groupName.toLocaleUpperCase());

    if (isEmpty) {
      toast.error(t("views.userGroups.emptyGroupName"));
    } else if (isAvailable) {
      toast.success(t("views.userGroups.groupAlreadyAvailable"));
    } else {
      dispatch(editGroup(ids, groupName, groupColor.hex));
      setOpenGroupEditModel(false);
    }
  };

  // groups

  const makeGroup = () => {
    const isAvailable = userGroups.find((f) => f.name.toLocaleUpperCase() === groupName.toLocaleUpperCase());
    if (groupName === "") {
      toast.error(t("views.userGroups.emptyGroupName"));
    } else if (isAvailable) {
      toast.success(t("views.userGroups.groupAlreadyAvailable"));
    } else {
      dispatch(
        createGroup({
          name: groupName,
          color: groupColor.hex,
        })
      );
      setOpenCreateGroup(false);
    }
  };

  // filters

  const settings = useSettings();

  const customersSearch = useCustomersSearch();
  const customersStore = useCustomersStore(customersSearch.state);
  const customersIds = useCustomersIds(customersStore.customers);
  const customersSelection = useSelection(customersIds);

  usePageView();

  const [searchInput, setSearchInput] = useState("");

  const onSearchBarChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value.toLowerCase());
  };

  const filteredGroups = userGroups.filter((el) => {
    if (searchInput === "") {
      return el;
    } else {
      return el.name.toLowerCase().includes(searchInput);
    }
  });

  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

  return (
    <>
      <Layout>
        <Seo title={t("views.userGroups.seoTitle")} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8,
          }}>
          <Container maxWidth={settings.stretch ? false : "xl"}>
            <Stack spacing={4}>
              <div>
                <Link
                  color="text.primary"
                  component={RouterLink}
                  href={paths.users.index}
                  sx={{
                    alignItems: "center",
                    display: "inline-flex",
                  }}
                  underline="hover">
                  <SvgIcon sx={{ mr: 1 }}>
                    <ArrowLeftIcon />
                  </SvgIcon>
                  <Typography variant="subtitle2">{t("views.userGroups.users")}</Typography>
                </Link>
              </div>

              <Stack direction="row" justifyContent="space-between" spacing={4}>
                <Stack spacing={1}>
                  <Typography variant="h4">{t("views.userGroups.title")}</Typography>
                </Stack>
                <Stack alignItems="center" direction="row" spacing={2}>
                  <Button
                    onClick={() => setOpenCreateGroup(true)}
                    startIcon={
                      <SvgIcon>
                        <AddIcon />
                      </SvgIcon>
                    }
                    variant="contained">
                    {t("views.userGroups.addGroup")}
                  </Button>
                </Stack>
              </Stack>

              <Card>
                <UserGroupListSearch onSearchBarChange={onSearchBarChange} />

                <Box sx={{ position: "relative" }}>
                  <Scrollbar>
                    <Table sx={{ minWidth: 700 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ paddingLeft: "32px" }}>{t("views.userGroups.name")}</TableCell>
                          <TableCell align="right">{t("views.userGroups.actions")}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <>
                          {filteredGroups.map((group, index) => {
                            return <UsersGroupListTable key={index} group={group} onGroupDelete={onGroupDelete} onGroupEdit={onGroupEdit} />;
                          })}
                        </>
                      </TableBody>
                    </Table>
                  </Scrollbar>
                </Box>
              </Card>
            </Stack>
          </Container>
        </Box>
      </Layout>

      <Dialog open={openCreateGroup} onClose={() => setOpenCreateGroup(false)}>
        <FormControl component="form" onSubmit={(e) => e.preventDefault()}>
          <DialogTitle sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
            <GroupIcon color="primary" sx={{ fontSize: 24, marginRight: "8px" }} />
            {t("views.userGroups.createGroup")}
          </DialogTitle>
          <DialogContent
            sx={{
              width: isMobile ? "300px" : "500px",
              height: isMobile ? "360px" : "500px",
            }}>
            <TextField
              autoFocus
              required
              margin="dense"
              id="groupName"
              name="groupName"
              type="text"
              fullWidth
              variant="standard"
              label={t("views.userGroups.typeGroupName")}
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              sx={{ marginBottom: "24px" }}
            />
            <ColorPicker hideInput={["rgb", "hsv"]} color={groupColor} onChange={setGroupColor} />
            <DialogActions>
              <Button onClick={() => setOpenCreateGroup(false)}>{t("views.userGroups.close")}</Button>
              <Button onClick={makeGroup} type="submit">
                {t("views.userGroups.save")}
              </Button>
            </DialogActions>
          </DialogContent>
        </FormControl>
      </Dialog>

      <Dialog open={openGroupEditModel} onClose={() => setOpenGroupEditModel(false)}>
        <FormControl component="form" onSubmit={(e) => e.preventDefault()}>
          <DialogTitle sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
            <GroupIcon color="primary" sx={{ fontSize: 24, marginRight: "8px" }} />
            {t("views.userGroups.editGroup")}
          </DialogTitle>
          <DialogContent
            sx={{
              width: isMobile ? "300px" : "500px",
              height: isMobile ? "360px" : "500px",
            }}>
            <Box style={{ width: "100%", height: "50px", margin: "20px auto" }}>
              <TextField type={"text"} label={t("views.userGroups.text")} value={groupName} onChange={(e) => setGroupName(e.target.value)} fullWidth />
            </Box>

            <ColorPicker hideInput={["rgb", "hsv"]} color={groupColor} onChange={setGroupColor} />

            <DialogActions>
              <Button onClick={() => setOpenGroupEditModel(false)}>{t("views.userGroups.cancel")}</Button>
              <Button onClick={onConfirmGroupEdit} variant={"contained"} type="submit">
                {t("views.userGroups.save")}
              </Button>
            </DialogActions>
          </DialogContent>
        </FormControl>
      </Dialog>

      <ConfirmationModel
        openCreateModel={openCreateModel}
        title={t("views.userGroups.deleteConfirmation")}
        onClose={() => setOpenCreateModel(false)}
        onConfirm={onConfirm}
        Icon={DeleteIcon}
      />
    </>
  );
}

export default UserGroups;
