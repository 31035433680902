import { memo } from "react";
import { CardHeader } from '@mui/material';

const WidgetDefaultHeader = memo(({ widgetData, actions, editMode }) => {

    if (editMode)
        return (
            <CardHeader title={widgetData.name} action={actions} />
        );

    return null;

});

export default WidgetDefaultHeader;