import { PENDING_WIDGETS_GRID, EDIT_WIDGETS_GRID, DELETE_WIDGETS_GRID, INIT_WIDGETS_GRID, ADD_WIDGET_TO_GRID, EDIT_WIDGET_IN_GRID, DELETE_WIDGET_FROM_GRID, UPDATE_WIDGETS_IN_GRID } from "../constant";

const initial = {
    dashboard: {
        pending: true,
        widgets: [],
        draft: null,
    }
};

export const WidgetsGridsReducer = (state = initial, action) => {
    switch (action.type) {
        case PENDING_WIDGETS_GRID: {
            return {
                ...state,
                [action.payload.gridName]: {
                    widgets: [],
                    ...(state[action.payload.gridName] ?? {}),
                    pending: true,
                }
            };
        }


        case INIT_WIDGETS_GRID: {
            return {
                ...state,
                [action.payload.gridName]: {
                    widgets: [...action.payload.widgets ?? []],
                    draft: action.payload.draft,
                    pending: false,
                }
            };
        }

        case EDIT_WIDGETS_GRID: {
            return {
                ...state,
                [action.payload.gridName]: {
                    ...(state[action.payload.gridName] ?? {}),
                    draft: action.payload.draft,
                    pending: false,
                }
            };
        }

        case DELETE_WIDGETS_GRID: {
            delete state[action.payload.gridName];

            return {
                ...state
            };
        }

        case ADD_WIDGET_TO_GRID: {
            const grid = state[action.payload.gridName];
            grid.widgets = [
                ...grid.widgets,
                action.payload.widget
            ];
            grid.pending = false;

            return {
                ...state,
                [action.payload.gridName]: grid,
            };
        }

        case EDIT_WIDGET_IN_GRID: {
            const grid = state[action.payload.gridName];
            grid.widgets = grid.widgets.map(w => w._id === action.payload.widget._id ? action.payload.widget : w);
            grid.pending = false;

            return {
                ...state,
                [action.payload.gridName]: grid,
            };
        }

        case DELETE_WIDGET_FROM_GRID: {
            const grid = state[action.payload.gridName];
            grid.widgets = grid.widgets.filter(w => w._id !== action.payload.widget._id);
            grid.pending = false;

            return {
                ...state,
                [action.payload.gridName]: grid,
            };
        }

        case UPDATE_WIDGETS_IN_GRID: {
            const grid = state[action.payload.gridName];
            grid.widgets = grid.widgets.map(w => action.payload.widgets.find(newWidget => newWidget._id === w._id) ?? w);
            grid.pending = false;

            return {
                ...state,
                [action.payload.gridName]: grid,
            };
        }

        default:
            return state;
    }
};