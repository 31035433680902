import { useCallback, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { getWidgetGrid, editWidgetInGrid, discardWidgetGridChanges, saveWidgetGrid, editWidgetGrid, swapWidgetsInGrid } from "../../redux/actions/WidgetsGridsAction";

import Grid from '@mui/material/Unstable_Grid2';
import { DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arraySwap, rectSwappingStrategy, SortableContext } from '@dnd-kit/sortable';

import WidgetGridItem from './WidgetGridItem';
import WidgetsGridHeader from './WidgetsGridHeader';
import WidgetsGridAddButton from './WidgetsGridAddButton';
import WidgetsGridLoader from './WidgetsGridLoader';

import WidgetsActionModal, { useWidgetActionsModal } from "./WidgetsActionModal";

export const WidgetsGrid = ({ name, title }) => {

    const dispatch = useDispatch();

    //Grid state
    const gridDraftName = useSelector(state => state.WidgetsGrids[name].draft); //Draft is only returned in api when user is admin
    const isEditMode = useMemo(() => !!gridDraftName, [gridDraftName]);

    //Get rendered grid name (normal or draft)
    const gridName = useMemo(() => {
        return gridDraftName ?? name;
    }, [gridDraftName, name]);

    //Init widgetGrid
    useEffect(() => {
        dispatch(getWidgetGrid(gridName));
    }, [gridName]);

    //Handle draft mode
    const handleGridEdit = useCallback(() => {
        if (!isEditMode)
            dispatch(editWidgetGrid(name));
    }, [name, isEditMode]);

    const handleGridDiscard = useCallback(() => {
        if (isEditMode && name !== gridName)
            dispatch(discardWidgetGridChanges(name));
    }, [name, gridName, isEditMode]);

    const handleGridSave = useCallback(() => {
        if (isEditMode && name !== gridName)
            dispatch(saveWidgetGrid(name));
    }, [name, gridName, isEditMode]);

    //Get widgets in correct order
    const widgets = useSelector(state => {
        return [...state.WidgetsGrids[gridName]?.widgets ?? []].sort((a, b) => a.order - b.order);
    });

    //Draggable
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
    const handleDragEnd = useCallback((event) => {
        const { over, active } = event;

        console.log('[ES]', event);

        if (over) {
            dispatch(swapWidgetsInGrid(gridName, [
                { _id: over.id },
                { _id: active.id }
            ]));
            arraySwap(widgets, over.id, active.id);
        }
    }, [gridName, widgets]);

    //ActionModal
    const { modalState, openModal } = useWidgetActionsModal(gridName);

    const handleWidgetDelete = useCallback((widgetData) => {
        openModal('DELETE', widgetData);
    }, [gridName]);

    const handleWidgetEdit = useCallback((widgetData) => {
        openModal('EDIT', widgetData);
    }, [gridName]);

    const handleChangeVisiblility = useCallback((widgetData) => {
        dispatch(editWidgetInGrid(gridName, {
            _id: widgetData._id,
            style: {
                ...widgetData.style,
                isVisible: !widgetData.style.isVisible
            }
        }));
    }, [gridName]);

    const handleWidgetAdd = useCallback(() => {
        openModal('ADD');
    }, [gridName]);

    //UI elements
    const AddButton = useMemo(() => (isEditMode && (
        <Grid item xs={12}>
            <WidgetsGridAddButton onClick={handleWidgetAdd} />
        </Grid>
    )), [isEditMode]);

    return (
        <>
            <Grid container className="widget-grid" spacing={4} sx={{ position: "relative", minHeight: "360px", height: "100%" }}>
                <WidgetsGridLoader gridName={name} />
                <DndContext
                    sensors={sensors}
                    onDragEnd={handleDragEnd}
                >
                    <SortableContext items={widgets.map((w) => ({ id: w._id }))} strategy={rectSwappingStrategy}>
                        {widgets.map((widgetData) => (
                            <WidgetGridItem
                                key={`widgetItem_${widgetData._id}`}
                                editMode={isEditMode}
                                widgetData={widgetData}
                                onDelete={handleWidgetDelete}
                                onEdit={handleWidgetEdit}
                                onChangeVisibility={handleChangeVisiblility}
                            />
                        ))}
                    </SortableContext>
                </DndContext>
                {AddButton}
                <Grid item xs={12}>
                    <WidgetsGridHeader
                        title={title}
                        editMode={isEditMode}
                        onEdit={handleGridEdit}
                        onCancel={handleGridDiscard}
                        onSave={handleGridSave}
                    />
                </Grid>
            </Grid>
            <WidgetsActionModal modal={modalState} />
        </>
    );
};