import { useState, useEffect } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import parseApiFormErrors from "src/utils/parse-api-form-errors";

import { createGroup, editGroup } from "src/redux/actions/Group";

import FormValuesInit from "src/components/inputs/FormValuesInit";
import ColorInputElement from "src/components/inputs/ColorInputElement";

import GroupIcon from "@mui/icons-material/Group";

import { FormContainer, TextFieldElement } from "react-hook-form-mui";

const GroupEditDialog = ({ group, parentGroup, open, onClose }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

  const [isFormPending, setFormPending] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    setFormErrors({});
  }, [group]);

  const dispatch = useDispatch();

  const handleSubmit = async (data) => {
    try {
      setFormPending(true);
      setFormErrors({});
      if (!group) {
        await dispatch(
          createGroup({
            ...data,
            ...(parentGroup?._id ? { parent: parentGroup._id } : {}),
          })
        );
        toast.success(t("views.groupEditDialog.groupAddedSuccess"));
      } else {
        await dispatch(editGroup(group._id, data));
        toast.success(t("views.groupEditDialog.groupUpdatedSuccess"));
      }

      onClose?.();
    } catch (e) {
      if (!e.formErrors) return;

      setFormErrors(parseApiFormErrors(e.formErrors));
    } finally {
      setFormPending(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <FormContainer defaultValues={group ? group : { name: undefined, color: "#ff0000" }} errors={formErrors} onSuccess={handleSubmit}>
        <FormValuesInit initValues={group} />
        <DialogTitle sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
          <GroupIcon color="primary" sx={{ fontSize: 24, marginRight: "8px" }} />
          {group ? t("views.groupEditDialog.editGroup") : t("views.groupEditDialog.addGroup")}
        </DialogTitle>
        <DialogContent
          sx={{
            width: isMobile ? "300px" : "500px",
            height: isMobile ? "360px" : "500px",
          }}>
          <fieldset disabled={isFormPending}>
            <TextFieldElement
              autoFocus
              required
              margin="dense"
              name="name"
              type="text"
              fullWidth
              variant="standard"
              label={t("views.groupEditDialog.typeGroupName")}
              sx={{ marginBottom: "24px" }}
            />
            <ColorInputElement name="color" />
            <DialogActions>
              <Button onClick={onClose}>{t("views.groupEditDialog.close")}</Button>
              <Button type="submit">{t("views.groupEditDialog.save")}</Button>
            </DialogActions>
          </fieldset>
        </DialogContent>
      </FormContainer>
    </Dialog>
  );
};

export default GroupEditDialog;
