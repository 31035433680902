import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { grey } from "@mui/material/colors";
import { useState } from "react";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";
import CardWebsite from "./CardWebsite";
import { useDispatch, useSelector } from "react-redux";
import { addWidget } from '../../../redux/actions/Content';
import toast from "react-hot-toast";
export default function DialogBoxWebSite({
  isOpen,
  onClose,
  onTakeData,
  isIcon,
  onAddWidget
}) {
  // const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { currentFolder } = useSelector(state => state.FileFolder);
  const [items, setItems] = useState([]);
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [updateInterval, setUpdateInterval] = useState("");

  const { user } = useSelector(state => state.User);

  React.useEffect(() => {
    handleClickOpen();
  }, [isOpen, onclose]);

  const handleClickOpen = () => {
    // setOpen(true);
    if (isOpen) {
      setName("");
      setUrl("");
      setUpdateInterval("");
    }
    if (onClose) {
      setName("");
      setUrl("");
      setUpdateInterval("");
    }
  };

  const handleClose = () => {
    // setOpen(false);
  };

  const handleAdd = () => {
    setItems((previous) => [...previous, { title: name, url, updateInterval }]);
    if (name == '' || url == '') {
      toast.error('(*) fileds are required');
      return;
    }
    onTakeData({ title: name, url, updateInterval, isIcon });
    onAddWidget({ name, type: 'widget', widgetCat: 'website', widgetIcon: isIcon, intervalValue: updateInterval, url: url, folderId: currentFolder, user: user._id })
    setName("");
    setUrl("");
    setUpdateInterval("");
    onClose();
    // setOpen(false);
  };

  return (
    <>
      <div>
        {/* <Button variant="outlined" onClick={open}>
          Open form dialog
        </Button> */}

        <Dialog fullWidth open={isOpen} onClose={() => onClose(false)}>
          <LanguageRoundedIcon
            sx={{
              color: grey[400],
              fontSize: 100,
              textAlign: "center",
              marginX: "auto",
              paddingTop: 3,
            }}
          />
          <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
            Website
          </DialogTitle>
          <DialogContent sx={{ minHeight: "30vh" }}>
            <div
              style={{
                display: "flex",
                marginTop: 20,
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ fontWeight: "bold" }}>
                Name &#8727;
              </DialogContentText>
              <TextField
                id="outlined-basic"
                label="Your Name Here"
                variant="outlined"
                fullWidth
                sx={{ marginLeft: 3, width: "430px" }}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                marginTop: 20,
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ fontWeight: "bold" }}>
                URL &#8727;
              </DialogContentText>
              <TextField
                id="outlined-basic"
                label="URL"
                variant="outlined"
                fullWidth
                sx={{ marginLeft: 3, width: "430px" }}
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                marginTop: 20,
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ fontWeight: "bold" }}>
                Update Interval &#8727;
              </DialogContentText>
              <TextField
                id="outlined-basic"
                label="Update Interval"
                variant="outlined"
                fullWidth
                helperText="Check update every 600 seconds"
                sx={{ width: "465px" }}
                value={updateInterval}
                onChange={(e) => setUpdateInterval(e.target.value)}
              />
            </div>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 2,
              marginTop: 1,
            }}
          >
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAdd}
              >
                Preview
              </Button>
            </div>
            <div>
              <Button onClick={() => onClose(false)}>Cancel</Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAdd}
              >
                Save
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
      {/* <div
        style={{
          display: "flex",
          gap: "10px",
          marginTop: "10px",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {items.map((item, index) => (
          <CardWebsite key={index} item={item} />
        ))}
      </div> */}
    </>
  );
}
