import React, {useState,useEffect, useRef, useMemo} from 'react'
import {Select, MenuItem, FormControl, InputLabel,TextField, Grid, Button, Typography, Box, Stack, Menu} from '@mui/material';
import { ContentType, FitScreen } from './types';
import DialogBoxAssets from '../AssetsScreen/DialogBoxAssets';


import { ResolutionTypes,UnitTypes } from './types';

const SingleZone = ({playlists, settingsInfo, zone, idx, changeZoneDetails, stageSize}) => {
  const boxRef = useRef(null);
  // ---- useStates ----
  const [params, setParams] = useState({"y":0,"x":0,"width":0,"height":0, "counter_h":0,"counter_w":0,"screenHeight":0,"screenWidth":0});
  const [changedParams, setChangedParams] = useState(false);
  const [openAssetsDialog, setOpenAssetsDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // console.log("Settings information: ", settingsInfo)
  // ---- Functions ----
  const handleClick = (event) => {
    if(zone.type == "Playlist" || zone.type == "Schedule"){
      event.stopPropagation();
      console.log("handle handleClick: ")
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = (option) => {
    console.log("handle close: ", handleClose)
    setAnchorEl(null);
    if (option) {
      changeZoneDetails(idx, "object", option)
    }
  };

  const handleChangeNumbers = (e,w_h,key) => {
    if (settingsInfo["Unit Type"] === UnitTypes.PIXELS){
      var value = e.target.value
        .replace(",", ".")
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*)\./g, '$1')
        .replace(/(\.\d{4})\d+/, '$1');
      const type_val = key == ("height" || "y") ?  params.counter_h : params.counter_w

      console.log("New Value: ",value, parseFloat(value))
      if ( key == "height" ){
        if (parseFloat(value)  > params["screenHeight"]- w_h){
            value = parseFloat((params["screenHeight"]  - w_h).toFixed(2))
        }
      }
      if ( key == "y" ){
        if (parseFloat(value)  > params["screenHeight"] - w_h){
            value = parseFloat((params["screenHeight"]  - w_h).toFixed(2))
        }
      }
      if ( key == "x" ){
        console.log("Height: ", parseFloat(value) ," and real: ", params["screenWidth"] -w_h)
        if (parseFloat(value)  > params["screenWidth"] - w_h){
            value = parseFloat((params["screenWidth"]  - w_h).toFixed(2))
        }
      }
      else{

        if (parseFloat(value) > params["screenWidth"] - w_h ){
              value = parseFloat((params["screenWidth"]  - w_h).toFixed(2))
          }
        }

        setParams(prevParams => {
          return {...prevParams, [key]: value}
        })
        setChangedParams(true);
        if (value !== "") {
            changeZoneDetails(idx, key, value / type_val);//Math.min(1, newValue) * stageSize.width
        } else if (value === "") {
            changeZoneDetails(idx, key, 0);
      }
    }

    else{
      var value = e.target.value
        .replace(",", ".")
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*)\./g, '$1')
        .replace(/(\.\d{4})\d+/, '$1');
      const type_val = key == ("height" || "y") ?  stageSize.height : stageSize.width
      console.log("Actual value and width: ", parseFloat(value), w_h)
      
      if (parseFloat(value)  > 1 - w_h){
          value = parseFloat((1  - w_h).toFixed(2))
      }

      setParams(prevParams => {
        return {...prevParams, [key]: value}
      })
      setChangedParams(true);
      if (value !== "") {
          changeZoneDetails(idx, key, Math.min(1, value) * type_val);//Math.min(1, newValue) * stageSize.width
      } else if (value === "") {
          console.log("NAN - detected")
          changeZoneDetails(idx, key, 0);
      }
    }
  }

  const handleChangeFileType = (e) => {
    console.log("Change File Type: ", e.target.value)
    changeZoneDetails(idx, ["type","content_name", "content_url"], [e.target.value,"",""])
    // changeZoneDetails(idx, "content_name", "")
  }

  const handleContentName = () =>{
    console.log("Handle Content Name")
    if (zone.type == "Playlist"){
      if (!zone.object){
        return "Select Playlist"
      }
      console.log("zoneeee when palyliss",zone, zone.object)
      return zone.object.playlistName ? zone.object.playlistName : "Select Playlist"
    }
    if (zone.type == "Schedule"){
      return zone.object.scheduleName ? zone.object.scheduleName : "Select Schedule"
    }
    else{
      return zone.content_name ? zone.content_name : "Choose Content"
    }
  }

  // ---- useEffects ----

  useEffect(() => {

    if (!changedParams){
      if (settingsInfo["Unit Type"] === UnitTypes.PIXELS){
        var screenHeight = -1;
        var screenWidth = -1;

        switch (settingsInfo["Resolution"]){
          case ResolutionTypes.HD:
            screenHeight = 720;
            screenWidth = 1280;
            break;
          case ResolutionTypes.FULL_HD:
            screenHeight = 1080;
            screenWidth = 1920;
            break;
          case ResolutionTypes.TWO_K:
            screenHeight = 1440;
            screenWidth = 2560;
            break;
          case ResolutionTypes.FOUR_K:
            screenHeight = 2160;
            screenWidth = 3840;
            break;  
          case ResolutionTypes.CUSTOM:
            screenHeight = 2160;
            screenWidth = 3840;
            break;  
        }

        const ch = screenHeight/stageSize.height
        const cw = screenWidth/stageSize.width

        setParams({
          y: (zone.y * ch).toFixed(2),
          x: (zone.x * cw).toFixed(2),
          width: (zone.width * cw).toFixed(2),
          height: (zone.height* ch).toFixed(2),
          counter_h: ch,
          counter_w: cw,
          screenHeight: screenHeight,
          screenWidth: screenWidth
        })

      }
      else{
        setParams({
          y: (zone.y/stageSize.height).toFixed(4),
          x: (zone.x/stageSize.width).toFixed(4),
          width: (zone.width/stageSize.width).toFixed(4),
          height: (zone.height/stageSize.height).toFixed(4)
        })
      }
    }
    else{
      setChangedParams(false);
    }

  }, [zone, settingsInfo])

  console.log("Hsalo:3 ", zone)


  const memoizedZoneEditor = useMemo(() => (
    <div style={{
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      width: "95%",
      margin: "auto"
    }}>
      <TextField
        label="Zone Name"
        variant="outlined"
        value={zone.name}
        onChange={(e) => changeZoneDetails(idx, "name", e.target.value)}
        fullWidth
      />
      {/* Container for coordinates manipulation */}
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            label={settingsInfo["Unit Type"] === UnitTypes.PIXELS ? "Top(px)" : "Top(%)"}
            variant="outlined"
            value={params.y}
            onChange={(e) => handleChangeNumbers(e, params.height, "y")}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label={settingsInfo["Unit Type"] === UnitTypes.PIXELS ? "Left(px)" : "Left(%)"}
            variant="outlined"
            value={params.x}
            onChange={(e) => handleChangeNumbers(e, params.width, "x")}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label={settingsInfo["Unit Type"] === UnitTypes.PIXELS ? "Width(px)" : "Width(%)"}
            variant="outlined"
            value={params.width}
            onChange={(e) => handleChangeNumbers(e, params.x, "width")}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label={settingsInfo["Unit Type"] === UnitTypes.PIXELS ? "Height(px)" : "Height(%)"}
            variant="outlined"
            value={params.height}
            onChange={(e) => handleChangeNumbers(e, params.y, "height")}
            fullWidth
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          />
        </Grid>
      </Grid>
      <Stack direction={"row"} sx={{ alignItems: "center", justifyContent: "center", gap: "10px" }}>
        <FormControl fullWidth variant="outlined" sx={{ width: zone.type ? "30%" : "100%" }}>
          <InputLabel id="select-label">Type</InputLabel>
          <Select
            labelId="select-label"
            value={zone.type}
            onChange={(e) => handleChangeFileType(e)}
            label="Type"
          >
            {Object.entries(ContentType).map(([key, value]) => (
              <MenuItem key={key} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {zone.type ?
          <Box ref={boxRef} display="flex" alignItems="center" sx={{ width: "70%", height: "60px", border: '1px solid #1A2432', borderRadius: 1, backgroundColor: "#1A2432", cursor: zone.type === "Playlist" ? "pointer" : "not-allowed" }}>
            <Typography variant="body1"
              onClick={(e) => handleClick(e)}
              sx={{
                flexGrow: 1,
                ml: 2,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}>
              {handleContentName()}
            </Typography>
            <Button variant="outlined" sx={{ borderRadius: 1, height: "60px" }} onClick={() => {
              if (zone.type === "Playlist" || zone.type === "Schedule") {
                window.open('http://localhost:3000/playlist', '_blank')
              } else {
                setOpenAssetsDialog(true)
              }
            }}>
              {zone.type === "Asset" ? "Change" : "Go To"}
            </Button>
            {(zone.type === "Playlist" || zone.type === "Schedule") &&
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => handleClose(null)}
                PaperProps={{
                  style: {
                    maxHeight: 200,
                    width: boxRef.current ? boxRef.current.offsetWidth : 'auto',
                    backgroundColor: "#1A2432",
                    marginTop: "20px",
                    marginLeft: "-20px"
                  },
                }}
              >
                {playlists && playlists.length > 0 && playlists.map((playlist, index) => (
                  <MenuItem key={index} onClick={() => handleClose(playlist)}>{playlist.playlistName}</MenuItem>
                ))}
              </Menu>
            }
          </Box>
          : null
        }
      </Stack>
      {zone.type === "Asset" && zone.content_name &&
        <FormControl fullWidth variant="outlined" sx={{ width: "100%" }}>
          <InputLabel id="select-label">Fit Screen</InputLabel>
          <Select
            labelId="select-label"
            value={zone.fit_screen}
            onChange={(e) => changeZoneDetails(idx, "fit_screen", e.target.value)}
            label="Fit Screen"
          >
            {Object.entries(FitScreen).map(([key, value]) => (
              <MenuItem key={key} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      }
      <DialogBoxAssets isOpen={openAssetsDialog} onClose={setOpenAssetsDialog} idx={idx} changeZoneDetails={changeZoneDetails} />
    </div>
  ), [idx, zone, params, settingsInfo, setOpenAssetsDialog, openAssetsDialog, playlists, anchorEl, changeZoneDetails, handleClose]);



  return memoizedZoneEditor;
}

export default SingleZone