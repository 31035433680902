import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { Table, TableBody, TableCell, TableRow, Checkbox } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    overflowX: "auto",
    maxWidth: "100%",
  },
  staticColumn: {
    position: "sticky",
    left: 0,
    zIndex: 1,
    border: 0,
    width: 250,
    flexShrink: 0,
    fontWeight: "bold",
    fontSize: "18px",
  },
}));

const FeatureManagerListTable = ({
  plans,
  handlePlanFeatureChange,
  handlePlanFeatureValueChange,
  handlePlanPriceChange,
  handlePlanFeaturePriceChange,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.tableContainer}>
      <Table sx={{ minWidth: 700 }}>
        <TableBody>
          <TableRow>
            <TableCell
              className={classes.staticColumn}
              sx={{ backgroundColor: theme.palette.background.default }}
            >
              {t("views.featureManagerListTable.features")}
            </TableCell>
            {plans.length !== 0 &&
              plans.map((plan) => (
                <TableCell key={plan._id} align="center">
                  {plan.name}
                </TableCell>
              ))}
          </TableRow>

          <TableRow>
            <TableCell
              className={classes.staticColumn}
              sx={{ backgroundColor: theme.palette.background.default }}
            >
              {t("views.userSubscriptions.subscriptionPrice")}
            </TableCell>
            {plans.map((plan) => (
              <TableCell key={plan._id} align="center">
                <TextField
                  sx={{ width: 100 }}
                  variant="standard"
                  value={plan.price || ""}
                  onChange={(e) =>
                    handlePlanPriceChange(
                      plan._id,
                      parseInt(e.target.value, 10)
                    )
                  }
                  inputProps={{
                    style: { textAlign: "right" },
                    "aria-label": t("views.featureManagerListTable.planPrice", {
                      plan: plan.name,
                    }),
                  }}
                />
              </TableCell>
            ))}
          </TableRow>

          {plans.length !== 0 &&
            plans[0].planFeatures.map((planFeature, featureIndex) => {
              return (
                <TableRow hover key={planFeature._id}>
                  <TableCell
                    className={classes.staticColumn}
                    sx={{ backgroundColor: theme.palette.background.default }}
                  >
                    {planFeature.name}
                  </TableCell>
                  {plans.map((plan) => {
                    const featureData = plan.planFeatures[featureIndex];
                    const value = featureData.value;
                    const featurePrice = featureData.price || "";
                    const inputType = featureData.inputType;
                    const units = featureData.units;
                    const placeholder = featureData.placeholder;
                    const isCanvaApp = featureData.slug === "canva_app";
                    const enabled = featureData.enabled;

                    let showPriceInput = false;

                    if (inputType === "text") {
                      // Dla inputType === "text" zawsze pokazujemy input do ceny
                      showPriceInput = true;
                    } else {
                      // inputType nie jest "text" → czyli checkbox
                      // Logika:
                      // - Canva App: enabled === true → pokaż input do ceny
                      // - Inne: enabled === false → pokaż input do ceny
                      if (isCanvaApp && enabled) {
                        showPriceInput = true;
                      } else if (!isCanvaApp && !enabled) {
                        showPriceInput = true;
                      }
                    }

                    let mainInput;
                    if (inputType === "text") {
                      mainInput = (
                        <TextField
                          sx={{ width: 100 }}
                          placeholder={placeholder}
                          value={value}
                          variant="standard"
                          InputProps={{
                            inputProps: {
                              style: { textAlign: "right" },
                              "aria-label": t(
                                "views.featureManagerListTable.featureValue",
                                { feature: planFeature.name, plan: plan.name }
                              ),
                            },
                            endAdornment: units ? (
                              <InputAdornment position="end">
                                {units}
                              </InputAdornment>
                            ) : null,
                          }}
                          onChange={(e) =>
                            handlePlanFeatureValueChange(
                              featureData._id,
                              plan._id,
                              e.target.value
                            )
                          }
                        />
                      );
                    } else {
                      mainInput = (
                        <Checkbox
                          sx={{ padding: 0 }}
                          checked={enabled}
                          onChange={(e) =>
                            handlePlanFeatureChange(
                              featureData._id,
                              plan._id,
                              e.target.checked
                            )
                          }
                          aria-label={t(
                            "views.featureManagerListTable.toggleFeature",
                            {
                              feature: planFeature.name,
                              plan: plan.name,
                            }
                          )}
                        />
                      );
                    }

                    return (
                      <TableCell key={plan._id} align="center">
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          gap={1}
                        >
                          {mainInput}
                          {showPriceInput ? (
                            <TextField
                              sx={{ width: 35 }}
                              variant="standard"
                              value={featurePrice}
                              placeholder={t("views.userSubscriptions.price")}
                              onChange={(e) =>
                                handlePlanFeaturePriceChange(
                                  featureData._id,
                                  plan._id,
                                  e.target.value
                                )
                              }
                              InputProps={{
                                style: { textAlign: "right" },
                                "aria-label": `Feature Price for ${planFeature.name} in ${plan.name}`,
                              }}
                            />
                          ) : (
                            <Box sx={{ width: 35 }} />
                          )}
                        </Box>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </div>
  );
};

export default FeatureManagerListTable;
