import { useSelector } from "react-redux";

const useUserFeature = (featureSlug, value = false) => {
  try {
    const feature = useSelector((s) => s.User.user.plan.planFeatures.find((f) => f.slug == featureSlug));

    if (!feature) return null;

    return value ? feature.value : feature.enabled;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export default useUserFeature;
