import { ReactComponent as Template1 } from './template-9-splitscreen-custom-01.svg';
import { ReactComponent as Template2 } from './template-9-splitscreen-custom-02.svg';
import { ReactComponent as Template3 } from './template-9-splitscreen-custom-03.svg';
import { ReactComponent as Template4 } from './template-9-splitscreen-custom-04.svg';
import { ReactComponent as Template5 } from './template-9-splitscreen-custom-05.svg';
import { ReactComponent as Template6 } from './template-9-splitscreen-custom-06.svg';
import { ReactComponent as Template7 } from './template-9-splitscreen-custom-07.svg';
import { ReactComponent as Template8 } from './template-9-splitscreen-custom-08.svg';
import { ReactComponent as Template9 } from './template-9-splitscreen-custom-09.svg';
import { ReactComponent as Template10 } from './template-9-splitscreen-custom-10.svg';
import { ReactComponent as Template11 } from './template-9-splitscreen-custom-11.svg';
import { ReactComponent as Template12 } from './template-9-splitscreen-custom-12.svg';

const Templates = [
  Template1,
  Template2,
  Template3,
  Template4,
  Template5,
  Template6,
  Template7,
  Template8,
  Template9,
  Template10,
  Template11,
  Template12,
];

export default Templates;