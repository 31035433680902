import React, { useState, useEffect } from "react";
import { Typography, Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import CardWeather from '../../files/Widgets/CardWeather';
import PlayerScaler from './PlayerScaler';

import { resolveSrc } from "../../../api/endpoints";

function Player({ open, onClose, player }) {
  console.log(player.orientation);
  const { t } = useTranslation();

  const defaultOrientation = player?.orientation?.toString() === "270" || player?.orientation?.toString() === "90" ? "270" : "landscape";

  const [orientation, setOrientation] = useState(defaultOrientation);
  const [displayDimensions, setDisplayDimensions] = useState({ width: "calc(85vh * 16 / 9)", height: "85vh" });

  useEffect(() => {
    updateDisplayBasedOnOrientation(orientation);
  }, [orientation]);

  const updateDisplayBasedOnOrientation = (newOrientation) => {
    if (newOrientation === "270" || newOrientation === "90") {
      setDisplayDimensions({ width: "calc(85vh * 9 / 16)", height: "85vh" });
    } else {
      setDisplayDimensions({ width: "calc(85vh * 16 / 9)", height: "85vh" });
    }
  };

  const handleOrientationChange = (e) => {
    setOrientation(e.target.value);
  };

  const getContentStyle = (maxHeight) => ({
    width: "100%",
    height: orientation === "landscape" || maxHeight ? "100%" : undefined,
    objectFit: "contain",
  });

  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          maxWidth: "100vw",
          background: "#0e1320",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "1000000000000",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}>
        <Box
          sx={{
            height: "10vh",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <Box sx={{ padding: "0 20px" }}>
            <Typography variant="h4">{t("views.player.title")}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              margin: "0 10px",
              position: "relative",
            }}>
            <select
              value={orientation}
              onChange={handleOrientationChange}
              style={{
                height: "40px",
                width: "200px",
                outline: "none",
                padding: "4px 10px",
                borderRadius: "5px",
                border: "1px solid black",
              }}>
              <option value="landscape">{t("views.player.landscapeResolution")}</option>
              <option value="270">{t("views.player.portraitResolution")}</option>
            </select>
            <Button variant={"contained"} sx={{ borderRadius: "5px", textTransform: "capitalize" }} onClick={onClose}>
              {t("views.player.close")}
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            height: displayDimensions.height,
            width: displayDimensions.width,
            margin: "20px 0",
            position: "relative",
            borderRadius: "10px",
            backgroundColor: "#1c2536",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
          }}>
          {player.type === "image" && <img src={resolveSrc(player?.url)} style={getContentStyle()} />}
          {player.type === "video" && <video autoPlay src={resolveSrc(player?.url)} style={getContentStyle()}></video>}
          {player.embed === "embed" && <embed src={`${player.url}`} style={getContentStyle()} controls />}
          {player.type === "widget" && (
            <>
              {player.widget?.widgetCat === "youtube" && <iframe src={player.widget.url} style={getContentStyle()}></iframe>}
              {player.widget?.widgetCat === "vimeo" && <iframe src={player.widget.url} style={getContentStyle()}></iframe>}
              {player.widget.widgetCat === "website" && <iframe src={player.widget.url} style={getContentStyle(true)}></iframe>}
              {player.widget.widgetCat === "scrollingstrip" && <div style={getContentStyle(true)} id="scrollingstrip" dangerouslySetInnerHTML={{ __html: player.widget.html }}></div>}
              {player.widget.widgetCat === "embed" && <div style={getContentStyle()} dangerouslySetInnerHTML={{ __html: player.widget.html }}></div>}
              {player.widget.widgetCat === "weather" && (
                orientation === '270' ? (
                  <PlayerScaler>
                    <CardWeather item={player.widget} />
                  </PlayerScaler>

                ) : (
                  <div style={getContentStyle(true)}>
                    <CardWeather item={player.widget} style={{ height: '100%' }} />
                  </div>
                ))}
              {player.widget.widgetCat === "canva" && (
                <div style={getContentStyle()}>
                  <div
                    style={{ height: "100%", width: "100%", maxHeight: "100%", overflow: "auto" }} className="player-canva"
                    dangerouslySetInnerHTML={{ __html: player.widget.html.replaceAll(">\\n", "<br/>").replaceAll("\\", "") }}></div>
                </div>
              )}
            </>
          )}
              {player.widget.widgetCat === "postermywall" && (
                <div style= {{display:"flex", justifyContent:"center", alignItems:"center", height: "100vh", width: "100vw", flexDirection: "column"}}>
                  <p>{player.name}</p>
                 <Button variant={"contained"} sx={{ borderRadius: "5px", textTransform: "capitalize" }} onClick={() => window.open(player.widget.url, "_blank", "noopener,noreferrer")}>
                    {t("views.player.openPreview")}
                  </Button>
               </div>
              )}
        </Box>
      </Box>
    </>
  );
}

export default Player;
