import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import ConfirmDialog from "src/components/dialog/ConfirmDialog";

import { removeGroup } from "src/redux/actions/User";

const GroupUnassignUsersDialog = ({ group, selectedUsers, open, onClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleAccept = async () => {
    await dispatch(removeGroup({ groupId: group._id, userIds: selectedUsers }));
    onClose?.();
  };

  return <ConfirmDialog open={open} title={t("views.groupUnassignUsersDialog.confirmUnassign")} onClose={onClose} onAccept={handleAccept} />;
};

export default GroupUnassignUsersDialog;
