import React, { useEffect, useCallback, useMemo, useRef } from "react";
import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
    auth: {
        clientId: '83c80d6d-a494-42ea-aa55-a56a9fe5e665',
        authority: 'https://login.microsoftonline.com/consumers',
        redirectUri: 'https://staging.vcplayer.com'
    }
};

const app = new PublicClientApplication(msalConfig);

async function getToken(scopes = ['OneDrive.ReadOnly']) {

    let accessToken = "";
    let cid = "";
    const authParams = {
        scopes
    };

    try {
        const resp = await app.acquireTokenSilent(authParams);
        accessToken = resp.accessToken;
        cid = resp.idTokenClaims?.oid.replace(/-/g, "").slice(16);
    } catch (e) {
        const resp = await app.loginPopup(authParams);
        app.setActiveAccount(resp.account);

        if (resp.idToken) {
            const resp2 = await app.acquireTokenSilent(authParams);
            accessToken = resp2.accessToken;
            cid = resp.idTokenClaims?.oid.replace(/-/g, "").slice(16);
        }
    }

    return {
        accessToken,
        cid
    }
};

const useOneDrivePicker = () => {

    const params = useMemo(() => ({
        sdk: "8.0",
        entry: {
            oneDrive: {}
        },
        authentication: {},
        messaging: {
            origin: "https://staging.vcplayer.com",
            channelId: "27"
        },
        typesAndSources: {
            mode: "all",
            pivots: {
                oneDrive: true,
                recent: true,
            }
        }
    }), []);

    const winRef = useRef(null);
    const portRef = useRef(null);

    const openOneDrivePicker = useCallback(async ({ onFilePick }) => {
        if (winRef.current)
            winRef.current.close();

        await app.initialize();

        const { cid, accessToken } = await getToken();

        winRef.current = window.open("", "Picker", "width=800,height=600");

        let { current: port } = portRef;
        let { current: win } = winRef;

        const queryString = new URLSearchParams({
            filePicker: JSON.stringify(params),
            cid
        });

        const url = `https://onedrive.live.com/picker?${queryString}`;

        const form = win.document.createElement("form");
        form.setAttribute("action", url);
        form.setAttribute("method", "POST");
        win.document.body.append(form);

        const input = win.document.createElement("input");
        input.setAttribute("type", "hidden")
        input.setAttribute("name", "access_token");
        input.setAttribute("value", accessToken);
        form.appendChild(input);

        form.submit();

        //Add listeners
        const messageListener = async (message) => {
            switch (message.data.type) {

                case "notification":
                    console.log(`OneDrive: Notification`, message.data);
                    break;

                case "command":
                    port.postMessage({
                        type: "acknowledge",
                        id: message.data.id,
                    });

                    const command = message.data.data;

                    switch (command.command) {
                        case "authenticate":
                            const { accessToken } = await getToken();
                            if (typeof accessToken !== "undefined" && accessToken !== null) {
                                port.postMessage({
                                    type: "result",
                                    id: message.data.id,
                                    data: {
                                        result: "token",
                                        token: accessToken,
                                    }
                                });
                            } else {
                                console.error(`OneDrive: Could not get auth token for command: ${JSON.stringify(command)}`);
                            }

                            break;

                        case "close":
                            win.close();

                            port.removeEventListener("message", messageListener);
                            port.close();
                            window.removeEventListener("message", initListener);

                            win = null;
                            port = null;

                            break;
                        case "pick":
                            console.log(`OneDrive: Picked: ${JSON.stringify(command)}`);

                            const { accessToken: downloadToken } = await getToken(['User.Read', 'Files.Read.All']);
                            if (typeof downloadToken !== "undefined" && downloadToken !== null) {
                                onFilePick?.({
                                    ...command,
                                    token: downloadToken
                                });
                            }

                            port.postMessage({
                                type: "result",
                                id: message.data.id,
                                data: {
                                    result: "success",
                                },
                            });
                            win.close();
                            break;
                        default:
                            console.warn(`OneDrive: Unsupported command: ${JSON.stringify(command)}`, 2);
                            port.postMessage({
                                result: "error",
                                error: {
                                    code: "unsupportedCommand",
                                    message: command.command
                                },
                                isExpected: true,
                            });
                            break;
                    }

                    break;
            }
        };

        const initListener = (event) => {
            if (event.source && event.source === win) {
                const message = event.data;
                if (message.type === "initialize" && message.channelId === params.messaging.channelId) {
                    port = event.ports[0];
                    port.addEventListener("message", messageListener);
                    port.start();
                    port.postMessage({
                        type: "activate",
                    });
                }
            }
        };
        window.addEventListener("message", initListener);

    }, [params]);

    return {
        openOneDrivePicker
    };
};

export default useOneDrivePicker;