import { Box, Breadcrumbs, Button, Dialog, DialogTitle, DialogContent, TextField } from "@mui/material";
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { changeFolder, createFolder } from "../../../redux/actions/FileFolder";

const Subbar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentFolder, currentFolderData, userFolders, user } = useSelector(
    (state) => ({
      currentFolder: state.FileFolder.currentFolder,
      currentFolderData: state.FileFolder.userFolders.find((u) => u._id === state.FileFolder.currentFolder),
      userFolders: state.FileFolder.userFolders,
      user: state.User,
    }),
    shallowEqual
  );
  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
  const [folderName, setFolderName] = useState("");
  const [code, setCode] = useState("");
  const [openCreateFolder, setOpenCreateFolder] = useState(false);
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  const makeFolder = () => {
    dispatch(
      createFolder({
        name: folderName,
        parent: currentFolder,
        path: currentFolder === "root" ? [] : [{ parent: currentFolder, name: "" }],
      })
    );
  };
  const handleNavigate = (link, id) => {
    navigate(link);
    dispatch(changeFolder(id));
  };

  return (
    <>
      <Box
        sx={{
          width: "90%",
          height: "10vh",
          borderBottom: "1px dotted black",
          margin: "0 auto",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Box>
          <div role="presentation" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb">
              {currentFolder !== "root" ? (
                <>
                  <Button onClick={() => handleNavigate("/folder", "root")}>{t("views.subbar.home")}</Button>
                  {currentFolderData.path.map((c, index) => (
                    <Button
                      key={index}
                      onClick={() =>
                        handleNavigate(`/foldercomponent/${userFolders.find((u) => u._id === c.parent)?._id}`, `${userFolders.find((u) => u._id === c.parent)?._id}`)
                      }>
                      {userFolders.find((u) => u._id === c.parent)?.name}
                    </Button>
                  ))}
                  <li>{currentFolderData?.name}</li>
                </>
              ) : (
                <li>{t("views.subbar.home")}</li>
              )}
            </Breadcrumbs>
          </div>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "5px",
          }}>
          <Button type="button" variant="outlined">
            {t("views.subbar.createFile")}
          </Button>
          <Button onClick={() => setOpenCreateFolder(true)} type="button" variant="outlined" disabled={user.checkFeatureAcesss("create_folders")}>
            {t("views.subbar.createFolder")}
          </Button>
        </Box>
      </Box>

      <Dialog open={openCreateFolder} onClose={() => setOpenCreateFolder(false)} maxWidth="lg">
        <DialogTitle sx={{ textAlign: "center" }}>{t("views.subbar.createFolderTitle")}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: isMobile ? "70vw" : "50vw",
              height: "50vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}>
            <Box style={{ width: "183px", height: "154.41px", margin: "0 auto" }}>
              <img src="/images/addFolderIcon.png" alt={t("views.subbar.addFolderIconAlt")} />
            </Box>
            <Box sx={{ margin: "20px 0" }}>
              <TextField
                variant="standard"
                fullWidth
                type="text"
                label={t("views.subbar.typeFolderName")}
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "40px 0",
                gap: "10px 10px",
              }}>
              <Button
                variant="outlined"
                sx={{
                  height: "38px",
                  width: "193px",
                  textTransform: "capitalize",
                  borderRadius: "10px",
                }}
                onClick={() => setOpenCreateFolder(false)}>
                {t("views.subbar.close")}
              </Button>
              <Button
                variant="contained"
                sx={{
                  height: "38px",
                  width: "193px",
                  textTransform: "capitalize",
                  borderRadius: "10px",
                }}
                onClick={makeFolder}>
                {t("views.subbar.save")}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Subbar;
