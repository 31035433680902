import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, List, ListItem, Card, CardHeader, MenuItem, Button, Typography, Link, SvgIcon } from "@mui/material";
import Layout from "../../components/layout/Layout";
import DonutChart from "../../components/chart/DonutChart";
import { useParams } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import "./screendetail.css";
import { GoogleMap, Autocomplete, Marker } from "@react-google-maps/api";

import { paths } from "src/paths";
import { RouterLink } from "src/components/router-link";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import ArrowLeftIcon from "@untitled-ui/icons-react/build/esm/ArrowLeft";
import { useSettings } from "src/hooks/use-settings";
import { NetworkIcon } from "src/icons/untitled-ui/duocolor/network";

function ScreenDetail({ user, socketRef }) {
  const { t } = useTranslation();
  const settings = useSettings();

  const { id } = useParams();
  const selectedPlaceRef = useRef();
  const { screens, apk } = useSelector(
    (state) => ({
      screens: state.Screen.screens,
      apk: state.Apk.apk,
    }),
    shallowEqual
  );
  const [screenInfo, setScreenInfo] = useState({});
  const [apkDetail, setApkDetail] = useState({});
  const [isApkUpdated, setIsApkUpdated] = useState(false);

  useEffect(() => {
    if (screens && screens.length > 0) {
      const s = screens.find((s) => s._id === id);
      if (s) {
        apk.forEach((a) => {
          if (a?.versionName > s?.device_details?.software) {
            setApkDetail({ url: `https://app.vcplayer.com${a.url}` });
            setIsApkUpdated(true);
          }
        });
        setScreenInfo({ ...s });
      } else {
        console.error("Screen not found");
      }
      console.log("screen: ", s);
    }
  }, [id, screens, apk]);

  const sendApkUpdate = () => {
    socketRef.current.emit("sendapkupdate", { url: apkDetail.url, mac: screenInfo.mac });
  };

  const placeChanged = () => {
    const placeObject = selectedPlaceRef.current.getPlace();
    const lat = placeObject.geometry.location.lat();
    const lng = placeObject.geometry.location.lng();
    console.log("Latitude:", lat);
    console.log("Longitude:", lng);
  };
  return (
    <Layout title={t("views.screenDetail.title")} user={user}>
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={settings.stretch ? false : "xl"}>
          <div>
            <Link
              color="text.primary"
              component={RouterLink}
              href={paths.screens.index}
              sx={{
                alignItems: "center",
                display: "inline-flex",
                marginBottom: "20px",
              }}
              underline="hover">
              <SvgIcon sx={{ mr: 1 }}>
                <ArrowLeftIcon />
              </SvgIcon>
              <Typography variant="subtitle2">{t("views.screenDetail.screens")}</Typography>
            </Link>
          </div>

          <Grid container spacing={{ xs: 3, lg: 4 }}>
            <Grid xs={12}>
              <Stack direction="row" justifyContent="space-between" spacing={4}>
                <div>
                  <Typography variant="h4">{t("views.screenDetail.screenDetails")}</Typography>
                </div>
              </Stack>
            </Grid>

            <Box sx={{ display: "flex", minWidth: "100%", flexDirection: { md: "row", xs: "column" } }}>
              {/* left column */}
              <Grid xs={12} md={8}>
                <Card sx={{ p: 2 }}>
                  {/* list */}
                  <List>
                    <ListItem disableGutters divider sx={{ justifyContent: "space-between", padding: 2 }}>
                      <Typography variant="subtitle2">{t("views.screenDetail.status")}</Typography>
                      <Typography variant="body2" color={screenInfo.onlineStatus ? "primary" : "error"}>
                        {screenInfo.onlineStatus ? t("views.screenDetail.connected") : t("views.screenDetail.disconnected")}
                      </Typography>
                    </ListItem>

                    <ListItem disableGutters divider sx={{ justifyContent: "space-between", padding: 2 }}>
                      <Typography variant="subtitle2">{t("views.screenDetail.network")}</Typography>
                      <Typography color="text.secondary" variant="body2" >
                        <Box sx={{display: "flex", alignItems: "flex-end"}}>
                          {screenInfo.device_details?.ssid}
                          <NetworkIcon ethernetStatus={screenInfo.device_details?.ethernetStatus} wifiSignal={screenInfo.device_details?.wifiSignal} connectionType={screenInfo.device_details?.connectionType}/>
                        </Box>
                      </Typography>
                    </ListItem>

                    <ListItem disableGutters divider sx={{ justifyContent: "space-between", padding: 2 }}>
                      <Typography variant="subtitle2">{t("views.screenDetail.code")}</Typography>
                      <Typography color="text.secondary" variant="body2">
                        {screenInfo?.screenCode}
                      </Typography>
                    </ListItem>

                    <ListItem disableGutters divider sx={{ justifyContent: "space-between", padding: 2 }}>
                      <Typography variant="subtitle2">{t("views.screenDetail.deviceName")}</Typography>
                      <Typography color="text.secondary" variant="body2">
                        {screenInfo?.device_details?.device_name}
                      </Typography>
                    </ListItem>

                    <ListItem disableGutters divider sx={{ justifyContent: "space-between", padding: 2 }}>
                      <Typography variant="subtitle2">{t("views.screenDetail.name")}</Typography>
                      <Typography color="text.secondary" variant="body2">
                        {screenInfo?.deviceName}
                      </Typography>
                    </ListItem>

                    <ListItem disableGutters divider sx={{ justifyContent: "space-between", padding: 2 }}>
                      <Typography variant="subtitle2">{t("views.screenDetail.deadline")}</Typography>
                      <Typography color="text.secondary" variant="body2">
                        test
                      </Typography>
                    </ListItem>

                    <ListItem disableGutters divider sx={{ justifyContent: "space-between", padding: 2 }}>
                      <Typography variant="subtitle2">{t("views.screenDetail.manufacturing")}</Typography>
                      <Typography color="text.secondary" variant="body2">
                        {screenInfo?.device_details?.manufacturer}
                      </Typography>
                    </ListItem>

                    <ListItem disableGutters divider sx={{ justifyContent: "space-between", padding: 2 }}>
                      <Typography variant="subtitle2">{t("views.screenDetail.os")}</Typography>
                      <Typography color="text.secondary" variant="body2">
                        {screenInfo?.device_details?.os_version}
                      </Typography>
                    </ListItem>

                    <ListItem disableGutters divider sx={{ justifyContent: "space-between", padding: 2 }}>
                      <Typography variant="subtitle2">{t("views.screenDetail.location")}</Typography>
                      <Typography color="text.secondary" variant="body2">
                        <span>{t("views.screenDetail.unitedStates")}</span>
                      </Typography>
                    </ListItem>

                    <ListItem disableGutters divider sx={{ justifyContent: "space-between", padding: 2 }}>
                      <Typography variant="subtitle2">{t("views.screenDetail.macAddress")}</Typography>
                      <Typography color="text.secondary" variant="body2">
                        {screenInfo?.mac}
                      </Typography>
                    </ListItem>

                    <ListItem disableGutters divider sx={{ justifyContent: "space-between", padding: 2 }}>
                      <Typography variant="subtitle2">{t("views.screenDetail.ipAddress")}</Typography>
                      <Typography color="text.secondary" variant="body2">
                        {screenInfo?.device_details?.ip}
                      </Typography>
                    </ListItem>

                    <ListItem disableGutters divider sx={{ justifyContent: "space-between", padding: 2 }}>
                      <Typography variant="subtitle2">{t("views.screenDetail.software")}</Typography>
                      <Typography color="text.secondary" variant="body2">
                        {screenInfo?.device_details?.software}
                      </Typography>
                    </ListItem>

                    <ListItem disableGutters divider sx={{ justifyContent: "space-between", padding: 2 }}>
                      <Typography variant="subtitle2">{t("views.screenDetail.rootLevel")}</Typography>
                      <Typography color="text.secondary" variant="body2">
                        {screenInfo?.device_details?.root_level}
                      </Typography>
                    </ListItem>

                    <ListItem disableGutters divider sx={{ justifyContent: "space-between", padding: 2 }}>
                      <Typography variant="subtitle2">{t("views.screenDetail.resolution")}</Typography>
                      <Typography color="text.secondary" variant="body2">
                        {`${screenInfo?.device_details?.width} x ${screenInfo?.device_details?.height}
                          ${+screenInfo?.device_details?.width > +screenInfo?.device_details?.height
                            ? t("views.screenDetail.landscape")
                            : t("views.screenDetail.portrait")
                          }
                          `}
                      </Typography>
                    </ListItem>
                  </List>
                </Card>
              </Grid>

              {/* right column */}
              <Grid xs={12} md={4}>
                <Card sx={{ p: 2 }}>
                  <Box sx={{ maxWidth: "200px" }}>
                    <div className="ram">
                      <DonutChart
                        views={t("views.screenDetail.ram")}
                        series={[+screenInfo?.ram?.total.split(" ")[0] - +screenInfo?.ram?.free.split(" ")[0], +screenInfo?.ram?.free.split(" ")[0]]}
                        labels={[
                          t("views.screenDetail.usage", { unit: screenInfo?.ram?.total.split(" ")[1] }),
                          t("views.screenDetail.free", { unit: screenInfo?.ram?.free.split(" ")[1] }),
                        ]}
                      />
                    </div>
                  </Box>

                  <Box sx={{ maxWidth: "200px" }}>
                    <div className="storage">
                      <DonutChart
                        views={t("views.screenDetail.storage")}
                        series={[+screenInfo?.storage?.total.split(" ")[0] - +screenInfo?.storage?.free.split(" ")[0], +screenInfo?.storage?.free.split(" ")[0]]}
                        labels={[
                          t("views.screenDetail.usage", { unit: screenInfo?.storage?.total.split(" ")[1] }),
                          t("views.screenDetail.free", { unit: screenInfo?.storage?.free.split(" ")[1] }),
                        ]}
                      />
                    </div>
                  </Box>

                  <Box
                    sx={{
                      background: "white",
                      p: 2,
                      borderRadius: "16px",
                    }}>
                    <GoogleMap
                      zoom={10}
                      center={{ lat: +screenInfo?.lat, lng: +screenInfo?.lng }}
                      mapContainerStyle={{ height: "300px", width: "100%" }}
                      options={{
                        mapTypeControl: false,
                        zoomControl: false,
                        streetViewControl: false,
                        fullscreenControl: false,
                      }}>
                      <Marker position={{ lat: +screenInfo?.lat, lng: +screenInfo?.lng }} />
                    </GoogleMap>
                    <Typography color="text.secondary" variant="body2">
                      {t("views.screenDetail.location")}
                    </Typography>
                  </Box>
                </Card>
              </Grid>
            </Box>
          </Grid>
        </Container>
      </Box>
    </Layout>
  );
}

export default ScreenDetail;
