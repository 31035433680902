import { makeStyles } from "@material-ui/core";
import { Box, Typography } from "@mui/material";
import React from "react";
const useStyles = makeStyles((theme) => {
  return {
    layoutContainer: {
      minHeight: "80vh",
      width: "80vw",
      margin: "20px auto",
      // border: "1px solid black",
      display: "flex",
    },
    layoutSidebar: {
      minHeight: "80vh",
      width: "25vw",
      margin: "0 8px",
      background: "#FFFFFF",
      borderRadius: "10px",
      boxShadow: "1px 1px 5px 5px rgba(0,0,0,0.1)",
      position: "relative",
    },
    layoutBody: {
      minHeight: "50vh",
      width: "55vw",
      padding: '30px 20px 0 20px',
      boxShadow: "1px 1px 5px 5px rgba(0,0,0,0.1)",
      margin: "0 8px",
      background: "#FFFFFF",
      borderRadius: "10px",
      [theme.breakpoints.down("sm")]: {
        width: "80vw",
        marginTop: "40px",
        minHeight: "80vh",
      },
    },
    layoutSidebarMidBanner: {
      position: "absolute",
      background: "linear-gradient(30.84deg, #016AFE 61.34%, rgba(1, 163, 254, 0.53) 100%)",
      borderRadius: "0px",
      transform: "matrix(1, 0, 0, -1, 0, 0)",
      minHeight: "10vh",
      width: "100%",
      top: "40vh",
    },
    layoutSidebarUpperBox: {
      minHeight: '40vh',
      width: '25vw',
      // border:'1px solid black',
      borderRadius: '10px 10px 0 0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    layoutSidebarBottomBox: {
      minHeight: '40vh',
      width: '25vw',
      // border:'1px solid black',
      borderRadius: '10px 10px 0 0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end'
    },
    layoutSidebarUpperBoxInside: {
      minHeight: '35vh',
      width: '23vw',
      // border:'1px solid black'
    },
    layoutSidebarBottomBoxInside: {
      minHeight: '30vh',
      width: '23vw',
      // border:'1px solid black',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
      // padding:'10px'
    },
  };
});
export function GenaralLayout({ children }) {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.layoutContainer}>
        {children}
      </Box>
    </>
  );
}
export function GeneralLayoutSidebar({ children }) {
  const classes = useStyles();
  return (<>
    <Box className={classes.layoutSidebar} >
      <Box className={classes.layoutSidebarMidBanner}></Box>
      {children}
    </Box>
  </>)
}
export function GenaralLayoutSidebarUpper({ children }) {
  const classes = useStyles();
  return (<>
    <Box className={classes.layoutSidebarUpperBox}>
      <Box className={classes.layoutSidebarUpperBoxInside}>
        {children}
      </Box>
    </Box>
  </>)
}
export function GenaralLayoutSidebarBottom({ children }) {
  const classes = useStyles();
  return (<>
    <Box className={classes.layoutSidebarBottomBox}>
      <Box className={classes.layoutSidebarBottomBoxInside}>{children}</Box>
    </Box>
  </>)
}
export function GeneralLayoutBody({ children }) {
  const classes = useStyles();
  return (<>
    <Box className={classes.layoutBody}>{children}</Box>

  </>)
}