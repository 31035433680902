import { useCallback, useMemo, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import { addWidgetToGrid, editWidgetInGrid } from "../../../../redux/actions/WidgetsGridsAction";

import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Collapse } from "@mui/material";

import WidgetEditInput from "./WidgetEditInput";
import WidgetEditStylesInputs from "./WidgetEditStylesInputs";

import * as Widgets from "../../widgets";

const WidgetEditDialog = ({ modal }) => {
  const { t } = useTranslation();

  const StylesInputsSchema = {
    "style-size": Yup.string().required(t("components.widgetEditStylesInputs.widgetSizeRequired")),
    "style-border": Yup.string().required(t("components.widgetEditStylesInputs.widgetBorderTypeRequired")),
    "style-padding": Yup.string().required(t("components.widgetEditStylesInputs.widgetPaddingSizeRequired")),
    "style-proportions": Yup.string().required(t("components.widgetEditStylesInputs.widgetProportionsRequired")),
  };

  const dispatch = useDispatch();
  const [state, dispatchModal] = modal;
  const { gridName, widget, action } = state;

  const handleClose = useCallback(() => {
    dispatchModal({ type: "END_ACTIONS" });
  }, []);

  const handleAccept = useCallback(
    (widgetData) => {
      if (action == "EDIT") {
        dispatch(
          editWidgetInGrid(gridName, {
            _id: widget._id,
            style: widgetData.style,
            settings: widgetData.settings,
          })
        );
      } else {
        dispatch(addWidgetToGrid(gridName, widgetData));
      }

      dispatchModal({ type: "END_ACTIONS" });
    },
    [action, widget, gridName]
  );

  //UI Fields
  const dialogTitle = useMemo(() => (action == "EDIT" ? t("components.widgetEditDialog.editWidget") : t("components.widgetEditDialog.addWidget")), [action]);
  const actionButtonText = useMemo(() => (action == "EDIT" ? t("components.widgetEditDialog.save") : t("components.widgetEditDialog.add")), [action]);

  //Form fields
  const [widgetType, setWidgetType] = useState(action == "EDIT" ? widget.type : null);
  const handleWidgetTypeChange = useCallback((type) => {
    setWidgetType(type);
  }, []);
  useEffect(() => setWidgetType(action == "EDIT" ? widget.type : null), [action, widget]);

  const [formErrors, setFormErrors] = useState({});

  const configOptions = useMemo(() => {
    return Object.values(Widgets).find((w) => w.type == widgetType)?.configOptions;
  }, [widgetType]);

  const widgetTypesOptions = useMemo(
    () =>
      Object.values(Widgets).map((w) => ({
        name: w.displayName,
        value: w.type,
      })),
    [configOptions, widget]
  );

  const formValidScheme = useMemo(
    () =>
      Yup.object({
        ...(configOptions
          ? Object.entries(configOptions).reduce((schema, [name, field]) => {
              if (field.validator) schema[name] = field.validator;

              return schema;
            }, {})
          : {}),
        ...StylesInputsSchema,
        name: Yup.string().required(t("components.widgetEditDialog.widgetNameRequired")),
        type: Yup.string().required(t("components.widgetEditDialog.widgetTypeRequired")),
      }),
    [configOptions]
  );

  const formFields = useMemo(
    () => [
      configOptions && Object.keys(configOptions).length > 0 && (
        <Box my={4} key={`widgetConfigField_0`}>
          <Typography variant="h6">{t("components.widgetEditDialog.settings")}</Typography>
        </Box>
      ),
      ...(configOptions
        ? Object.entries(configOptions).map(([name, field], idx) => (
            <Box my={2}>
              <WidgetEditInput
                name={name}
                {...field}
                key={`widgetConfigField_${idx + 1}`}
                error={formErrors[name]}
                defaultValue={widget?.settings?.[name] ?? undefined}
              />
            </Box>
          ))
        : []),
    ],
    [configOptions, formErrors]
  );

  const handleFormSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setFormErrors({});

      const formData = new FormData(e.currentTarget);
      let formJson = Object.fromEntries(formData.entries());

      //Add disabled values
      formJson["name"] = formJson?.["name"] ?? widget?.name;
      formJson["type"] = formJson?.["type"] ?? widgetType;

      try {
        //Validate
        await formValidScheme.validate(formJson, { abortEarly: false });

        //Accept and set widget model data
        const {
          //Base settings
          name,
          type,
          //Rest fields
          ...formFields
        } = formJson;

        //Parse settings fields to style and the rest
        const { style, settings } = Object.entries(formFields).reduce(
          (output, [fieldName, fieldValue]) => {
            if (fieldName.startsWith("style-")) output["style"][fieldName.replace(/^style-/, "")] = fieldValue;
            else output["settings"][fieldName] = fieldValue;

            return output;
          },
          {
            style: {},
            settings: {},
          }
        );

        handleAccept({ name, type, style, settings });
      } catch (e) {
        const validationDetails = e?.inner;
        if (!validationDetails) throw e;

        setFormErrors(
          validationDetails.reduce((errors, error) => {
            errors[error.path] = error.message;
            return errors;
          }, {})
        );
      }
    },
    [formValidScheme, widget]
  );

  return (
    <Dialog
      open={(widget && action == "EDIT") || action == "ADD"}
      onClose={handleClose}
      maxWidth={"md"}
      fullWidth
      PaperProps={{
        component: "form",
        onSubmit: handleFormSubmit,
      }}>
      <DialogTitle mt={2}>{dialogTitle}</DialogTitle>
      <DialogContent>
        <Box my={2}>
          <WidgetEditInput
            label={t("components.widgetEditDialog.widgetName")}
            placeholder={t("components.widgetEditDialog.enterWidgetName")}
            type="text"
            name="name"
            disabled={!!widget?.name}
            defaultValue={widget?.name}
            error={formErrors["name"]}
          />
        </Box>
        <Box my={2}>
          <WidgetEditInput
            label={t("components.widgetEditDialog.widgetType")}
            type="select"
            name="type"
            placeholder={t("components.widgetEditDialog.selectWidgetType")}
            options={widgetTypesOptions}
            disabled={action == "EDIT"}
            value={widgetType}
            error={formErrors["type"]}
            onChange={(e) => handleWidgetTypeChange(e.target.value)}
          />
        </Box>
        {/* Custom widget settings field */}
        {formFields}
        {/* Widget style customization */}
        <WidgetEditStylesInputs widget={widget} action={action} formErrors={formErrors} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("components.widgetEditDialog.cancel")}</Button>
        <Button type="submit" variant={"primary"}>
          {actionButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WidgetEditDialog;
